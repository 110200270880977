import { Component } from "react"
import shallowEqual from "shallowequal"
import handleStateChangeOnClient from "./client"
import Provider from "./HelmetProvider"
import { HelmetData, IProviderHelmetD } from "./react-helmet-async-types.d"
import mapStateOnServer from "./server"
import { reducePropsToState } from "./utils"

interface IProps {
  context: IProviderHelmetD
}

export default class Dispatcher extends Component<IProps> {
  public static displayName = "HelmetDispatcher"

  private rendered = false

  public shouldComponentUpdate(nextProps) {
    return !shallowEqual(nextProps, this.props)
  }

  public componentDidUpdate() {
    this.emitChange()
  }

  public componentWillUnmount() {
    const { context } = this.props
    if (context) {
      const { helmetInstances } = context
      helmetInstances.remove(this)
      this.emitChange()
    }
  }

  public emitChange() {
    const { context } = this.props
    if (!context) {
      return
    }

    const { helmetInstances, setHelmet } = context
    let serverState: HelmetData | null = null
    const state = reducePropsToState(
      helmetInstances.get().map((instance) => {
        const props = { ...instance.props }
        delete props.context
        return props
      }),
    )
    // console.log('changing state:')
    // console.dir(state)
    if (Provider.canUseDOM) {
      handleStateChangeOnClient(state)
    } else if (mapStateOnServer) {
      serverState = mapStateOnServer(state)
    }
    setHelmet(serverState)
  }

  // componentWillMount will be deprecated
  // for SSR, initialize on first render
  // constructor is also unsafe in StrictMode
  public init() {
    if (this.rendered) {
      return
    }

    this.rendered = true

    const { context } = this.props
    if (context) {
      const { helmetInstances } = context
      helmetInstances.add(this)
      this.emitChange()
    }
  }

  public render() {
    this.init()

    return null
  }
}
