import React, { FC, useMemo, useCallback, useState, ReactNode } from "react"
import { Redirect } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { TPoolRouteProps } from "../../../../../../routes.d"
import { PoolPageCardBody } from "../../../components/PoolPage/PoolPage.styles"
import { BracketsHomeWrapper } from "./BracketPoolHome.styles"
import Typography from "../../../../../shared/atoms/Typography"
import { POOL_SEASON_STANDINGS_QUERY } from "../../../../queries"
import { PoolSeasonStandingsQuery, PoolSeasonStandingsQueryVariables } from "../../../../../../__generated__/PoolSeasonStandingsQuery"
import LoadingView from "../../../../../components/LoadingView"
import { ANCHORS } from "@cbs-sports/sports-shared-client/build/cjs/components/Popover"
import { emptyObject, emptyArray } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import HorizontalShadowScroll from "../../../../../shared/atoms/HorizontalShadowScroll"
import { edgeToNode, toNodes } from "../../../../../utils/misc-utils"
import { withoutDomain } from "../../../../../utils/url-utils"
import PaginationNew from "../../../components/PaginationNew"
import PoolHistoryDropdown, { IDisplayPoolSelectOption } from "./PoolHistoryDropdown"
import ChampionLogo from "./ChampionLogo"
import StandingsTable from "./StandingsTable"
import { ColumnLink } from "./StandingsTable.styles"
import BracketStatus from "./BracketStatus"
import {
  BracketStatusType,
  defaultDataEntryFnForKey,
  StandingsTableSortOptionsType,
  DataEntryFn,
  ColumnDataType,
  CHAMPION_PICK_STATUS,
} from "./StandingsTable.common"
import { buildPickUtils } from "../../../../../hooks/usePoolData"
import { sortByOrder } from "../../../../../../common/sorters"
import { useDeviceType } from "../../../../../Base/DeviceType"
import constants, { NCAAB_BRACKET_LOCKS_AT_TIMESTAMP, SPORT_TYPE_LOGOS } from "../../../../../../common/constants"
import AnalyticScreen from "../../../../../components/AnalyticsScreen"
import { TiebreakerTypeEnum } from "../../../../../../common/enums"
import PickUtils from "../../../../../../common/pick-utils"
import useStandingOptions, { StandingOptions } from "../../../../../hooks/useStandingOptions"
import {
  dateToString,
  DayFormat,
  MonthFormat,
  WeekdayFormat,
  getHoursAndMinutesFromDate,
} from "@cbs-sports/sports-shared-client/build/cjs/utils/helper-utils"
import { shieldLogo } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import ClassicTooltip from "@cbs-sports/sports-shared-client/build/cjs/components/ClassicTooltip"
import { TooltipBody } from "../../EntryRow/EntryRow.styles"
import InfoCircle from "@cbs-sports/sports-shared-client/build/cjs/components/icons/IcoMoonIcons/InfoCircle"
import palette from "../../../../../../common/palette"
import NewsletterModal from "../../../components/EntryModals/NewsletterModal"
import { isCurrentUserLoggedIn, teamImgUrl } from "../../../../../utils/data-utils"
import useStandingsPagination from "./useStandingsPagination"
import PicksReminderButton from "./PicksReminderButton"
import ImportIcon from "../../../../../components/icons/Import"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import Link from "../../../../../components/Link"
import Analytics from "../../../../../utils/analytics"
import { isNCAAWTournamentMatcher } from "../../../../../../common/common-utils-helpers"
import GenericConfirmationModal from "../../../components/EntryModals/GenericConfirmationModal"
import { scrollToTop } from "../../../../../components/ScrollToTopOnMount"

const MAIN_TITLE = "Standings"
const MY_BRACKETS_SUBTITLE = "My Brackets"
const OVERALL_SUBTITLE = "Overall Standings"
// const DISABLE_POOL_SORTING_THRESHOLD = 200
const BRACKET_NAME_STYLE = { minWidth: 0 }

const initialPeriodList: Array<string> = []
type IBracketPoolHome = TPoolRouteProps

export const getChampionStatus = (pickUtils: PickUtils | null, championTeam: any): CHAMPION_PICK_STATUS => {
  const { bracketMapping, bracketPeriodTree } = pickUtils || emptyObject
  const { rounds } = bracketPeriodTree || emptyObject
  const { eliminatedItemIds, gameStatusMapping, matchupByRound } = bracketMapping || emptyObject
  const lastRound = rounds && rounds.length && rounds[rounds.length - 1]
  const finalRound = lastRound && matchupByRound && matchupByRound[lastRound]
  const finalMatchUp = finalRound && finalRound[0]
  const gameStatus = (finalMatchUp && finalMatchUp.id && gameStatusMapping[finalMatchUp.id]) || "UNKNOWN"
  if (championTeam && championTeam.id && eliminatedItemIds) {
    if (gameStatus === "final") {
      return eliminatedItemIds[championTeam.id] ? "INCORRECT" : "CORRECT"
    } else {
      return eliminatedItemIds[championTeam.id] ? "INCORRECT" : "UNKNOWN"
    }
  }
  return "UNKNOWN"
}

export const getChampionLogo = (pickUtils: PickUtils | null, championTeam: any): string | undefined => {
  if (championTeam) {
    const { sportType, id } = championTeam
    const { bracketMapping } = pickUtils || emptyObject
    const { itemIdPlayinItemNameOverrides } = bracketMapping || emptyObject
    const isPlayInTeam = itemIdPlayinItemNameOverrides && itemIdPlayinItemNameOverrides[id]
    if (isPlayInTeam) {
      return SPORT_TYPE_LOGOS[sportType] || shieldLogo
    }
    return teamImgUrl({ sportType: championTeam.sportType, cbsTeamId: championTeam.cbsTeamId, id: championTeam.cbsTeamId })
  }
  return undefined
}

const BracketPoolHome: FC<IBracketPoolHome> = (props) => {
  const deviceType = useDeviceType()
  const [isExportStandingsModalOpen, setIsExportStandingsModalOpen] = useState(false)

  const hideModal = React.useCallback(() => {
    setIsExportStandingsModalOpen(false)
  }, [setIsExportStandingsModalOpen])

  const showModal = React.useCallback(() => {
    Analytics.trackAction("brackets standings", "export icon", "export", "click")
    setIsExportStandingsModalOpen(true)
  }, [setIsExportStandingsModalOpen])

  const { poolData, history } = props
  const { isChallengePool } = poolData || emptyObject
  const isLoggedIn = isCurrentUserLoggedIn(poolData.currentUser)
  const allPeriods = poolData.poolPeriodQuery.data?.gameInstance.period?.segment.periods.edges ?? []
  const { gameInstanceUid, isInComingSoon, centralBracketState, poolDetail, segmentForArea } = poolData || emptyObject
  const isSweet16Variant = constants.SWEET_16_ENABLED && gameInstanceUid.includes("ncaab-tournament-sweet-sixteen")
  const isManager = poolData.hasManagerRole
  const pickUtils = buildPickUtils(poolData, null, poolData.detailedEntry?.picks, poolData.detailedEntry?.id)
  const isLocked = centralBracketState?.bracketState?.isLocked || false
  const showRoadBlock = deviceType === "handheld" && !constants.UNSUPORTED_APP_GAME_INSTANCE_UIDS.includes(gameInstanceUid)

  const canShowNewsletterModal = useMemo(() => {
    const { hasSeenManagerWelcomeBackModal, inviteAllEmailSent, parentPoolId } = poolData.poolDetail || emptyObject
    const isShowingWelcomeBackModal = isManager && Boolean(parentPoolId) && !hasSeenManagerWelcomeBackModal && !inviteAllEmailSent
    const isRouterStatePresent = typeof history.location.state !== "undefined" && ["actions"].some((key) => Boolean(history.location.state[key]))
    return !isShowingWelcomeBackModal && !isRouterStatePresent
    //Only want to read on first render to prevent showing multiple modals at the same time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const poolPeriodByRoundMap = allPeriods
    .map(edgeToNode)
    .sort(sortByOrder)
    .reduce((obj, curr) => {
      if (curr.tournamentRound) {
        return [...obj, curr.id]
      }
      return obj
    }, initialPeriodList)

  const poolSettings: Record<string, unknown> = Object.assign({}, segmentForArea?.poolSettings ?? {}, poolDetail?.poolSettings ?? {})
  const poolHasTiebreaker = poolSettings.mainTiebreaker === TiebreakerTypeEnum.TOTAL_SCORE

  const [displayingPoolId, setDisplayingPoolId] = useState<string>(() => poolData.poolId)
  const [displayingSeasonYear, setDisplayingSeasonYear] = useState<number | undefined>(() => poolData.poolDetail?.season.year)

  /**
   * Game Options
   */
  const [, hasOption] = useStandingOptions()
  const gameHasPaginationOption = hasOption(StandingOptions.Pagination)
  const gameHasSortingOption = hasOption(StandingOptions.Sorting)
  const gameHasDisplayPrelockBracketsOption = hasOption(StandingOptions.DisplayPrelockBrackets)
  const gameHasDisplayPrelockStatusOption = hasOption(StandingOptions.DisplayPrelockStatus)
  const gameHasDisplayCompletedBracketsCountOption = hasOption(StandingOptions.DisplayCompletedBracketsCount)
  const gameHasDisplayPoolHistoryOption = hasOption(StandingOptions.DisplayPoolHistory)

  /**
   * Bracket/Game Status
   */
  const bracketStatus = useMemo<BracketStatusType>(() => {
    const currentPeriod = poolData.detailedPeriod
    if (!currentPeriod?.isCurrent) {
      return "ARCHIVED"
    }
    const isLocked = isSweet16Variant
      ? false // centralBracketState?.bracketState?.sweetSixteenBracketState.isLocked
      : centralBracketState?.bracketState.isLocked
    if (isLocked) {
      return "LOCKED"
    }

    const isPickable = isSweet16Variant
      ? false // !centralBracketState?.bracketState?.sweetSixteenBracketState.isPreTournament
      : !centralBracketState?.bracketState?.isPreTournament
    if (isPickable) {
      return "PICKABLE"
    }

    return "COMING_SOON"
  }, [centralBracketState?.bracketState, poolData, isSweet16Variant])
  const isInPrelockStatus = bracketStatus === "PICKABLE" || bracketStatus === "COMING_SOON"

  const displayingArchivedPool = gameHasDisplayPoolHistoryOption && displayingPoolId !== poolData.poolId

  /**
   * Sorting Controls
   */
  // const isInSortableThreshold = (poolData.poolDetail?.entriesCount ?? 0) <= DISABLE_POOL_SORTING_THRESHOLD
  const isPreTournament = centralBracketState?.bracketState?.isPreTournament
  const isSortingEnabled = !isPreTournament && gameHasSortingOption && !displayingArchivedPool

  const initialSortingColumn: PoolSeasonStandingsQueryVariables["orderBy"] =
    isInPrelockStatus && gameHasDisplayPrelockStatusOption && !displayingArchivedPool ? "STATUS" : "OVERALL_RANK"
  const initialSortingOrder: PoolSeasonStandingsQueryVariables["sortingOrder"] =
    !displayingArchivedPool && initialSortingColumn === "STATUS" ? "DESC" : "ASC"

  // const [sortByColumn, setSortByColumn] = useState<PoolSeasonStandingsQueryVariables["orderBy"]>(initialSortingColumn)
  // const [sortingOrder, setSortingOrder] = useState<PoolSeasonStandingsQueryVariables["sortingOrder"] | undefined>(initialSortingOrder)

  // React.useEffect(() => {
  //   //Restore default sorting params as react router dom won't remount the whole component on every location change
  //   setSortByColumn(initialSortingColumn)
  //   setSortingOrder(initialSortingOrder)
  // }, [location, displayingPoolId, poolData?.poolId, initialSortingColumn, initialSortingOrder])

  /**
   * Pagination controls
   */
  const pagination = useStandingsPagination({ pageSize: 50, orderByColumn: initialSortingColumn, sort: initialSortingOrder })

  const onGoToPage = (cursor: string | null) => {
    pagination.goToPage(cursor)
    scrollToTop()
  }

  React.useEffect(() => {
    setDisplayingPoolId(poolData.poolId)
    setDisplayingSeasonYear(poolData.poolDetail?.season.year)
  }, [poolData.poolId, poolData.poolDetail])

  const handleSortChange = useCallback(
    (col: string) => {
      pagination.updateOrderBy(col)
    },
    [pagination],
  )

  const allEntriesSortOptions: StandingsTableSortOptionsType = {
    enabled: isSortingEnabled,
    sortBy: pagination.orderByColumn,
    onSortChange: handleSortChange,
  }

  const orderByVariable =
    (isSortingEnabled || displayingArchivedPool) && pagination.orderByColumn
      ? { orderBy: pagination.orderByColumn, sortingOrder: pagination.sort }
      : {}

  const currentUsersPoolEntryIds = useMemo(() => new Set(poolData.allCurrentEntries.filter((e) => e.pool.id === poolData.poolId).map((e) => e.id)), [
    poolData.allCurrentEntries,
    poolData.poolId,
  ])
  const skipCheckForIncompleteEntries = !isManager || bracketStatus !== "PICKABLE"
  const { data: standingsData, loading } = useQuery<PoolSeasonStandingsQuery, PoolSeasonStandingsQueryVariables>(POOL_SEASON_STANDINGS_QUERY, {
    variables: {
      gameInstanceUid: poolData.gameInstanceUid,
      includedEntryIds: displayingArchivedPool ? undefined : [...currentUsersPoolEntryIds],
      poolId: displayingPoolId,
      first: pagination.first,
      after: pagination.after,
      skipAncestorPools: !gameHasDisplayPoolHistoryOption,
      skipPeriodPoints: displayingArchivedPool,
      skipCheckForIncompleteEntries: skipCheckForIncompleteEntries,
      ...orderByVariable,
    },
    fetchPolicy: "cache-and-network", //Keep an eye on this in case it causes any performance issues. This will assure that player added/remove from pool will be shown in the standings page
    nextFetchPolicy: isManager ? "cache-and-network" : "cache-first",
  })
  const pageInfo = standingsData?.gameInstance.pool.entries.pageInfo ?? emptyObject

  const historyDropdownOptions: Array<IDisplayPoolSelectOption> = useMemo(() => {
    if (!gameHasDisplayPoolHistoryOption) {
      return emptyArray
    }
    const ancestorPoolsOptions: Array<IDisplayPoolSelectOption> =
      standingsData?.gameInstance.pool.poolEditions?.map((pool) => ({
        year: pool.seasonYear,
        isSelected: displayingPoolId === pool.poolId,
        value: pool.poolId,
        label: `${pool.seasonYear} Tournament`,
      })) ?? emptyArray
    return ancestorPoolsOptions
  }, [displayingPoolId, standingsData, gameHasDisplayPoolHistoryOption])

  const myEntries = useMemo(() => {
    const myEntryNodes = toNodes(standingsData?.gameInstance.pool.entries)
      .filter((e) => currentUsersPoolEntryIds.has(e.id))
      .sort((a, b) => {
        if (isLocked) {
          const aRank = a.poolRank || constants.DEFAULT_RANK
          const bRank = b.poolRank || constants.DEFAULT_RANK
          return aRank === bRank ? a.createdAt - b.createdAt : aRank - bRank
        }
        return a.createdAt - b.createdAt
      }) //my entries will be sorted by order of creation
    return myEntryNodes
  }, [standingsData, currentUsersPoolEntryIds, isLocked])

  const allEntries = useMemo(() => {
    //NOTE RH: all appended rows share the same cursor as the last row in the results.
    //That fact is being used here to compute how many rows have been added to the results by the `includedEntryIds` parameter
    const lastCursor = standingsData?.gameInstance.pool.entries.pageInfo.endCursor
    const prependedResultsCount = standingsData?.gameInstance.pool.entries.edges.filter(({ cursor }) => cursor === lastCursor).length ?? 1
    return toNodes(standingsData?.gameInstance.pool.entries).slice(prependedResultsCount - 1)
  }, [standingsData])

  const displayPoolStandings = (item: IDisplayPoolSelectOption) => {
    pagination.resetOrderBy("OVERALL_RANK")
    setDisplayingPoolId(String(item.value))
    setDisplayingSeasonYear(item.year)
  }

  const navigateToBracket = useCallback(
    (entryId: string) => {
      const poolUrl = poolData.poolDetail?.url
      if (typeof poolUrl === "string") {
        history.push({
          pathname: withoutDomain(poolUrl),
          search: `entryId=${entryId}`,
        })
      }
    },
    [history, poolData.poolDetail?.url],
  )

  const renderPeriodPointForRound = useCallback(
    (round: number) => {
      const fn: DataEntryFn = (entry) => {
        const periodId = poolPeriodByRoundMap[round]
        if (entry.hasGameDataOnly || !periodId) {
          return null
        }
        const periodPoint = entry.periodPoints?.edges.find((p) => p.node?.periodId === periodId)
        return periodPoint ? periodPoint.node?.fantasyPoints : null
      }
      return fn
    },
    [poolPeriodByRoundMap],
  )

  const sharedColumns = useMemo<Array<ColumnDataType>>(() => {
    return [
      { key: "poolRank", label: "RANK" },
      {
        key: "championTeam",
        label: "CHAMPION",
        size: 2,
        render: (e) => {
          const status = displayingArchivedPool || isLocked ? getChampionStatus(pickUtils, e.championTeam) : "UNKNOWN"
          const logoUrl = getChampionLogo(pickUtils, e.championTeam)
          const showPlaceholderLogo = !displayingArchivedPool && !isLocked && !currentUsersPoolEntryIds.has(e.id)
          return (
            <ChampionLogo
              championTeam={e.championTeam}
              showTiebreaker={poolHasTiebreaker}
              tiebreakerAnswers={e.tiebreakerAnswers}
              pickStatus={status}
              showPlaceholder={showPlaceholderLogo}
              logoUrl={logoUrl}
            />
          )
        },
        sortable: isSortingEnabled,
        customClass: "champion-column",
      },
      {
        key: "name",
        label: "BRACKET NAME",
        size: isSweet16Variant ? 8 : 6,
        render: (entry) => {
          // Show entry link only when the game is locked. If the game is not locked yet, show link only if the entry is mine
          const isInteractive = !isInComingSoon && !entry.hasGameDataOnly && !displayingArchivedPool && (entry.isMine || isLocked)
          return (
            <Typography weight="BOLD" spacing="COMPRESSED" className="column size6" key={`${entry.id}_bracketName`} style={BRACKET_NAME_STYLE}>
              {isInteractive ? <ColumnLink onClick={() => navigateToBracket(entry.id)}>{entry.name}</ColumnLink> : entry.name}
            </Typography>
          )
        },
      },
    ]
  }, [
    poolHasTiebreaker,
    isLocked,
    pickUtils,
    isInComingSoon,
    navigateToBracket,
    isSortingEnabled,
    isSweet16Variant,
    currentUsersPoolEntryIds,
    displayingArchivedPool,
  ])

  const allColumns = useMemo<Array<ColumnDataType>>(() => {
    let columns: Array<ColumnDataType> = []
    columns = columns.concat(
      { key: "fantasyPoints", label: "PTS", description: "Total Points", align: "right", sortable: isSortingEnabled },
      { key: "maxPoints", label: "MAX", description: "Maximum Possible Points", align: "right", sortable: isSortingEnabled },
      { key: "correctPicks", label: "CPK", description: "Total Correct Picks", align: "right", sortable: isSortingEnabled },
    )
    if (!isSweet16Variant && !displayingArchivedPool) {
      columns = columns.concat(
        { key: "rd1", label: "RD1", description: "Round 1", align: "right", render: renderPeriodPointForRound(0) },
        { key: "rd2", label: "RD2", description: "Round 2", align: "right", render: renderPeriodPointForRound(1) },
      )
    }
    if (!displayingArchivedPool) {
      columns = columns.concat(
        { key: "s16", label: "S16", description: "Sweet 16", align: "right", render: renderPeriodPointForRound(2) },
        { key: "e8", label: "E8", description: "Elite 8", align: "right", render: renderPeriodPointForRound(3) },
        { key: "ff", label: "FF", description: "Final Four", align: "right", render: renderPeriodPointForRound(4) },
        {
          key: "ncg",
          label: "NCG",
          description: "National Championship Game",
          align: "right",
          descriptionTooltipProps: { anchor: ANCHORS.BOTTOM_RIGHT },
          render: renderPeriodPointForRound(5),
        },
      )
    }
    return columns
  }, [isSortingEnabled, renderPeriodPointForRound, isSweet16Variant])

  const statusColumnSize = isSweet16Variant ? 7 : 9
  const myBracketsStatusColumns = useMemo<Array<ColumnDataType>>(
    () => [
      {
        key: "status",
        label: "STATUS",
        size: statusColumnSize,
        render: (entry) => {
          return (
            <BracketStatus
              status={bracketStatus}
              picksCount={entry.picksCount}
              maxPicksCount={entry.maxPicksCount}
              needsToMakePicks={entry.needsToMakePicks}
              detailedMessage
            />
          )
        },
      },
    ],
    [bracketStatus, statusColumnSize],
  )

  const allBracketsStatusColumns = useMemo<Array<ColumnDataType>>(
    () =>
      gameHasDisplayPrelockStatusOption
        ? [
            {
              key: "status",
              label: "STATUS",
              size: statusColumnSize,
              sortable: isSortingEnabled,
              render: (entry) => {
                return (
                  <BracketStatus
                    status={bracketStatus}
                    picksCount={entry.picksCount}
                    maxPicksCount={entry.maxPicksCount}
                    needsToMakePicks={entry.needsToMakePicks}
                  />
                )
              },
            },
          ]
        : [],
    [bracketStatus, gameHasDisplayPrelockStatusOption, isSortingEnabled, statusColumnSize],
  )

  const myBracketsColumns = useMemo(() => {
    const shouldShowAllColumns = ["LOCKED", "ARCHIVED"].includes(bracketStatus)
    const additionalColumns = shouldShowAllColumns ? allColumns : myBracketsStatusColumns
    return [...sharedColumns, ...additionalColumns].map((c) => ({ ...c, sortable: false, render: c.render ?? defaultDataEntryFnForKey(c.key) })) // remove sortable options from my brackets table
  }, [sharedColumns, myBracketsStatusColumns, bracketStatus, allColumns])

  const allBracketsColumns = useMemo(() => {
    const mapColumns = (c: ColumnDataType) => ({ ...c, render: c.render ?? defaultDataEntryFnForKey(c.key) })
    if (displayingArchivedPool) {
      return [...sharedColumns, ...allColumns].map(mapColumns)
    }

    if (bracketStatus === "COMING_SOON" || bracketStatus === "PICKABLE") {
      return [...sharedColumns, ...allBracketsStatusColumns].map(mapColumns)
    }
    return [...sharedColumns, ...allColumns].map(mapColumns)
  }, [bracketStatus, sharedColumns, allBracketsStatusColumns, allColumns, displayingArchivedPool])

  if (loading) {
    return <LoadingView />
  }

  if (showRoadBlock) {
    const roadblockUrl = `/games/roadblock?showCongrats=false&gameType=${gameInstanceUid}`
    return <Redirect to={roadblockUrl} />
  }

  const myBracketsTitle = (
    <Typography variant="SUBHEADING" spacing="COMPRESSED">
      {MY_BRACKETS_SUBTITLE}
    </Typography>
  )

  const renderBody = (body: ReactNode) => <TooltipBody>{body}</TooltipBody>

  const emptyBracketsText = () => {
    if (isLocked) {
      if (isManager) {
        return `Empty brackets have been removed from view. Those brackets are still accessible from Manage Players or Brackets Page via the search functionality.`
      } else {
        return `Empty brackets have been removed from view`
      }
    }
    return `Empty brackets will be removed from view once tournament locks`
  }

  const standingsNotificationComponent = !displayingArchivedPool && (
    <ClassicTooltip content={emptyBracketsText()} renderBody={renderBody} anchor={ANCHORS.BOTTOM_CENTER}>
      <InfoCircle className="home__standings-info-circle" fontSize="10px" color={palette.uiSubText1} hoverColor={palette.uiSubText1} />
    </ClassicTooltip>
  )

  const totalBracketsCount = isLocked ? standingsData?.gameInstance.pool.entriesWithPicksCount : standingsData?.gameInstance.pool.entriesCount

  const overallEntriesTitle = (
    <>
      <Typography variant="SUBHEADING" spacing="COMPRESSED">
        {OVERALL_SUBTITLE}
      </Typography>
      {gameHasDisplayCompletedBracketsCountOption && totalBracketsCount ? (
        <div className="home__standings-total-container">
          <Typography variant="BODY1" spacing="COMPRESSED" style={{ textAlign: "right" }}>{`Total Brackets: ${totalBracketsCount}`}</Typography>
          {standingsNotificationComponent}
        </div>
      ) : null}
    </>
  )
  const { hasIncompleteEntries } = standingsData?.gameInstance.pool || {}
  const showPicksReminderButton = !skipCheckForIncompleteEntries && hasIncompleteEntries
  const showRows = displayingArchivedPool || gameHasDisplayPrelockBracketsOption || !["COMING_SOON", "PICKABLE"].includes(bracketStatus)
  const locksAtDate = new Date(centralBracketState?.bracketState?.locksAt || NCAAB_BRACKET_LOCKS_AT_TIMESTAMP)
  const formattedTime = getHoursAndMinutesFromDate(locksAtDate).split(" ").join("")
  // display `noon` if 12:00PM otherwise display time in HH:MM(PM|AM) format
  const timeDisplayed = formattedTime === "12:00PM" ? "noon" : formattedTime
  const formattedLockDate = dateToString(locksAtDate, MonthFormat.long, DayFormat.twoDigit, undefined, WeekdayFormat.long)
  const bracketNotAvailableMessage = `Standings will be revealed after brackets lock at ${timeDisplayed} ET on ${formattedLockDate}.`
  const showStandingsDownload = isManager && !isChallengePool && !displayingArchivedPool
  const isWomenBracket = isNCAAWTournamentMatcher.test(gameInstanceUid)

  const newsletterModal = <NewsletterModal isOpen={!isWomenBracket && canShowNewsletterModal && deviceType !== "handheld"} />

  return (
    <>
      <AnalyticScreen feature="brackets" subfeature={`standings`} title={`Pool Standings`} deviceType={deviceType} />
      <PoolPageCardBody>
        <BracketsHomeWrapper>
          <div className="home__title-row">
            <div className="home__title-main">
              <Typography variant="HEADING" capitalization="UPPERCASE">
                {`${displayingSeasonYear ?? ""} ${MAIN_TITLE}`}
              </Typography>
              {showStandingsDownload && (
                <ClassicTooltip content="Download pool standings">
                  {bracketStatus === "LOCKED" ? (
                    <Link
                      className="home__title-download"
                      target="_blank"
                      rel="noopener"
                      href={`/college-basketball/pool/${poolData.poolId}/standings/export`}
                      to={`/college-basketball/pool/${poolData.poolId}/standings/export`}
                      external
                      onClick={() => {
                        Analytics.trackAction("brackets standings", "export icon", "export", "click")
                      }}
                    >
                      <ListItemIcon size="sm">
                        <ImportIcon color={palette.uiBlue1} />
                      </ListItemIcon>
                    </Link>
                  ) : (
                    <div style={{ marginLeft: "0.25rem" }}>
                      <ListItemIcon size="sm" onClick={showModal}>
                        <ImportIcon color={palette.uiBlue1} />
                      </ListItemIcon>
                    </div>
                  )}
                </ClassicTooltip>
              )}
              {showStandingsDownload && (
                <GenericConfirmationModal
                  isOpen={isExportStandingsModalOpen}
                  onConfirm={hideModal}
                  title="Export Standings"
                  message={`Exporting your standings will be available once the tournament starts and brackets have locked.`}
                  showCancel={false}
                  ctaButtonLabel="Close"
                />
              )}
            </div>
            <div className="home__title-row-extra">
              {showPicksReminderButton && <PicksReminderButton {...props} />}
              {gameHasDisplayPoolHistoryOption && <PoolHistoryDropdown options={historyDropdownOptions} onSelect={displayPoolStandings} />}
            </div>
          </div>
          {myEntries.length > 0 ? (
            <>
              <div className="home__subtitle-row">{myBracketsTitle}</div>
              <HorizontalShadowScroll className="home__bottom-margin">
                <StandingsTable entries={myEntries} columns={myBracketsColumns} />
              </HorizontalShadowScroll>
            </>
          ) : null}
          <div className="home__subtitle-row">{overallEntriesTitle}</div>
          <HorizontalShadowScroll offLeftElementClassName="home__off-left-scroll-shadow" offRightElementClassName="home__off-right-scroll-shadow">
            <StandingsTable
              className="home__overall-standings-table"
              sortOptions={allEntriesSortOptions}
              columns={allBracketsColumns}
              entries={showRows ? allEntries : emptyArray}
              noDataMessage={showRows ? undefined : bracketNotAvailableMessage}
              onSort={handleSortChange}
              currentUser={poolData.currentUser}
            />
          </HorizontalShadowScroll>
        </BracketsHomeWrapper>
      </PoolPageCardBody>
      {showRows && gameHasPaginationOption && <PaginationNew hasPreviousPage={!!pagination.after} goToPage={onGoToPage} {...pageInfo} />}
      {isLoggedIn && newsletterModal}
    </>
  )
}

export default BracketPoolHome
