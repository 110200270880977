import { ApolloClient, InMemoryCache, defaultDataIdFromObject, from, split, HttpLink } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries"
import { sha256 } from "crypto-hash"
import { RetryLink } from "@apollo/client/link/retry"
import { WebSocketLink } from "@apollo/client/link/ws"
import { getMainDefinition } from "apollo-utilities"
import { get as getCookie } from "es-cookie"
import constants from "../../common/constants"
import { importantCookieNames } from "../../common/cookies"
import settings from "../../settings"
import { createStateLink } from "./local-state"

declare global {
  // tslint:disable-next-line
  interface Window {
    __APOLLO_STATE__: any
  }
}

// https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-error
const errorHandlerLink = onError((allErrors) => {
  const { graphQLErrors, networkError } = allErrors
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      // skip if persisted query
      if (!/PersistedQueryNotFound/.test(message)) {
        console.warn(`[GraphQL error]: Message: '${JSON.stringify(message)}', Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(path)}`)
      }
    })
  }

  if (networkError) {
    console.error(`[Network error]: ${JSON.stringify(networkError)}`)
  }
})

// tslint:disable-next-line
function createApolloClient(): ApolloClient<any> {
  // hydrate from server query
  const cache = new InMemoryCache({
    dataIdFromObject: (obj: any) => {
      switch (obj.__typename) {
        case "RedisKeyObjectType":
          return obj.key
        default:
          return defaultDataIdFromObject(obj)
      }
    },
  }).restore(window.__APOLLO_STATE__)
  // add localstate
  const { writeDefaults, typeDefs, resolvers, initClient } = createStateLink(cache)
  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: constants.WS_API_ENDPOINT,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: () => {
        const cookies = {} as any
        if (typeof document !== "undefined") {
          for (const cookieName of importantCookieNames) {
            const val = getCookie(cookieName)
            if (val) {
              cookies[cookieName] = val
            }
          }
        }
        return {
          httpHeaders: {
            cookies, // : cookiestypeof(document) !== "undefined" && document.cookie || "",
            "user-agent": (typeof navigator !== "undefined" && navigator.userAgent) || "",
          },
        }
      },
    },
  })
  const httpOptions: Record<string, unknown> = {
    uri: constants.CLIENT_API_ENDPOINT,
    credentials: "include",
    fetch: (uri, options) => {
      let fetchUri = uri
      const windowUriSearch = window?.location?.search ?? ""
      if (windowUriSearch) {
        const fetchUriSearch = String(fetchUri).includes("?")
        if (fetchUriSearch) {
          // persistent queries might add search parameters to the uri
          fetchUri = `${fetchUri}&${windowUriSearch.substring(1)}`
        } else {
          fetchUri = `${fetchUri}${windowUriSearch}`
        }
      }
      return fetch(fetchUri, options)
    },
    headers: {
      // "x-stellate": `${constants.ENABLE_STELLATE}`,
      //   CAE: "true",
    },
  }
  const linksToCompose = [errorHandlerLink, new RetryLink(), new HttpLink(httpOptions)]
  if (!constants.ENABLE_STELLATE && constants.SSL && window.crypto.subtle) {
    linksToCompose.unshift(createPersistedQueryLink({ sha256, useGETForHashedQueries: true }))
  }
  const httpFullLink = from(linksToCompose)
  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      const isWsRequest = definition.kind === "OperationDefinition" && definition.operation === "subscription"
      // console.log(`isWsRequest: ${isWsRequest}`);
      return isWsRequest
    },
    wsLink,
    httpFullLink,
  )
  const client = new ApolloClient({
    connectToDevTools: true,
    link,
    cache,
    resolvers,
    typeDefs,
    name: "web",
    assumeImmutableResults: true,
    version: settings.GIT_REVISION,
    ...httpOptions,
  })
  writeDefaults()
  initClient(client)
  return client
}

export default createApolloClient
