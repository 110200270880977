import { ENUM_CONSENSUS, ENUM_WILLIAM_HILL_NEW_JERSEY } from "./common/enums"

const getOverrideFor = (key: string, defaultValue: any): string => {
  // if (key === "PUBLIC_SMTP_ENABLED") {
  //   console.log(`key: ${key} '${process.env.PUBLIC_SMTP_ENABLED}'`);
  // }
  return (key in process.env ? process.env[key] : defaultValue).toString()
}

// For the most part, we only want require APP_ENV and NODE_ENV
// Everything else should be correctly configured based on these
const NODE_ENV = getOverrideFor("NODE_ENV", "development")
// NOTE qac: IS_DOCKER is more of "is running inside of Dockerfile"
const IS_DOCKER = getOverrideFor("IS_DOCKER", false) === "true"
const IS_CI = getOverrideFor("CI", false) === "true"
const APP_ENV = getOverrideFor("APP_ENV", IS_DOCKER ? "dev" : "local")

let scoringFFBOpcQueueName
let scoringOpcQueueName
let scoringOpmPickemQueueName
let scoringOpmSurvivorQueueName
let picksWorkerQueueName = "http://localhost:9324"
switch (APP_ENV) {
  case "qa":
    scoringOpcQueueName = "picks-qa-opc-standings"
    scoringFFBOpcQueueName = "picks-qa-ffbopc-standings"
    picksWorkerQueueName = "picks-qa-worker"
    scoringOpmPickemQueueName = "picks-qa-opm-standings"
    scoringOpmSurvivorQueueName = "picks-qa-opm-survivor-standings"
    break
  case "prod":
    scoringOpcQueueName = "picks-prod-opc-standings"
    scoringFFBOpcQueueName = "picks-prod-ffbopc-standings"
    picksWorkerQueueName = "picks-prod-worker"
    scoringOpmPickemQueueName = "picks-prod-opm-standings"
    scoringOpmSurvivorQueueName = "picks-prod-opm-survivor-standings"
    break
  default:
    scoringOpcQueueName = "picks-dev-opc-standings"
    scoringFFBOpcQueueName = "picks-dev-ffbopc-standings"
    picksWorkerQueueName = "picks-dev-worker"
    scoringOpmPickemQueueName = "picks-dev-opm-standings"
    scoringOpmSurvivorQueueName = "picks-dev-opm-survivor-standings"
    break
}

const FORWARD_ENV = getOverrideFor("FORWARD_ENV", "dev")
// NOTE qac: use this flag with understanding that the setting should be consistent with prod ALWAYS
const IS_PRODISH = ["qa", "prod"].includes(APP_ENV)
const IS_LOCAL = ["local"].includes(APP_ENV)
const IS_DEV = ["dev"].includes(APP_ENV)
const IS_QA = ["qa"].includes(APP_ENV)
const IS_PROD = ["prod"].includes(APP_ENV)
const IS_TEST_ENV = ["test"].includes(APP_ENV)
const IS_LOCALISH = ["local", "test"].includes(APP_ENV)
const SPARKPOST_API_KEY = getOverrideFor("SPARKPOST_API_KEY", "e2f20eccfbb4dce9954a75db28754305f1ef6d3a")
const SPARKPOST_IP_POOL = getOverrideFor("SPARKPOST_IP_POOL", "fantasy_picks")
const SPARKPOST_ORIGIN = getOverrideFor("SPARKPOST_ORIGIN", "https://247sports.api.e.sparkpost.com")
const WORKER_RUN_INLINE = getOverrideFor("WORKER_RUN_INLINE", IS_LOCALISH) === "true"
const APP_LOG_LEVEL = getOverrideFor("APP_LOG_LEVEL", (IS_LOCAL && "DEBUG") || (IS_TEST_ENV && "INFO") || "INFO").toUpperCase()
const SAPI_ENV = getOverrideFor("SAPI_ENV", IS_PROD ? "prod" : "qa")
const PORT = parseInt(getOverrideFor("PORT", 4000), 10)

// Overall logic:
// - NODE_ENV="production" means:
// -- Compiled Code (es5)
// -- In /build directory
// -- React (frontend code) has a frozen process.env (snapshotted at `yarn build`)
// -- Node Server (./build/server.js) listens on HOST:PORT
// - NODE_ENV="development" means:
// -- babel all the things (which also handles typescript)
// -- In /src directory
// -- x2 webpack dev servers
// -- React dev server:
// ---- HOST:PORT
// -- Node dev server:
// ---- HOST:DEV_SERVER_PORT

// AWS_FILE_UPLOADS_BUCKET: getOverrideFor("AWS_FILE_UPLOADS_BUCKET", `i-aws-sports-picks-${["dev", "qa", "prod"].includes(APP_ENV) ? APP_ENV : 'dev'}-avatars`),
// const AWS_FILE_UPLOADS_USE_CF = getOverrideFor("AWS_FILE_UPLOADS_USE_CF", ["dev", "qa", "prod"].includes(APP_ENV)) === "true"
// const AWS_FILE_UPLOADS_BUCKET = getOverrideFor("AWS_FILE_UPLOADS_BUCKET", `i-aws-sports-picks-${["dev", "qa", "prod"].includes(APP_ENV) ? APP_ENV : 'dev'}-avatars`)
const hasS3AvatarBucket = ["dev", "qa", "prod"].includes(APP_ENV)
const defaultMaxDbPool = APP_ENV === "prod" ? 20 : 10
const hasDbHostReplicaConfig = process.env.hasOwnProperty("DB_HOST_REPLICA")
const REDIS_URL = getOverrideFor("REDIS_URL", IS_LOCALISH ? "redis://127.0.0.1:6379/0" : "")
const REDIS_URL_JOBS = getOverrideFor("REDIS_URL_JOBS", REDIS_URL)
const REDIS_CLUSTER_HOST = getOverrideFor("REDIS_CLUSTER_HOST", "")
const REDIS_CLUSTER_PORT = getOverrideFor("REDIS_CLUSTER_PORT", "")
const USER_REDIS_CLUSTER_HOST = getOverrideFor("USER_REDIS_CLUSTER_HOST", "")
const USER_REDIS_CLUSTER_PORT = getOverrideFor("USER_REDIS_CLUSTER_PORT", "")
const USER_REDIS_USES_CLUSTER = getOverrideFor("USER_REDIS_USES_CLUSTER", !IS_LOCALISH) === "true"
const defaultDbHost = IS_LOCALISH ? "127.0.0.1" : ""
const DB_HOST_REPLICA = getOverrideFor("DB_HOST_REPLICA", defaultDbHost)

const settings = {
  //  Overall toggle:
  NODE_ENV,
  APP_ENV,
  IS_DOCKER,
  FORWARD_ENV,
  IS_PRODISH,
  IS_LOCALISH,
  IS_LOCAL,
  IS_DEV,
  IS_QA,
  IS_PROD,
  IS_CI,
  IS_TEST_ENV,

  // NOTE qac: anything we want to appear in the client side HAS to be prefixed with PUBLIC_

  //  Server values:
  HOST: getOverrideFor("HOST", IS_DOCKER ? "0.0.0.0" : "localhost"), //  NOTE qac: its ok to switch this since MOST docker implementations
  APP_LOG_LEVEL,
  // NOTE qac: docker setup uses TASK=worker, ect here
  APP_RUN_CONTEXT: process.env.APP_RUN_CONTEXT || process.env.TASK || "unknown",
  PROTOCOL: getOverrideFor("PROTOCOL", IS_LOCALISH ? "http:" : "https:"),
  APOLLO_ENGINE_LOGGING_LEVEL: getOverrideFor("APOLLO_ENGINE_LOGGING_LEVEL", APP_LOG_LEVEL.toUpperCase()),
  APP_PRINT_SQL: getOverrideFor("APP_PRINT_SQL", APP_LOG_LEVEL.toUpperCase() === "DEBUG") === "true",
  PORT,
  DEV_SERVER_PORT: parseInt(getOverrideFor("DEV_SERVER_PORT", PORT + 1), 10),
  APP_SECRET: getOverrideFor("APP_SECRET", "1c1d12v2v3123v1b511"),
  HAS_BOTS: getOverrideFor("HAS_BOTS", false) === "true",
  APOLLO_ENGINE_KEY: getOverrideFor("APOLLO_ENGINE_KEY", "service:picks-platform:3uFuS2LVONbWS5ga5CeLuw"),
  WORKER_TYPE: getOverrideFor("WORKER_TYPE", "bull"),
  WORKER_RUN_INLINE,

  CBS_SLACK_WEBHOOK_URI: getOverrideFor("CBS_SLACK_WEBHOOK_URI", ""),
  CBS_SLACK_CHANNEL: getOverrideFor("CBS_SLACK_CHANNEL", ""),
  SLACK_WEBHOOK_URI: getOverrideFor("SLACK_WEBHOOK_URI", "https://hooks.slack.com/services/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"),
  SLACK_CHANNEL: getOverrideFor("SLACK_CHANNEL", IS_PROD ? "#i-sports-picks-builds-prod" : "#i-sports-picks-builds-dev"),
  PUBLIC_SENTRY_DSN: getOverrideFor("PUBLIC_SENTRY_DSN", IS_LOCALISH ? "" : "https://379572f5202844bebf71b6125b0d1c5e@sentry.io/1262166"),
  GIT_REVISION: getOverrideFor("GIT_REVISION", "unknown"),
  DOCKER_BUILD: getOverrideFor("DOCKER_BUILD", "unknown"),
  USE_CONSOLE_LOGGER: getOverrideFor("USE_CONSOLE_LOGGER", ["never"].includes(APP_ENV)) === "true",
  USE_PRETTY_LOGGER: getOverrideFor("USE_PRETTY_LOGGER", IS_LOCALISH) === "true",
  USE_PREV_YEAR_TOURNAMENTS: getOverrideFor("USE_PREV_YEAR_TOURNAMENTS", !IS_PRODISH) === "true",
  USE_PROD_SEEDING: getOverrideFor("USE_PROD_SEEDING", IS_PROD) === "true",
  SEED_PAST_SEASONS: getOverrideFor("SEED_PAST_SEASONS", true) === "true",
  SEED_SEASON: getOverrideFor("SEED_SEASON", "regular"),
  SEED_SPORT_YEAR: parseInt(getOverrideFor("SEED_SPORT_YEAR", 2021)),
  MARCH_MADNESS_SPORT_YEAR: parseInt(getOverrideFor("MARCH_MADNESS_SPORT_YEAR", 2024)),
  IS_IN_MANUAL_DATA_MODE: getOverrideFor("IS_IN_MANUAL_MODE", ["nope"].includes(APP_ENV)) === "true",
  // CBS settings
  // https://owl.cbsi.com/confluence/pages/viewpage.action?spaceKey=CBSSports&title=User+Login+and+Registration
  SAPI_ENV,
  SAPI_AUTH_DOMAIN: getOverrideFor("SAPI_AUTH_ORIGIN", `${SAPI_ENV === "prod" ? "www" : "www.qa"}.cbssports.com`),
  SAPI_ENABLED: getOverrideFor("SAPI_ENABLED", ["local", "dev", "qa", "prod"].includes(APP_ENV)) === "true",
  // NOTE qac: so server envs SHOULD hit -pub... but for some reason dev / qa were not configured correctly, and cannot use the -pub versions
  // for local envs, you should NOT use the -pub version
  SAPI_URL: getOverrideFor("SAPI_URL", IS_TEST_ENV ? "https://picks-dev.cbssports.com" : `https://sapi${IS_PROD ? "-pub" : "-qa"}.cbssports.com`),
  SAPI_AUTH_KEY: getOverrideFor("SAPI_AUTH_KEY", SAPI_ENV == "prod" ? "c49254b49744d1d1427ee73d63e557" : "18ebef66433d05cc459acd7aa131c2"),
  // NOTE qac: we need a different access key for getting a user's subscriptions
  SAPI_SUBSCRIPTION_ACCESS_TOKEN: getOverrideFor(
    "SAPI_SUBSCRIPTION_ACCESS_TOKEN",
    SAPI_ENV === "prod" ? "738949F8706E1CF6C3DE4CB16D99A5" : "51F62A609F7532F85F736929334C9D",
  ),
  SAPI_ACCESS_TOKEN_KEY: getOverrideFor("SAPI_ACCESS_TOKEN_KEY", "H4_l12#o01-kmD.3"),
  SAPI_ACCESS_TOKEN_IV: getOverrideFor("SAPI_ACCESS_TOKEN_IV", "Ji49.9z92lo"),
  SAPI_SHARED_SALT: getOverrideFor("SAPI_SHARED_SALT", "foijaFDeafijwfjioaweiwaDelf"),
  SAPI_ENCRYPTED_PID_TOKEN_KEY: getOverrideFor("SAPI_ENCRYPTED_PID_TOKEN_KEY", ""),
  SAPI_ENCRYPTED_PID_TOKEN_IV: getOverrideFor("SAPI_ENCRYPTED_PID_TOKEN_IV", ""),
  SAPI_PID_TOKEN_KEY: getOverrideFor("SAPI_PID_TOKEN_KEY", ""),
  // http://api.cbssports.com/swagger/index.html?url=http%3A%2F%2Fapi.cbssports.com%2Fprimpy%2Fapi-docs#/
  PRIMPY_HOSTNAME: getOverrideFor(
    "PRIMPY_HOSTNAME",
    ((IS_TEST_ENV || IS_PROD) && "http://sdf-api.cbssports.cloud/primpy") || "http://sdf-qa-api.cbssports.cloud/primpy",
  ),
  // NOTE LL: This is currently having a few hiccups, don't want to cutover until it's good to go
  PRIMPY_CALLER: getOverrideFor("PRIMPY_CALLER", "picks"),
  PRIMPY_ACCESS_TOKEN: getOverrideFor("PRIMPY_ACCESS_TOKEN", "1f14404ee6c5bb83ac98055a4897d31df29c8652"),
  STATS_LAB_HOSTNAME: getOverrideFor("SPORTS_LAB_HOSTNAME", "https://stats-lab.prod.datascience.cbssports.cloud"),
  STATS_LAB_TOKEN_HEADER: getOverrideFor("SPORTS_LAB_TOKEN_HEADER", `BTsoO5ww)@`),
  CBS_CMS_HOSTNAME: getOverrideFor("CBS_CMS_HOSTNAME", `https://api-cms.cbssports.com`),
  // ACTIVE_SPORT_TYPES: getOverrideFor("ACTIVE_SPORT_TYPES", "NCAAB,NCAAW"),
  // ACTIVE_SPORT_TYPES: getOverrideFor("ACTIVE_SPORT_TYPES", "NFL,NCAAF"),
  ACTIVE_SPORT_TYPES: getOverrideFor("ACTIVE_SPORT_TYPES", "NFL,NCAAF,NCAAB,NCAAW"),
  LEGACY_DB_ACTIVE_SPORT_TYPES: getOverrideFor("LEGACY_DB_ACTIVE_SPORT_TYPES", "NCAAB,NCAAW"),
  // LEGACY_DB_ACTIVE_SPORT_TYPES: getOverrideFor("LEGACY_DB_ACTIVE_SPORT_TYPES", ""),
  HELP_DESK_KEY: getOverrideFor("HELP_DESK_KEY", "SfhlpK3yab4tzyer"),
  HELP_DESK_IV: getOverrideFor("HELP_DESK_IV", "1234567890abcdef"),
  TORQ_HOST: getOverrideFor(
    "TORQ_HOST",
    [""].includes(APP_ENV) ? "http://torq-qa.cbssports.com/torq/handler" : "https://torq.cbssports.com/torq/handler",
  ),
  // TODO qac: we still dont have access to torq correctly:
  TORQ_ACCESS_TOKEN: getOverrideFor("TORQ_ACCESS_TOKEN", "892a040cf5d115dd5e1c4074bea413fb6153bbd4"), // "7D6D07554D7A07698675245762D44C1333EA36D6"),
  // TODO qac: we will use the primpy token when avail
  READONLY_ADMIN_PICKS_ACCESS_TOKEN: getOverrideFor(
    "READONLY_ADMIN_PICKS_ACCESS_TOKEN",
    "naWnCMFDy1Fy+dEzKCjfV0ZBau+ujvt0VUZI1ik1Q3NTmB/04AyUqOPMN2kOptWVtHxjDelS13GnYQ4YgNwxvQ==",
  ),

  // Urban Airship
  UA_APP_KEY: "nc2vzUKITAyG3E4PK9yQ_Q",
  UA_TOKEN: "MTpuYzJ2elVLSVRBeUczRTRQSzl5UV9ROkZPUnR1MVJkTUhYTExQQjAwT09yZzBYNE8yNGtLcFNDbFRxbjhvS01ISW8",
  UA_VAPID_PUBLIC_KEY: "BC-RGbqv3uep1fUhhSMMctITmleQkFudVf2vBx4oMylN5GfhzWCdeKfLZoXFNAf0QjnReX6Qpjj4qahS53w6LaQ=",

  UA_PUSH_APP_KEY: getOverrideFor("UA_PUSH_APP_KEY", "QtqzA_rzRGKODECkpyq37g"),
  UA_PUSH_APP_SECRET: getOverrideFor("UA_PUSH_APP_SECRET", "XF8Lm5SuQJ6eG3RY3rPOnw"),
  UA_PUSH_APP_MASTER_SECRET: getOverrideFor("UA_PUSH_APP_MASTER_SECRET", "QgNEtH-LRcOrnP8DY46wyw"),

  // AWS
  AWS_DEFAULT_REGION: getOverrideFor("AWS_DEFAULT_REGION", "us-east-1"),
  AWS_ECS_CLUSTER_NAME: getOverrideFor("AWS_ECS_CLUSTER_NAME", `picks-${IS_PROD ? "prod" : "staging"}`),
  AWS_ECS_WORKER_SERVICE_NAME: getOverrideFor("AWS_ECS_WORKER_SERVICE_NAME", `cbs-picks-${APP_ENV === "prod" ? "" : `-${APP_ENV}`}-worker`),
  AWS_ECS_WEB_SERVICE_NAME: getOverrideFor("AWS_ECS_WEB_SERVICE_NAME", `cbs-picks-${IS_PROD ? "" : `-${APP_ENV}`}-web`),
  AWS_FILE_UPLOADS_REGION: getOverrideFor("AWS_FILE_UPLOADS_REGION", "us-east-1"),
  AWS_FILE_UPLOADS_PROFILE: getOverrideFor("AWS_FILE_UPLOADS_PROFILE", ""),
  AWS_FILE_UPLOADS_USE_CF: getOverrideFor("AWS_FILE_UPLOADS_USE_CF", hasS3AvatarBucket) === "true",
  AWS_FILE_UPLOADS_BUCKET: getOverrideFor("AWS_FILE_UPLOADS_BUCKET", `i-aws-sports-picks-${hasS3AvatarBucket ? APP_ENV : "dev"}-avatars`),
  //BPM DATA MIGRATION
  AWS_BPM_MIGRATION_BUCKET: getOverrideFor("AWS_BPM_MIGRATION_BUCKET", "i-aws-bpm-migration-data"),
  AWS_SQS_QUEUE_OPC_SCORING: getOverrideFor("AWS_SQS_QUEUE_OPC_SCORING", scoringOpcQueueName),
  AWS_SQS_QUEUE_FFBOPC_SCORING: getOverrideFor("AWS_SQS_QUEUE_FFBOPC_SCORING", scoringFFBOpcQueueName),
  AWS_SIMULATE_GAME_DOMAIN: getOverrideFor("AWS_SIMULATE_GAME_DOMAIN", IS_LOCAL ? "http://127.0.0.1:3000" : ""),
  AWS_SQS_QUEUE_OPM_SURVIVOR_SCORING: getOverrideFor("AWS_SQS_QUEUE_OPM_SURVIVOR_SCORING", scoringOpmSurvivorQueueName),
  AWS_SQS_QUEUE_OPM_PICKEM_SCORING: getOverrideFor("AWS_SQS_QUEUE_OPM_PICKEM_SCORING", scoringOpmPickemQueueName),

  // SMTP / emails
  SPARKPOST_API_KEY,
  SPARKPOST_IP_POOL,
  SPARKPOST_ORIGIN,
  EXACT_TARGET_FTP_PW: getOverrideFor("EXACT_TARGET_FTP_PW", "4Dx..5Ye8a"),
  SEND_WELCOME_EMAIL: getOverrideFor("SEND_WELCOME_EMAIL", true) === "true",

  //  DB settings:
  USE_REPLICA: getOverrideFor("USE_REPLICA", hasDbHostReplicaConfig) === "true",
  DB_PRINT_SQL: getOverrideFor("DB_PRINT_SQL", false) === "true",
  DB_SOCKET_PATH: getOverrideFor("DB_SOCKET_PATH", ""),
  DB_ADAPTER: getOverrideFor("DB_ADAPTER", "mysql2"),
  DB_DATABASE: getOverrideFor("DB_DATABASE", `cbs${IS_TEST_ENV ? "_test" : ""}`),
  DB_HOST: getOverrideFor("DB_HOST", defaultDbHost),
  DB_HOST_REPLICA,
  DB_HOST_WORKER_REPLICA: getOverrideFor("DB_HOST_WORKER_REPLICA", DB_HOST_REPLICA),
  DB_USER: getOverrideFor("DB_USER", "root"),
  DB_PASSWORD: getOverrideFor("DB_PASSWORD", ""),
  DB_PORT: parseInt(getOverrideFor("DB_PORT", 3306), 10),
  DB_POOL_MAX: parseInt(getOverrideFor("DB_POOL_MAX", defaultMaxDbPool), 10),
  DB_POOL_MIN: parseInt(getOverrideFor("DB_POOL_MIN", 2), 10),
  DB_POOL_MAX_REPLICA: parseInt(getOverrideFor("DB_POOL_MAX_REPLICA", defaultMaxDbPool), 10),
  DB_POOL_MIN_REPLICA: parseInt(getOverrideFor("DB_POOL_MIN_REPLICA", 2), 10),
  REDIS_URL,
  REDIS_REPLICA_URL: getOverrideFor("REDIS_REPLICA_URL", REDIS_URL),
  REDIS_URL_JOBS,
  REDIS_REPLICA_URL_JOBS: getOverrideFor("REDIS_REPLICA_URL_JOBS", REDIS_URL_JOBS),
  REDIS_CLUSTER_HOST,
  REDIS_CLUSTER_PORT,
  // NOTE qac: for now we dont care to worry about this being sep:
  // REDIS_URL_WS: getOverrideFor("REDIS_URL_WS", REDIS_URL),
  // REDIS_REPLICA_URL_WS: getOverrideFor("REDIS_REPLICA_URL_WS", getOverrideFor("REDIS_REPLICA_URL", REDIS_URL)),
  // for the most part, we dont want to listen to torq when running jobs inline:
  // NOTE qac: if JOBS_LISTEN_TO_TORQ, we should use sep processes to prevent resource wasting / local env blocking
  JOBS_LISTEN_TO_TORQ: getOverrideFor("JOBS_LISTEN_TO_TORQ", false) === "true", // !WORKER_RUN_INLINE
  JOBS_USE_SEP_PROCESS: getOverrideFor("JOBS_USE_SEP_PROCESS", true) === "true",
  // NOTE qac: 0 means we compute from CPU units avail
  JOBS_SUBPROCESSES_COUNT: parseInt(getOverrideFor("JOBS_SUBPROCESSES_COUNT", 0), 10),
  JOBS_ENQUEUE_SCHEDULED_JOBS: getOverrideFor("JOBS_ENQUEUE_SCHEDULED_JOBS", true) === "true",
  JOBS_DEFAULT_QUEUE_NAME: getOverrideFor("JOBS_DEFAULT_QUEUE_NAME", "all"),
  JOBS_SECONDARY_QUEUE_NAME: getOverrideFor("JOBS_SECONDARY_QUEUE_NAME", "secondary"),
  CAN_AUTOSCALE: getOverrideFor("CAN_AUTOSCALE", IS_PROD) === "true",

  // *************************************************
  // public (available to the frontend + constants!)
  // all have to start with PUBLIC_

  // flags
  CACHE_PUBLIC_PAGINATION_ENABLED: getOverrideFor("CACHE_PUBLIC_PAGINATION_ENABLED", true) === "true",
  OS_METRICS_ENABLED: getOverrideFor("OS_METRICS_ENABLED", false) === "true",
  AUTOSCALE_ENABLED: getOverrideFor("AUTOSCALE_ENABLED", IS_PROD) === "true",
  PUBLIC_MESSAGE_BOARD_ENABLED: getOverrideFor("PUBLIC_MESSAGE_BOARD_ENABLED", true) === "true",
  PUBLIC_SL_NEWSLETTER_ENABLED: getOverrideFor("PUBLIC_SL_NEWSLETTER_ENABLED", !IS_PRODISH) === "true",
  PUBLIC_SMTP_ENABLED: getOverrideFor("PUBLIC_SMTP_ENABLED", true) === "true",
  PUBLIC_FRONTEND_PROXY_ENV: getOverrideFor("PUBLIC_FRONTEND_PROXY_ENV", ""),
  PUBLIC_USES_SW: getOverrideFor("PUBLIC_USES_SW", false) === "true",
  API_ONLY_MODE: getOverrideFor("API_ONLY_MODE", false) === "true",
  UI_ONLY_MODE: getOverrideFor("UI_ONLY_MODE", false) === "true",
  CLIENT_INTERNAL_API_ENDPOINT: getOverrideFor("CLIENT_INTERNAL_API_ENDPOINT", IS_LOCALISH ? "http://picks-local.cbssports.com:9000/graphql" : ""),
  ENABLE_AUTH_USER_OPTIMIZATIONS: getOverrideFor("ENABLE_AUTH_USER_OPTIMIZATIONS", true) === "true",
  ENABLE_ANON_HTML_CACHING: getOverrideFor("ENABLE_ANON_HTML_CACHING", true) === "true",
  ENABLE_CACHING: getOverrideFor("ENABLE_CACHING", true) === "true",
  USE_RESPONSE_CACHING: getOverrideFor("USE_RESPONSE_CACHING", true) === "true",
  TEST_ENV_PREFIX: getOverrideFor("TEST_ENV_PREFIX", ""),
  SDF_BRACKET_UID: getOverrideFor("SDF_BRACKET_UID", IS_PROD ? "76611c43-a230-4409-97c9-f5ff112d5002" : "6e2f788b-44a5-4f73-bdf2-1a3aece430ea"), // 76611c43-a230-4409-97c9-f5ff112d5002 is the bracket id for selection sunday
  SDF_WOMEN_BRACKET_UID: getOverrideFor(
    "SDF_WOMEN_BRACKET_UID",
    IS_PROD ? "af50315f-6b3c-4362-979e-ca7438c09759" : "22ed35c1-7aaf-44fd-91ef-964a8939ef1e",
  ), // af50315f-6b3c-4362-979e-ca7438c09759 is the bracket id for selection sunday
  // SDF_SWEET_SIXTEEN_BRACKET_UID: getOverrideFor("SDF_SWEET_SIXTEEN_BRACKET_UID", "8699298b-96b2-4605-92c0-7b37eb605f9c"),
  DEFAULT_APOLLO_REDIS_CACHE_TIME: parseInt(getOverrideFor("DEFAULT_APOLLO_REDIS_CACHE_TIME", 180)),
  RESPONSE_CACHE_POOL_PERIOD_QUERY_TTL: parseInt(getOverrideFor("RESPONSE_CACHE_POOL_PERIOD_QUERY_TTL", 180)),
  SPORTSLINE_SUBSCRIPTION_ID: parseInt(getOverrideFor("SPORTSLINE_SUBSCRIPTION_ID", 23350)),
  REFRESHABLE_CACHE_ITEM: getOverrideFor("REFRESHABLE_CACHE_ITEM", "REFRESHABLE_CACHE_ITEM"),
  NCAAB_CHALLENGE_MAX_ENTRIES_PER_USER: parseInt(getOverrideFor("NCAAB_CHALLENGE_MAX_ENTRIES_PER_USER", 3)),
  NCAAB_S16_CHALLENGE_MAX_ENTRIES_PER_USER: parseInt(getOverrideFor("NCAAB_S16_CHALLENGE_MAX_ENTRIES_PER_USER", 1)),
  APOLLO_REDIS_CACHE_PREFIX: getOverrideFor("APOLLO_REDIS_CACHE_PREFIX", "RESPONSE:"),
  CUSTOM_RESPONSE_CACHE_PREFIX: getOverrideFor("CUSTOM_RESPONSE_CACHE_PREFIX", "fqc:"),
  BRACKET_GLOBAL_STATE_CACHE_KEY: "BRACKET_GLOBAL_STATE_CACHE_KEY",
  PERF_FLAG_DISABLE_ARCHIVE_POOL_RESTORE: getOverrideFor("PERF_FLAG_DISABLE_ARCHIVE_POOL_RESTORE", false) === "true",
  ENTRIES_PER_MIN_FLAG_THRESHOLD: parseInt(getOverrideFor("ENTRIES_PER_MIN_FLAG_THRESHOLD", 5000)),
  REMOVE_JOB_ON_COMPLETE: getOverrideFor("REMOVE_JOB_ON_COMPLETE", true) === "true",
  JOBS_RATE_LIMITER_ENABLED: getOverrideFor("JOBS_RATE_LIMITER_ENABLED", false) === "true",
  JOBS_RATE_LIMITER_MAX: parseInt(getOverrideFor("JOBS_RATE_LIMITER_MAX", 10000), 10),
  JOBS_RATE_LIMITER_DURATION_MILLISECONDS: parseInt(getOverrideFor("JOBS_RATE_LIMITER_DURATION_MILLISECONDS", 1000), 10),
  ENABLE_BRACKETS_AUTO_EVENT_DETECTION: getOverrideFor("ENABLE_BRACKETS_AUTO_EVENT_DETECTION", true) === "true",
  SAPI_TO_PICKS_ACCESS_TOKEN_VALUE: getOverrideFor("SAPI_TO_PICKS_ACCESS_TOKEN_VALUE", ""),
  SEED_UPCOMING_GAMES: getOverrideFor("SEED_UPCOMING_GAMES", true) === "true", //Whether to seed upcoming games
  USER_REDIS_CLUSTER_HOST,
  USER_REDIS_CLUSTER_PORT,
  USER_REDIS_USES_CLUSTER,
  USER_REDIS_DEFAULT_TTL: parseInt(getOverrideFor("USER_REDIS_DEFAULT_TTL", 432000)), // 5 days
  SYNC_PICKS_DATA_WITH_SPOE_DATA: getOverrideFor("SYNC_PICKS_DATA_WITH_SPOE_DATA", true) === "true",
  UIGATEWAY_BASE_URL: getOverrideFor("UIGATEWAY_BASE_URL", `http://ui-gateway.${IS_PROD ? "prod" : "qa"}.picks.cbssports.cloud/ui-gateway`),
  // UIGATEWAY_BASE_URL: getOverrideFor("UIGATEWAY_BASE_URL", `http://localhost:8083/ui-gateway`),
  ENABLE_MESSAGE_BOARD_EMAILS: getOverrideFor("ENABLE_MESSAGE_BOARD_EMAILS", true) === "true",
  EMAIL_TEMPLATE_BUCKET: getOverrideFor(
    "EMAIL_TEMPLATE_BUCKET",
    `https://i-aws-sports-picks-email-templates${IS_PROD ? "-prod" : ""}.s3.amazonaws.com`,
  ),
  TRACKED_BOOKS: getOverrideFor("TRACKED_BOOKS", `${ENUM_WILLIAM_HILL_NEW_JERSEY},${ENUM_CONSENSUS}`),

  EVENT_EXTRA_TTL: parseInt(getOverrideFor("EVENT_EXTRA_TTL", 10)),
  ENABLE_GAMETRACKER_LINK: getOverrideFor("ENABLE_GAMETRACKER_LINK", !IS_PROD) === "true",
  SWEET_16_ENABLED: getOverrideFor("SWEET_16_ENABLED", false) === "true",
  SAPI_POOL_NAME_LIMIT: parseInt(getOverrideFor("SAPI_POOL_NAME_LIMIT", 100)),
  SAPI_ENTRY_NAME_LIMIT: parseInt(getOverrideFor("SAPI_ENTRY_NAME_LIMIT", 80)),
  BRACKETS_STANDINGS_RUN_CACHE_KEY: getOverrideFor("BRACKETS_STANDINGS_RUN_CACHE_KEY", "BRACKETS_STANDINGS_RUN_CACHE_KEY"),
  MAX_CHARACTERS_PER_MESSAGE_BOARD_MESSAGE: parseInt(getOverrideFor("MAX_CHARACTERS_PER_MESSAGE_BOARD_MESSAGE", 3000)), // We're adding some buffer here becaus slate is adding markup and it's taking up a lot of characters
  ENABLE_EXACT_TARGET_SYNC: getOverrideFor("ENABLE_EXACT_TARGET_SYNC", true) === "true",
  NEWSLETTER_247_BASE_URL: getOverrideFor("NEWSLETTER_247_AUTH_ENDPOINT", IS_PROD ? "https://ipa.247sports.com" : "https://ipa.247sportsstage.com"),
  NEWSLETTER_247_PARTNER_KEY: getOverrideFor("NEWSLETTER_247_PARTNER_KEY", "78be9e89ad8744bbb699f3d600ff1418"),
  APP_CONFIG_BUCKET: getOverrideFor("APP_CONFIG_BUCKET", `https://sports.cbsimg.net/picks-games`),
  COUNTER_KEY: getOverrideFor("COUNTER_KEY", "PICKS_COUNTER_KEY"),
  AWS_SQS_QUEUE_WORKER: getOverrideFor("AWS_SQS_QUEUE_WORKER", picksWorkerQueueName),
  AWS_SQS_QUEUE_URL_WORKER: getOverrideFor("AWS_SQS_QUEUE_URL_WORKER", ""),
  SHOW_GAMBLING_PARTNER_BRANDING: getOverrideFor("SHOW_GAMBLING_PARTNER_BRANDING", false) === "true",
  ADMIN_BASE_URL: getOverrideFor("ADMIN_BASE_URL", "http://picks-local.cbssports.com:3002"),
  ENABLE_OPEN_INVITES: getOverrideFor("OPEN_INVITES", true) === "true",
  STELLATE_SERVICE_NAME: getOverrideFor("STELLATE_LOGGER_SERVICE_NAME", ""),
  STELLATE_LOGGER_TOKEN: getOverrideFor("STELLATE_LOGGER_TOKEN", ""),
  STELLATE_PURGE_TOKEN: getOverrideFor("STELLATE_PURGE_TOKEN", ""),
  ENABLE_STELLATE: getOverrideFor("ENABLE_STELLATE", false) === "true",
  IS_TESTING: getOverrideFor("IS_TESTING", false) === "true",
  ENABLE_MANAGE_PALYERS_SORTING_THRESHOLD: parseInt(getOverrideFor("ENABLE_MANAGE_PALYERS_SORTING_THRESHOLD", 350)),
  ENABLE_LARGE_POOL_BRACKET_STATE_CACHE: getOverrideFor("ENABLE_LARGE_POOL_BRACKET_STATE_CACHE", true) === "true",
  EXACT_TARGET_QUEUE_URL: getOverrideFor("EXACT_TARGET_QUEUE_URL", ""),
  PICKS_LEGACY_SUBGRAPH_NAME: getOverrideFor("PICKS_LEGACY_SUBGRAPH_NAME", "__picks_legacy__"),
  ENABLE_OPERATION_TIME_PLUGIN: getOverrideFor("ENABLE_OPERATION_TIME_PLUGIN", IS_LOCAL) === "true",
  OPERATION_TIME_THRESHOLD: getOverrideFor("OPERATION_TIME_THRESHOLD", `${IS_LOCAL ? 0 : 2500}`),
  USE_CLIENT_SETTINGS: getOverrideFor("USE_CLIENT_SETTINGS", true) === "true",
  METRICS_ENABLED: getOverrideFor("METRICS_ENABLED", true) === "true",
  NCAA_RIP_OUT_SL: getOverrideFor("NCAA_RIP_OUT_SL", true) === "true",
}

export default settings
