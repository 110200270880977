export const ENUM_PARLAY = "PARLAY"
export const ENUM_CHALLENGE = "CHALLENGE"
export const ENUM_BRACKET = "BRACKET"
export const ENUM_PICKEM = "PICKEM"
export const ENUM_MANAGER = "MANAGER"
export const ENUM_AVAILABLE = "AVAILABLE"
export const ENUM_UNAVAILABLE = "UNAVAILABLE"
export const ENUM_PRE = "pre"
export const ENUM_REGULAR = "regular"
export const ENUM_POST = "post"
export const ENUM_CONFERENCE_TOURNAMENT = "ConferenceTournament"
export const ENUM_NFL = "NFL"
export const ENUM_NCAAB = "NCAAB"
export const ENUM_NCAAW = "NCAAW"
export const ENUM_NCAAF = "NCAAF"

export const ENUM_UNDERDOG = "UNDERDOG"
export const ENUM_FAVORED = "FAVORED"
export const ENUM_TIE = "TIE"
export enum SeasonArchiveStatusEnum {
  partial = "partial",
  full = "full",
}

export enum MessageCategoryEnum {
  PUBLIC = "PUBLIC",
  SUBSECT = "SUBSECT",
  ALL = "ALL",
}
export enum FormInputTypeEnum {
  CHECKBOX = "CHECKBOX",
}

export enum AutoPickProgrammaticEnum {
  // EXPERT_CHIP_PATTERSON = "EXPERT_CHIP_PATTERSON",
  // EXPERT_KYLE_BOONE = "EXPERT_KYLE_BOONE",
  // EXPERT_SL_MIKE_TIERNEY = "EXPERT_SL_MIKE_TIERNEY",
  TOP_SEEDS = "TOP_SEEDS",
  USER_FAVORITES = "USER_FAVORITES",
  HISTORICAL_RANDOM = "HISTORICAL_RANDOM",
  RANDOM = "RANDOM",
  SCORING_MARGIN = "SCORING_MARGIN",
  TOP_25_WINS = "TOP_25_WINS",
  AVERAGE_SCORING = "AVERAGE_SCORING",

  EXPERT_JERRY_PALM = "EXPERT_JERRY_PALM",
  EXPERT_GARY_PARRISH = "EXPERT_GARY_PARRISH",
  EXPERT_MATT_NORLANDER = "EXPERT_MATT_NORLANDER",
  EXPERT_ISAAC_TROTTER = "EXPERT_ISAAC_TROTTER",
  EXPERT_JOSH_NAGEL = "EXPERT_JOSH_NAGEL",
  EXPERT_ISABEL_GONZALEZ = "EXPERT_ISABEL_GONZALEZ",
  EXPERT_JACK_MALONEY = "EXPERT_JACK_MALONEY",
  EXPERT_ALICIA_JAY = "EXPERT_ALICIA_JAY",
  EXPERT_BRANDON_CLAY = "EXPERT_BRANDON_CLAY",
  EXPERT_AARON_BARZILAI = "EXPERT_AARON_BARZILAI",

  //Sportline options
  EXPERT_OPTIMAL_BRACKET = "EXPERT_OPTIMAL_BRACKET",
  EXPERT_UPSET_BRACKET = "EXPERT_UPSET_BRACKET",
  EXPERT_SL_CALVIN_WETZEL = "EXPERT_SL_CALVIN_WETZEL",

  // Retired experts
  EXPERT_WALLY_SZCZERBIAK = "EXPERT_WALLY_SZCZERBIAK",
  EXPERT_TIM_DOYLE = "EXPERT_TIM_DOYLE",
  EXPERT_SARAH_KUSTOK = "EXPERT_SARAH_KUSTOK",
  EXPERT_TRAVIS_BRANHAM = "EXPERT_TRAVIS_BRANHAM",
  EXPERT_ERICA_AYALA = "EXPERT_ERICA_AYALA",
  EXPERT_MARIA_CORNELIUS = "EXPERT_MARIA_CORNELIUS",
  EXPERT_TERRIKA_FOSTER_BRASBY = "EXPERT_TERRIKA_FOSTER_BRASBY",
}
export enum AutopickBasicEnum {
  OPTIMAL = "s_optimal",
  UPSET = "s_upset",
}

export enum EntryOrderByEnum {
  OVERALL_RANK = "OVERALL_RANK",
  PERIOD_RANK = "PERIOD_RANK",
  ROLES = "ROLES",
  PROMOTED_TO_COPY = "PROMOTED_TO_COPY",
  CHAMPION = "CHAMPION",
  MAX_POINTS = "MAX_POINTS",
  CORRECT_PICKS = "CORRECT_PICKS",
  USER_ENTRIES_FIRST = "USER_ENTRIES_FIRST",
  STATUS = "STATUS",
}
export enum SortingOrderEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export const ENUM_TEXT_FORMAT = "TEXT"
export const ENUM_HTML_FORMAT = "HTML"

export enum PoolRoleEnum {
  MANAGER = "MANAGER",
}
export enum ClientsEnum {
  CBS = "CBS",
  NISSAN = "NISSAN",
  TESTING_CBS = "TESTING_CBS",
}
export enum SportTypesEnum {
  NFL = "NFL",
  NCAAB = "NCAAB",
  NCAAF = "NCAAF",
  NCAAW = "NCAAW",
}
export enum SeasonEnumType {
  post = "post",
  pre = "pre",
  regular = "regular",
}
// export const SeasonEnumType = {
//   post: "post" as enum_seasonType,
//   pre: "pre" as enum_seasonType,
//   regular: "regular" as enum_seasonType,
// }
export enum GameTypeEnum {
  BRACKET = "BRACKET",
  PARLAY = "PARLAY",
  PICKEM = "PICKEM",
  SURVIVOR = "SURVIVOR",
}
// export const GameTypeEnum = {
//   BRACKET: "BRACKET" as enum_gameType,
//   PARLAY: "PARLAY" as enum_gameType,
//   PICKEM: "PICKEM" as enum_gameType,
// }
// const UNAVAILABLE = "UNAVAILABLE";
export enum AnalyticsChannelEnum {
  nfl = "nfl",
  collegebasketball = "collegebasketball",
  collegefootball = "collegefootball",
}

export enum SportEnum {
  football = "football",
  baseball = "baseball",
  collegefb = "collegefb",
  hockey = "hockey",
  basketball = "basketball",
  cricket = "cricket",
  soccer = "soccer",
  racing = "racing",
  golf = "golf",
  mayhem = "mayhem",
}

export enum ArenaEnum {
  mlb = "mlb",
  nba = "nba",
  nfl = "nfl",
  nhl = "nhl",
  ncaaf = "ncaaf",
  ncaab = "ncaab",
  ncaaw = "ncaaw",
  cricket = "cricket",
  soccer = "soccer",
  racing = "racing",
  golf = "golf",
  mayhem = "mayhem",
}

export enum ArenaTypeEnum {
  football = "football",
  baseball = "baseball",
  collegefb = "collegefb",
  hockey = "hockey",
  basketball = "basketball",
  cricket = "cricket",
  soccer = "soccer",
  racing = "racing",
  golf = "golf",
  mayhem = "mayhem",
}

export enum LeagueTypeEnum {
  nfl = "nfl",
  ncaaf = "ncaaf",
  nhl = "nhl",
  mls = "mls",
  ncaab = "ncaab",
  ncaaw = "ncaaw",
  mlb = "mlb",
  nba = "nba",
  pga = "pga",
}
export enum TtagSportTypeEnum {
  nfl = "nfl",
  cbb = "cbb",
  nhl = "nhl",
  mls = "mls",
  cfb = "cfb",
  cwb = "cwb", // College Women's Basketball
  mlb = "mlb",
  nba = "nba",
  pga = "pga",
  unk = "unk",
}
export enum PoolTypeEnum {
  CHALLENGE = "CHALLENGE",
  MANAGER = "MANAGER",
}
export enum PeriodLengthEnum {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  ROUND = "ROUND",
  FULL = "FULL",
}
export enum PicksDeadlineTypeEnum {
  BEFORE_START_OF_EACH_GAME = "BEFORE_START_OF_EACH_GAME",
  BEFORE_START_OF_DAYS_FIRST_GAME = "BEFORE_START_OF_DAYS_FIRST_GAME",
  BEFORE_START_OF_PERIODS_FIRST_GAME = "BEFORE_START_OF_PERIODS_FIRST_GAME",
  BEFORE_START_OF_EACH_TOURNAMENT = "BEFORE_START_OF_EACH_TOURNAMENT",
}

export type SpreadEnumType = "CBS_SPREAD" | "CUSTOM_SPREAD" | "STRAIGHT_UP" | "UNAVAILABLE" | "WH_SPREAD"
export enum SpreadTypeEnum {
  UNAVAILABLE = "UNAVAILABLE",
  STRAIGHT_UP = "STRAIGHT_UP",
  CBS_SPREAD = "CBS_SPREAD",
  WH_SPREAD = "WH_SPREAD",
  CUSTOM_SPREAD = "CUSTOM_SPREAD",
}
export enum GameWeightTypeEnum {
  UNAVAILABLE = "UNAVAILABLE",
  NONE = "NONE",
  CONFIDENCE = "CONFIDENCE",
  MULTIPLY_SEED = "MULTIPLY_SEED",
  VEGAS = "VEGAS",
  ROUND = "ROUND",
  CUSTOM = "CUSTOM",
  FROM_MAX = "FROM_MAX",
}
export enum RoundBonusTypeEnum {
  UNAVAILABLE = "UNAVAILABLE",
  NONE = "NONE",
  STANDARD = "STANDARD",
  ADD_SEED = "ADD_SEED",
  MULTIPLY_SEED = "MULTIPLY_SEED",
  VEGAS = "VEGAS",
}
export enum RoundModifierTypeEnum {
  NONE = "NONE",
  ADD_SEED = "ADD_SEED",
  MULTIPLY_SEED = "MULTIPLY_SEED",
}
export enum TiebreakerTypeEnum {
  UNAVAILABLE = "UNAVAILABLE",
  NONE = "NONE",
  TOTAL_SCORE = "TOTAL_SCORE",
  ROUND_TOTALS = "ROUND_TOTALS",
  FIRST_TO_PICK = "FIRST_TO_PICK",
  TOURNAMENT_WINNER = "TOURNAMENT_WINNER",
  CUSTOM = "CUSTOM",
}
export enum TiebreakerQuestionKeyEnum {
  NONE = "NONE",
  HOME_TEAM_SCORE = "HOME_TEAM_SCORE",
  AWAY_TEAM_SCORE = "AWAY_TEAM_SCORE",
  HOME_TEAM_SCORE_2 = "HOME_TEAM_SCORE_2",
  AWAY_TEAM_SCORE_2 = "AWAY_TEAM_SCORE_2",
  TOTAL_OFFENSIVE_YARDS = "TOTAL_OFFENSIVE_YARDS",
  TOURNAMENT_WINNER = "TOURNAMENT_WINNER",
  TOTAL_SCORE = "TOTAL_SCORE",
  CUSTOM = "CUSTOM",
}
export enum ForgotPickFallbackEnum {
  UNAVAILABLE = "UNAVAILABLE",
  NONE = "NONE",
  LOWEST_SCORER = "LOWEST_SCORER",
  LOWEST_SCORER_MINUS_ONE = "LOWEST_SCORER_MINUS_ONE",
}
export enum AvailableOptionEnum {
  UNAVAILABLE = "UNAVAILABLE",
  AVAILABLE = "AVAILABLE",
}
export enum GamesPerPeriodEnum {
  UNAVAILABLE = "UNAVAILABLE",
  ALL = "ALL",
  CBS = "CBS",
  CUSTOM = "CUSTOM",
}
export enum SubsectionEnum {
  NONE = "",
  ConferenceTournament = "ConferenceTournament",
  NcaaTournament = "NcaaTournament",
  NcaawTournament = "NcaawTournament",
  NcaaTournamentSweetSixteen = "NcaaTournamentSweetSixteen",
  Draft = "Draft",
  // NcaafBowlGames = "NcaafBowlGames",
}

export enum DeleteReasonKeyEnum {
  OFFENSIVE_LANGUAGE = "OFFENSIVE_LANGUAGE",
  FOUL_LANGUAGE = "FOUL_LANGUAGE",
  ATTACKING_ANOTHER_MEMBER = "ATTACKING_ANOTHER_MEMBER",
  OTHER = "OTHER",
}

export const ENUM_CONSENSUS = "CONSENSUS"
export const ENUM_BET365 = "BET365"
export const ENUM_CAESARS = "CAESARS"
export const ENUM_MGM = "MGM"

export const ENUM_ISE = "ISE"
export const ENUM_DRAFT_KINGS = "DRAFT_KINGS"
export const ENUM_BET365_NEW_JERSEY = "BET365_NEW_JERSEY"
export const ENUM_BET888 = "BET888"
export const ENUM_CANTORGAMING = "CANTORGAMING"
export const ENUM_WYNN = "WYNN"
export const ENUM_WILLIAM_HILL_CASINO = "WILLIAM_HILL_CASINO"
export const ENUM_WESTGATE = "WESTGATE"
export const ENUM_POINTS_BET = "POINTS_BET"
export const ENUM_WILLIAM_HILL_NEW_JERSEY = "WilliamHillNewJersey"
export const ENUM_CBS = "CBS"

export const ENUM_HOME = "HOME"
export const ENUM_AWAY = "AWAY"
export const ENUM_NONE = "NONE"
export const ENUM_UP = "UP"
export const ENUM_DOWN = "DOWN"
export const ENUM_NEUTRAL = "NEUTRAL"
export const ENUM_OVER = "OVER"
export const ENUM_UNDER = "UNDER"

export const ENUM_POOL_ORDER_DEFAULT = "DEFAULT"
export const ENUM_POOL_ORDER_ENTRY_COUNT = "ENTRY_COUNT"

export const ENUM_PUBLIC_CATEGORY_FEATURED = "FEATURED"
export const ENUM_PUBLIC_CATEGORY_FAN = "FAN"
export const ENUM_PUBLIC_CATEGORY_STANDARD = "STANDARD"

export const ENUM_CANCELLED = "CANCELLED"
export const ENUM_POSTPONED = "POSTPONED"
export const ENUM_FINAL = "FINAL"
export const ENUM_SCHEDULED = "SCHEDULED"

export enum PoolSettingsTypesEnum {
  LEGACY_POOL_SETTINGS = "PoolSettings",
  POOL_SETTINGS_OPM = "PoolSettingsOPM",
  POOL_SETTINGS_SURVIVOR = "PoolSettingsSurvivor",
}

export enum ProductAbbrevEnum {
  /**
   * CBS Sports Women's Bracket Challenge Game
   */
  WBPC = "wbpc",
  /**
   * CBS Sports Men's Bracket Challenge Game
   */
  BPC = "bpc",
  /**
   * CBS Sports Men's Bracket Manager Game
   */
  BPM = "bpm",
  /**
   * CBS Sports Women's Bracket Manager Game
   */
  WBPM = "wbpm",
  /**
   * CBS Sports Men's Bracket Sweet Sixteen Challenge Game
   */
  SSBPC = "ssbpc",
  /**
   * Nissan Men's Bracket Manager Game
   */
  WLNBPC = "wlnbpc",
  /**
   * Nissan Women's Bracket Manager Game
   */
  WLNWBPC = "wlnwbpc",
  /**
   * OPC
   */
  OPC = "opc",
  /**
   * College Football OPC,
   */
  FFBOPC = "ffbopc",
  /**
   * College Football OPM,
   */
  FFBOPM = "ffbopm",
  /**
   * College Football Playoff OPC,
   */
  FFBPOPC = "ffbpopc",
  /**
   * Parlay OPC,
   */
  PLOPC = "plopc",
  /**
   * Playoff OPM,
   */
  POPM = "popm",
  /**
   * Playoff OPC,
   */
  POPC = "popc",
  /**
   * CBS Sports Conference Bracket Challenge Game
   */
  CNFBPC = "cnfbpc",
  /**
   * CBS Sports Conference Bracket Manager Game
   */
  CNFBPM = "cnfbpm",
  /**
   * CBS Sports Football Pick'em + Survivor Manager Games
   */
  OPM = "opm",
}

export enum GameInstanceUidEnum {
  /**
   * CBS Sports Women's Bracket Challenge Game
   */
  NCAAW_CHALLENGE = "cbs-ncaaw-tournament-challenge",
  /**
   * CBS Sports Men's Bracket Challenge Game
   */
  NCAAB_CHALLENGE = "cbs-ncaab-tournament-challenge",
  /**
   * CBS Sports Men's Bracket Manager Game
   */
  NCAAB_MANAGER = "cbs-ncaab-tournament-manager",
  /**
   * CBS Sports Women's Bracket Manager Game
   */
  NCAAW_MANAGER = "cbs-ncaaw-tournament-manager",
  /**
   * CBS Sports Men's Bracket Sweet Sixteen Challenge Game
   */
  NCAAB_SWEET_SIXTEEN = "cbs-ncaab-tournament-sweet-sixteen-challenge",
  /**
   * Nissan Men's Bracket Manager Game
   */
  NISSAN_NCAAB_CHALLENGE = "nissan-ncaab-tournament-challenge",
  /**
   * Nissan Women's Bracket Manager Game
   */
  NISSAN_NCAAW_CHALLENGE = "nissan-ncaaw-tournament-challenge",
}
