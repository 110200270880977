import React from "react"
import styled from "styled-components/macro"
import { fontSizes, fontWeight, palette, pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import constants from "../../../../common/constants"
import { dateToString, DayFormat, MonthFormat, YearFormat } from "@cbs-sports/sports-shared-client/build/cjs/utils/helper-utils"
import CBSSportsHQSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/CBSSportsHQ"
import LiveSvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Live"
import PlaySvg from "@cbs-sports/sports-shared-client/build/cjs/components/icons/Play"
const Wrapper = styled.div`
  background-color: ${palette.white};
  border: 1px solid ${palette.gray90};
  box-sizing: border-box;
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  color: ${palette.gray20};
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${palette.gray90};

    & > span.live-icon {
      width: ${pxToRem(38)};
      height: ${pxToRem(20)};
    }
    & > span.cbs-sports-hq-logo {
      width: ${pxToRem(148)};
      height: ${pxToRem(14)};
    }
  }

  & .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    & > a.text {
      font-size: ${fontSizes.regular};
      font-weight: ${fontWeight.bold};
      letter-spacing: -0.1px;
      line-height: 1.25rem;
      padding-right: 1rem;
      z-index: 1;
      &:hover {
        color: ${palette.lightBlue3};
      }
    }

    & > .thumbnail {
      position: relative;
      flex-shrink: 0;
      width: ${pxToRem(60)};
      height: ${pxToRem(60)};
      img {
        width: 100%;
        height: 100%;
        border-radius: 0.25rem;
      }
      & > .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        z-index: 1;
        background-color: ${palette.white};
        border-radius: 50%;
        opacity: 0.8;
        transition: all 0.1s ease-out;
        width: 1.5rem;
        height: 1.5rem;

        & .play-icon {
          color: ${palette.darkRed};
          position: absolute;
          width: 1.5rem;
          height: 1.5rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      & :hover .play-button {
        transform: translate(-50%, -50%) scale(1.15);
      }
    }
  }
`
const staticRoot = `${constants.DOMAIN}/static`
const componentLink = " https://www.cbssports.com/live?ftag=CBS-16-10aca7e"
const datesWithLiveGames = {
  // Adding 3 days (11-13) for testing
  "2/21/2025": [
    {
      startTime: new Date(2025, 1, 21, 13, 0, 0).getTime(), // 1pm-4pm ET
      endTime: new Date(2025, 1, 21, 16, 0, 0).getTime(),
    },
  ],
  "3/16/2025": [
    {
      startTime: new Date(2025, 2, 16, 19, 0, 0).getTime(), // 7pm-11pm ET
      endTime: new Date(2025, 2, 16, 23, 0, 0).getTime(),
    },
  ],
  "3/20/2025": [
    {
      startTime: new Date(2025, 2, 20, 11, 0, 0).getTime(), // 11am-12am ET (11am-11:59pm)
      endTime: new Date(2025, 2, 21, 0, 0, 0).getTime(),
    },
  ],
  "3/21/2025": [
    {
      startTime: new Date(2025, 2, 21, 11, 0, 0).getTime(), // 11am-12am ET (11am-11:59pm)
      endTime: new Date(2025, 2, 22, 0, 0, 0).getTime(),
    },
  ],
  "3/22/2025": [
    {
      startTime: new Date(2025, 2, 22, 11, 0, 0).getTime(), // 11am-12am ET (11am-11:59pm)
      endTime: new Date(2025, 2, 23, 0, 0, 0).getTime(),
    },
  ],
  "3/23/2025": [
    {
      startTime: new Date(2025, 2, 23, 11, 0, 0).getTime(), // 11am-12am ET (11am-11:59pm)
      endTime: new Date(2025, 2, 24, 0, 0, 0).getTime(),
    },
  ],
  "3/27/2025": [
    {
      startTime: new Date(2025, 2, 27, 19, 0, 0).getTime(), // 7pm - 11pm
      endTime: new Date(2025, 2, 27, 23, 0, 0).getTime(),
    },
  ],
  "3/28/2025": [
    {
      startTime: new Date(2025, 2, 28, 19, 0, 0).getTime(), // 7pm - 11pm
      endTime: new Date(2025, 2, 28, 23, 0, 0).getTime(),
    },
  ],
  "3/29/2025": [
    {
      startTime: new Date(2025, 2, 29, 19, 0, 0).getTime(), // 7pm - 11pm
      endTime: new Date(2025, 2, 29, 23, 0, 0).getTime(),
    },
  ],
  "3/30/2025": [
    {
      startTime: new Date(2025, 2, 30, 19, 0, 0).getTime(), // 7pm - 11pm
      endTime: new Date(2025, 2, 30, 23, 0, 0).getTime(),
    },
  ],
}
const shouldShowComponent = (): boolean => {
  const currentDate = new Date()
  const formattedDate = dateToString(currentDate, MonthFormat.default, DayFormat.default, YearFormat.numeric)
  const gameDay = datesWithLiveGames[formattedDate]
  if (gameDay) {
    const currentTime = currentDate.getTime()
    return gameDay.some(({ startTime, endTime }) => startTime < currentTime && currentTime < endTime)
  }
  return false
}

interface IProps {
  locksAt: number
}
const SportsHQLiveCard: React.FC<IProps> = ({ locksAt }) => {
  const todaysDate = Date.now()
  const textToShow =
    todaysDate < locksAt
      ? "Stream CBS Sports HQ and get the inside edge on your bracket."
      : "Stream CBS Sports HQ for all your NCAA Tournament news, highlights and coverage."

  return shouldShowComponent() ? (
    <Wrapper>
      <div className="header">
        <span className="cbs-sports-hq-logo">
          <CBSSportsHQSvg />
        </span>
        <span className="live-icon">
          <LiveSvg />
        </span>
      </div>

      <div className="content">
        <a href={componentLink} target="_blank" rel="noreferrer noopener nofollow" className="text">
          {textToShow}
        </a>
        <a className="thumbnail" href={componentLink} target="_blank" rel="noreferrer noopener nofollow">
          <img src={`${staticRoot}/sport-assets/basketball/HQ_120x120px.jpg`} alt="thumbnail" aria-label="thumbnail" width="60" height="60" />
          <div className="play-button">
            <span className="play-icon">
              <PlaySvg />
            </span>
          </div>
        </a>
      </div>
    </Wrapper>
  ) : null
}

export default SportsHQLiveCard
