import { TGameType, TSeasonType, TSubsectionType } from "./db-typings"
import {
  GamesPerPeriodEnumType,
  GameWeightEnumType,
  PeriodLengthEnumType,
  PicksDeadlineEnumType,
  RoundBonusEnumType,
  TiebreakerEnumType,
} from "../__generated__/globalTypes"
import { awayValue, homeValue } from "./common-utils-helpers"
import constants from "./constants"
import {
  ArenaEnum,
  AvailableOptionEnum,
  ENUM_UNAVAILABLE,
  ForgotPickFallbackEnum,
  GamesPerPeriodEnum,
  GameTypeEnum,
  GameWeightTypeEnum,
  LeagueTypeEnum,
  PeriodLengthEnum,
  PicksDeadlineTypeEnum,
  RoundBonusTypeEnum,
  SeasonEnumType,
  SportEnum,
  SpreadTypeEnum,
  SubsectionEnum,
  TiebreakerQuestionKeyEnum,
  TiebreakerTypeEnum,
  SportTypesEnum,
  RoundModifierTypeEnum,
  PoolSettingsTypesEnum,
  SpreadEnumType,
} from "./enums"
import { FinancesFeesFrequencySurvivor } from "./pool-settings-survivor"
import { FinancesFeesFrequencyOPM, MissedPeriodsOPMEnum } from "./pool-settings-opm"

export interface ITypesEnum<T> {
  label: string
  description?: string
  value: T // | "" | "NONE";
  enabledGameTypes?: TGameType[]
  disabled?: boolean
  val?: number
}
export type TTypesEnumArray<T> = Array<ITypesEnum<T>>

export type TRoundTimeframe = {
  startPeriod: number
  endPeriod?: number
}

export type AvailabilityOption = "UNAVAILABLE" | "AVAILABLE"

export interface IFullPoolSettings {
  picksDeadlineType: PicksDeadlineEnumType
  periodLength: PeriodLengthEnumType
  spreadType: "CBS_SPREAD" | "CUSTOM_SPREAD" | "STRAIGHT_UP" | "UNAVAILABLE" | "WH_SPREAD"
  // rest are optional currently, lack means its not an option for managers!
  multipleEntriesOption: AvailabilityOption
  maxEntriesPerUser: number
  mainTiebreaker: TiebreakerEnumType
  secondaryTiebreaker?: TiebreakerEnumType
  thirdTiebreaker?: TiebreakerEnumType
  fourthTiebreaker?: TiebreakerEnumType
  // forgotPickFallback?: string;
  gamesPerPeriod: GamesPerPeriodEnumType
  pickCountRangeOption: AvailabilityOption
  minPicksPerPeriodCount: number
  maxPicksPerPeriodCount: number | null
  gameWeightType: GameWeightEnumType
  // public gameWeights: any, // Map<string,number>,
  // gameWeightTotalPointsPerMatchup?: number;
  // bracketMarginOfVictoryBonus?: string;
  roundBonusType: RoundBonusEnumType
  // roundBonusTotalPointsPerMatchup?: number;
  roundBonuses: number[] | null
  roundModifiersOption: AvailabilityOption
  roundModifiers: RoundModifierTypeEnum[] | null
  // format?: string;
  tournamentIds: number[] | null
  includeChampionshipRound: boolean | null
  includeMessageBoard: boolean | null
  openInvites: boolean | null
  __typename: PoolSettingsTypesEnum
}

export type PoolSettingsCheckType = Pick<
  IFullPoolSettings,
  | "gameWeightType"
  | "roundBonusType"
  | "roundBonuses"
  | "picksDeadlineType"
  | "mainTiebreaker"
  | "pickCountRangeOption"
  | "minPicksPerPeriodCount"
  | "maxPicksPerPeriodCount"
  | "tournamentIds"
  | "periodLength"
  | "maxEntriesPerUser"
  | "gamesPerPeriod"
  | "roundModifiers"
  | "roundModifiersOption"
  | "includeMessageBoard"
  | "openInvites"
  | "spreadType"
  | "includeChampionshipRound"
  | "__typename"
>

export const EventSides = [awayValue, homeValue]
export const ReversedEventSides = [homeValue, awayValue]

export const HelpDeskProductAbbrevMapping = {
  pc: 31,
  opm: 40,
  opc: 41,
  bpm: 40,
  bpc: 50,
  wbpc: 50,
  wbpm: 40,
  ssbpc: 50,
  wlnbpc: 50,
  wlnwbpc: 50,
  bpcr: 60,
  cnfbpc: 70,
  cnfbpm: 80,
  popm: 101,
  popc: 102,
  ffbopc: 103,
  ffbopm: 104,
  plopc: 105,
  ffbpopm: 106,
  ffbpopc: 107,
}

export const BugLinkUrlSportMapping = {
  // [SportEnum.baseball]: 11218,
  [SportEnum.football]: 21720,
  [SportEnum.collegefb]: 21724,
  // [SportEnum.hockey]: 11220,
  // [SportEnum.basketball]: 11219,
  // [SportEnum.cricket]: ,
  // [SportEnum.soccer]: ,
  // [SportEnum.racing]: ,
  // [SportEnum.golf]: ,
  [SportEnum.mayhem]: 21725,
}

export const BugLinkUrlLeagueMapping = {
  // [LeagueTypeEnum.mlb]: 11218,
  [LeagueTypeEnum.nfl]: 21720,
  [LeagueTypeEnum.ncaaf]: 21724,
  // [LeagueTypeEnum.nhl]: 11220,
  // [LeagueTypeEnum.nba]: 11219,
  [LeagueTypeEnum.ncaab]: 11222,
  // [LeagueTypeEnum.mls]: ,
  //   TEN   => 11224,
  // [LeagueTypeEnum.pga]: 11223,
}

// We do not want these now
// export const BugLinkUrlProductAbbrevMapping = {
//   pc: 31,
//   opm: 11308,
//   opc: 11309,
//   bpm: 11308,
//   bpc: 11309,
//   bpcr: 11310,
//   cnfbpc: 70,
//   cnfbpm: 80,
//   popm: 101,
//   popc: 102,
//   ffbopc: 103,
//   ffbopm: 104,
//   plopc: 105,
//   plopm: 106,
//   ffbpopm: 107,
//   ffbpopc: 108,
// };

// export const BugLinkFeatureMapping = {
//   schedule: 11313,
//   scoring: 11314,
//   setup: 11315,
//   stats: 11316, // stats and standings are the same value
//   standings: 11316,
//   transactions: 11317,
//   misc: 11318,
//   lobby: 11319,
// }

const unavailableLabel = "Unavailable"
export const SeasonAttributes = ["productAbbrev", "season", "year", "challengePoolSlug", "masterProductId"]
export const SegmentAttributes = ["sportType", "seasonType", "gameType", "subsection"]

export const HelpDeskSportMapping = {
  [SportEnum.baseball]: "BB",
  [SportEnum.football]: "FB",
  [SportEnum.collegefb]: "CFB",
  [SportEnum.hockey]: "HK",
  [SportEnum.basketball]: "BK",
  [SportEnum.cricket]: "CK",
  [SportEnum.soccer]: "SC",
  [SportEnum.racing]: "NC",
  [SportEnum.golf]: "GO",
  [SportEnum.mayhem]: "BK",
}

export const SportByLeagueMapping = {
  [LeagueTypeEnum.mlb]: SportEnum.baseball,
  [LeagueTypeEnum.nfl]: SportEnum.football,
  [LeagueTypeEnum.ncaaf]: SportEnum.collegefb,
  [LeagueTypeEnum.nhl]: SportEnum.hockey,
  [LeagueTypeEnum.nba]: SportEnum.basketball,
  [LeagueTypeEnum.ncaab]: SportEnum.mayhem,
  [LeagueTypeEnum.ncaaw]: SportEnum.mayhem,
  [LeagueTypeEnum.mls]: SportEnum.soccer,
  [LeagueTypeEnum.pga]: SportEnum.golf,
}

export const getArenaForNewsSlug = (sportOrLeague: string) => {
  let arena = ``
  switch (sportOrLeague.toLowerCase()) {
    case LeagueTypeEnum.nfl.toLowerCase():
      arena = LeagueTypeEnum.nfl
      break
    case SportEnum.football.toLowerCase():
      arena = LeagueTypeEnum.nfl
      break
    case LeagueTypeEnum.ncaaf.toLowerCase():
      arena = `college-football`
      break
    case LeagueTypeEnum.ncaab.toLowerCase():
      arena = `college-basketball`
      break
    case LeagueTypeEnum.ncaaw.toLowerCase():
      arena = `womens-college-basketball`
      break
    default:
      arena = ``
      break
  }
  return arena
}

export const SportTypeByLeagueMapping = {
  [SportTypesEnum.NFL]: SportEnum.football,
  [SportTypesEnum.NCAAF]: SportEnum.collegefb,
  [SportTypesEnum.NCAAB]: SportEnum.mayhem,
}

export const SportTypeByArenaMapping = {
  [SportTypesEnum.NFL]: ArenaEnum.nfl,
  [SportTypesEnum.NCAAF]: ArenaEnum.ncaaf,
  [SportTypesEnum.NCAAB]: ArenaEnum.ncaab,
}
export const ArenaBySportMapping = {
  [SportEnum.baseball]: ArenaEnum.mlb,
  [SportEnum.football]: ArenaEnum.nfl,
  [SportEnum.collegefb]: ArenaEnum.ncaaf,
  [SportEnum.hockey]: ArenaEnum.nhl,
  [SportEnum.basketball]: ArenaEnum.nba,
  [SportEnum.cricket]: ArenaEnum.cricket,
  [SportEnum.soccer]: ArenaEnum.soccer,
  [SportEnum.racing]: ArenaEnum.racing,
  [SportEnum.golf]: ArenaEnum.golf,
  [SportEnum.mayhem]: ArenaEnum.mayhem,
}

// "DAILY" | "MONTHLY" | "WEEKLY" | "ROUND" | "FULL"
const pickem = GameTypeEnum.PICKEM
const bracket = GameTypeEnum.BRACKET
const parlay = GameTypeEnum.PARLAY

export const FinancesFeesFrequencySurvivorType = [
  { label: "One Time Fee", value: FinancesFeesFrequencySurvivor.ONE_TIME },
  { label: "Once Per Round", value: FinancesFeesFrequencySurvivor.PER_ROUND },
  { label: "Unknown", value: FinancesFeesFrequencySurvivor.UNKNOWN },
]

export const FinancesFeesFrequencyOPMType = [
  { label: "One Time Fee", value: FinancesFeesFrequencyOPM.ONE_TIME },
  { label: "Once Per Week", value: FinancesFeesFrequencyOPM.PER_WEEK },
  { label: "Unknown", value: FinancesFeesFrequencyOPM.UNKNOWN },
]

export const MissedPeriodsOPMType = [
  { label: "Zero Points", value: MissedPeriodsOPMEnum.ZERO },
  { label: "Lowest Score Minus One", value: MissedPeriodsOPMEnum.LOWEST_MINUS_ONE },
  { label: "Unknown", value: MissedPeriodsOPMEnum.UNKNOWN },
]
export const PeriodLengthType = [
  { label: "Daily periods", value: PeriodLengthEnum.DAILY, disabled: true },
  { label: "Weekly", value: PeriodLengthEnum.WEEKLY },
  { label: "Monthly", value: PeriodLengthEnum.MONTHLY, disabled: true },
  { label: "Each Round", value: PeriodLengthEnum.ROUND },
  { label: "Length of Season / Tournament", value: PeriodLengthEnum.FULL },
] as TTypesEnumArray<PeriodLengthEnum>

export const PicksDeadlineType = [
  {
    label: "Before the start of each game",
    value: PicksDeadlineTypeEnum.BEFORE_START_OF_EACH_GAME,
  },
  {
    label: "Before the start of the days first game",
    value: PicksDeadlineTypeEnum.BEFORE_START_OF_DAYS_FIRST_GAME,
    disabled: true,
  },
  {
    label: "Before the start of the round",
    value: PicksDeadlineTypeEnum.BEFORE_START_OF_PERIODS_FIRST_GAME,
  },
  {
    label: "Before the start of each tournament",
    value: PicksDeadlineTypeEnum.BEFORE_START_OF_EACH_TOURNAMENT,
    enabledGameTypes: [bracket],
  },
] as TTypesEnumArray<PicksDeadlineTypeEnum>
// ['STRAIGHT_UP', 'CBS_SPREAD', 'CUSTOM_SPREAD']

export const SpreadType = [
  { label: unavailableLabel, value: SpreadTypeEnum.UNAVAILABLE },
  { label: "Straight Up", value: SpreadTypeEnum.STRAIGHT_UP },
  { label: "Against CBS Spread", value: SpreadTypeEnum.CBS_SPREAD },
  { label: "Against William Hill Spread", value: SpreadTypeEnum.WH_SPREAD },
  {
    label: "Against Custom Spread",
    value: SpreadTypeEnum.CUSTOM_SPREAD,
    disabled: true,
  },
] as TTypesEnumArray<SpreadTypeEnum>
export const nonSpreadSpreadTypes = ["STRAIGHT_UP", "UNAVAILABLE"] as SpreadEnumType[]
export const spreadsRequiringManualApprovalTypes = ["CBS_SPREAD", "CUSTOM_SPREAD"] as SpreadEnumType[]

// const Format = [
//   {label: "Standard", value: "STANDARD", enabledGameTypes: [pickem]},
//   {label: "Single Tournament", value: "SINGLE_TOURNAMENT", enabledGameTypes: [bracket]},
//   {label: "Multiple Tournaments", value: "MULTIPLE_TOURNAMENTS", enabledGameTypes: [bracket]},
//   // {label: "Custom", value: "CUSTOM_SPREAD", disabled: true, enabledGameTypes: [bracket]},
// ];

// ['NONE', 'STANDARD', 'VEGAS', 'ROUND', 'CUSTOM']
export const GameWeightType = [
  { label: unavailableLabel, value: GameWeightTypeEnum.UNAVAILABLE },
  { label: "None", value: GameWeightTypeEnum.NONE, enabledGameTypes: [pickem] },
  {
    label: "By Confidence Points",
    value: GameWeightTypeEnum.CONFIDENCE,
    enabledGameTypes: [pickem],
  },
  { label: "By Seed", value: GameWeightTypeEnum.MULTIPLY_SEED },
  {
    label: "Vegas Odds",
    value: GameWeightTypeEnum.VEGAS,
    disabled: true,
    enabledGameTypes: [pickem],
  },
  {
    label: "Round Weights",
    value: GameWeightTypeEnum.ROUND,
    disabled: true,
    enabledGameTypes: [bracket],
  },
  {
    label: "Custom",
    value: GameWeightTypeEnum.CUSTOM,
    disabled: true,
    enabledGameTypes: [pickem],
  },
  {
    label: "From Max",
    value: GameWeightTypeEnum.FROM_MAX,
    enabledGameTypes: [pickem],
  },
] as TTypesEnumArray<GameWeightTypeEnum>

export const RoundBonusTypes = [
  { label: unavailableLabel, value: RoundBonusTypeEnum.UNAVAILABLE },
  { label: "None", value: RoundBonusTypeEnum.NONE },
  { label: "Standard (1-x)", value: RoundBonusTypeEnum.STANDARD },
  { label: "Add Seed", value: RoundBonusTypeEnum.ADD_SEED, disabled: true },
  {
    label: "Multiply Seed",
    value: RoundBonusTypeEnum.MULTIPLY_SEED,
    disabled: true,
  },
  { label: "Vegas Odds", value: RoundBonusTypeEnum.VEGAS, disabled: true },
] as TTypesEnumArray<RoundBonusTypeEnum>

export const RoundModifierTypes = [
  { label: "None", value: RoundModifierTypeEnum.NONE },
  { label: "Add Seed", value: RoundModifierTypeEnum.ADD_SEED },
  { label: "Multiply Seed", value: RoundModifierTypeEnum.MULTIPLY_SEED },
] as TTypesEnumArray<RoundModifierTypeEnum>

export interface TiebreakerQuestionsType {
  label: string
  key: TiebreakerQuestionKeyEnum
}
export const ValidTiebreakerQuestions: TiebreakerQuestionsType[] = [
  {
    label: "Total Points for Home Team",
    key: TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE,
  },
  {
    label: "Total Points for Away Team",
    key: TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE,
  },
  {
    label: "Total Points for Home Team",
    key: TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE_2,
  },
  {
    label: "Total Points for Away Team",
    key: TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE_2,
  },
  {
    label: "Total Offensive Yards",
    key: TiebreakerQuestionKeyEnum.TOTAL_OFFENSIVE_YARDS,
  },
  { label: "Winner", key: TiebreakerQuestionKeyEnum.TOURNAMENT_WINNER },
  { label: "Total Score", key: TiebreakerQuestionKeyEnum.TOTAL_SCORE },
]
export const TiebreakerTypesWithQuestions = [
  TiebreakerTypeEnum.CUSTOM,
  TiebreakerTypeEnum.TOTAL_SCORE,
  TiebreakerTypeEnum.TOURNAMENT_WINNER,
] as string[]
export const TiebreakerTypesWithQuestionOptions = [TiebreakerTypeEnum.TOURNAMENT_WINNER] as string[]
export const TiebreakerTypesForCustomOnlyQuestions = [
  TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE,
  TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE,
  TiebreakerQuestionKeyEnum.TOTAL_OFFENSIVE_YARDS,
]
export const TiebreakerTypesForSecondEventCustomQuestions = [TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE_2, TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE_2]
export const TiebreakerTypesForCombinedWithCustomQuestions = [TiebreakerQuestionKeyEnum.TOTAL_OFFENSIVE_YARDS]

// const nonCustomTypes = [TiebreakerQuestionKeyEnum.TOTAL_SCORE, TiebreakerQuestionKeyEnum.TOURNAMENT_WINNER]
export const TiebreakerTypes = [
  { label: unavailableLabel, value: TiebreakerTypeEnum.UNAVAILABLE },
  { label: "None", value: TiebreakerTypeEnum.NONE },
  { label: "Total score", value: TiebreakerTypeEnum.TOTAL_SCORE },
  { label: "Round Totals", value: TiebreakerTypeEnum.ROUND_TOTALS },
  {
    label: "First to submit picks",
    value: TiebreakerTypeEnum.FIRST_TO_PICK,
    disabled: true,
  },
  {
    label: "Custom correct/incorrect questions",
    value: TiebreakerTypeEnum.CUSTOM,
    disabled: true,
    enabledGameTypes: [parlay, pickem, bracket],
  },
  {
    label: "Winner",
    value: TiebreakerTypeEnum.TOURNAMENT_WINNER,
    disabled: true,
    enabledGameTypes: [pickem],
  },
] as TTypesEnumArray<TiebreakerTypeEnum>

export const ForgotPickFallback = [
  { label: unavailableLabel, value: ForgotPickFallbackEnum.UNAVAILABLE },
  {
    label: "Award Zero Points",
    value: ForgotPickFallbackEnum.NONE,
    enabledGameTypes: [pickem],
  },
  {
    label: "Award the lowest correct score for the period",
    value: ForgotPickFallbackEnum.LOWEST_SCORER,
    disabled: true,
    enabledGameTypes: [pickem],
  },
  {
    label: "Award the lowest correct score for the period minus 1 point",
    value: ForgotPickFallbackEnum.LOWEST_SCORER_MINUS_ONE,
    disabled: true,
    enabledGameTypes: [pickem],
  },
] as TTypesEnumArray<ForgotPickFallbackEnum>

export const MultipleEntriesOption = [
  {
    label: unavailableLabel,
    value: AvailableOptionEnum.UNAVAILABLE,
    enabledGameTypes: [bracket],
  },
  {
    label: "Available",
    value: AvailableOptionEnum.AVAILABLE,
    enabledGameTypes: [bracket],
  },
] as TTypesEnumArray<AvailableOptionEnum>

export const PickCountRangeOption = [
  {
    label: unavailableLabel,
    value: AvailableOptionEnum.UNAVAILABLE,
    enabledGameTypes: [parlay, pickem],
  },
  {
    label: "Available",
    value: AvailableOptionEnum.AVAILABLE,
    enabledGameTypes: [parlay, pickem],
  },
] as TTypesEnumArray<AvailableOptionEnum>

export const RoundModifiersOption = [
  {
    label: unavailableLabel,
    value: AvailableOptionEnum.UNAVAILABLE,
  },
  {
    label: "Available",
    value: AvailableOptionEnum.AVAILABLE,
  },
] as TTypesEnumArray<AvailableOptionEnum>

export const GamesPerPeriod = [
  { label: unavailableLabel, value: GamesPerPeriodEnum.UNAVAILABLE },
  { label: "All", value: GamesPerPeriodEnum.ALL, enabledGameTypes: [pickem] },
  {
    label: "CBS Choice",
    value: GamesPerPeriodEnum.CBS,
    enabledGameTypes: [pickem],
  },
  {
    label: "Custom",
    value: GamesPerPeriodEnum.CUSTOM,
    enabledGameTypes: [pickem],
  },
] as TTypesEnumArray<GamesPerPeriodEnum>
export const nonCustomGamesPerPeriod = ["ALL", "UNAVAILABLE"] as GamesPerPeriodEnumType[]
// const BracketMarginOfVictoryBonus = [
//   {label: "No", value: "NO"},
//   {label: "Yes", value: "YES", disabled: true},
// ];

export const SeasonTypeOptions = [
  { label: "Preseason", value: SeasonEnumType.pre as TSeasonType, val: 0.3 },
  {
    label: "Regular Season",
    value: SeasonEnumType.regular as TSeasonType,
    val: 0.6,
  },
  {
    label: "Post (Playoff) Season",
    value: SeasonEnumType.post as TSeasonType,
    val: 0.9,
  },
] as TTypesEnumArray<SeasonEnumType>

export const SubsectionTypes = [
  { label: "None", value: SubsectionEnum.NONE },
  {
    label: "Official NCAA Conference Tournaments",
    value: SubsectionEnum.ConferenceTournament,
  },
  // {label: "College Football Bowl Games", value: SubsectionEnum.NcaafBowlGames},
  {
    label: "NCAA official tournament",
    value: SubsectionEnum.NcaaTournament,
  },
  {
    label: "NCAA Sweet 16 tournament",
    value: SubsectionEnum.NcaaTournamentSweetSixteen,
  },
  {
    label: "NCAAW official tournament",
    value: SubsectionEnum.NcaawTournament,
  },
  { label: "Draft event", value: SubsectionEnum.Draft, disabled: true },
] as TTypesEnumArray<TSubsectionType>
export const MaxPicksPerPeriodCountTypes = [
  { label: "All Games", value: null },
  { label: "1 Game", value: 1 },
]
let i = 1
// NOTE qac: we need to allow for stuff like ncaaf bowl games which have close to the max
while (MaxPicksPerPeriodCountTypes.length < constants.POOL_ALLOWABLE_EVENTS_PER_PERIOD / 2 + 1) {
  i++
  MaxPicksPerPeriodCountTypes.push({
    label: `${i} Game${i === 1 ? "" : "s"}`,
    value: i,
  })
}

export const PoolSettingsOptions = {
  picksDeadlineType: PicksDeadlineType,
  spreadType: SpreadType,
  gameWeightType: GameWeightType,
  mainTiebreaker: TiebreakerTypes,
  secondaryTiebreaker: TiebreakerTypes,
  thirdTiebreaker: TiebreakerTypes,
  fourthTiebreaker: TiebreakerTypes,
  // forgotPickFallback: ForgotPickFallback,
  gamesPerPeriod: GamesPerPeriod,
  maxPicksPerPeriodCount: MaxPicksPerPeriodCountTypes,
  // gameWeightTotalPointsPerMatchup,
  // bracketMarginOfVictoryBonus: BracketMarginOfVictoryBonus,
  roundBonusType: RoundBonusTypes,
  // roundBonusTotalPointsPerMatchup,
}
export const PoolSettingsLabels = {
  picksDeadlineType: "Pick Deadline",
  spreadType: "Format",
  mainTiebreaker: "Tie-breaker",
  secondaryTiebreaker: "2nd Tie-breaker",
  thirdTiebreaker: "3rd Tie-breaker",
  fourthTiebreaker: "4th Tie-breaker",
  // forgotPickFallback: "Missed Pick",
  gamesPerPeriod: "Games",
  minPicksPerPeriodCount: "Minumum required picks",
  maxPicksPerPeriodCount: "Number of Games",
  // gameWeightTotalPointsPerMatchup,
  // bracketMarginOfVictoryBonus: "Margin Bonus",
  roundBonusType: "Round Bonuses",
  gameWeightType: "Game Weights",
  tournamentIds: "Tournament Ids",
  includeChampionshipRound: "Include Championship Week",
  // roundBonusTotalPointsPerMatchup,
}

export const SPORT_TYPE_WITH_SECOND_EVENT_OF_THE_PERIOD_ID: SportTypesEnum[] = [SportTypesEnum.NCAAF, SportTypesEnum.NFL]
// poolSettings is a trump merge between:
// DefaultPoolSettings <- Segment.poolSettings <- Pool.poolSettings
export const DefaultPoolSettings: IFullPoolSettings = {
  // maxEntriesPerUser: 1,
  picksDeadlineType: PicksDeadlineTypeEnum.BEFORE_START_OF_EACH_GAME,
  periodLength: PeriodLengthEnum.DAILY,
  spreadType: ENUM_UNAVAILABLE,
  // rest are optional currently, lack means its not an option for managers!
  multipleEntriesOption: ENUM_UNAVAILABLE,
  maxEntriesPerUser: 1,
  mainTiebreaker: ENUM_UNAVAILABLE,
  secondaryTiebreaker: ENUM_UNAVAILABLE,
  thirdTiebreaker: ENUM_UNAVAILABLE,
  fourthTiebreaker: ENUM_UNAVAILABLE,
  // forgotPickFallback?: string;
  gamesPerPeriod: ENUM_UNAVAILABLE,
  pickCountRangeOption: ENUM_UNAVAILABLE,
  minPicksPerPeriodCount: 1,
  maxPicksPerPeriodCount: null,
  gameWeightType: ENUM_UNAVAILABLE,
  // public gameWeights: any, // Map<string,number>,
  // gameWeightTotalPointsPerMatchup?: number;
  // bracketMarginOfVictoryBonus?: string;
  roundBonusType: ENUM_UNAVAILABLE,
  // roundBonusTotalPointsPerMatchup?: number;
  roundBonuses: null,
  roundModifiers: null,
  roundModifiersOption: ENUM_UNAVAILABLE,
  // format?: string;
  tournamentIds: null,
  includeChampionshipRound: null,
  includeMessageBoard: null,
  openInvites: null,
  __typename: PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS,
}

export const PoolSettingAttributes = Object.keys(DefaultPoolSettings)

// interface ITypeOption<T> {
//   enabledGameTypes?: string[];
// }
export const enabledForGameType = <T>(gameType: any, typeOptions: ITypesEnum<T>) => {
  if (typeOptions.enabledGameTypes) {
    return typeOptions.enabledGameTypes?.includes(gameType)
  }
  return true
}
export const optionsForGameType = <T>(gameType: any, typeOptions: TTypesEnumArray<T>) => {
  return typeOptions.filter((o) => enabledForGameType(gameType, o))
}
export const cannotSupportGameType = <T>(gameType: any, typeOptions: TTypesEnumArray<T>) => {
  return optionsForGameType(gameType, typeOptions).length === 0
}

export const getDiff = (poolSettings, basePoolSettings) => {
  const diff = {}
  Object.keys(poolSettings).forEach((key) => {
    const value = poolSettings[key]
    const baseValue = basePoolSettings[key]
    if (typeof value === "object") {
      // throw new Error(`PoolSettings must be a flat map!`);
      if (JSON.stringify(value) != JSON.stringify(baseValue)) {
        diff[key] = value
      }
    }
    if (value !== baseValue) {
      diff[key] = value
    }
  })
  return diff
}
// export const hasDiff = (poolSettings, basePoolSettings) => Object.keys(getDiff(poolSettings, basePoolSettings)).length > 0

function tiebreakerQuestionSortFor(tiebreakerQuestion: TiebreakerQuestionsType) {
  if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.TOTAL_SCORE) {
    return 0.8
  } else if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE) {
    return 0.7
  } else if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE) {
    return 0.6
  } else if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE_2) {
    return 0.5
  } else if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE_2) {
    return 0.4
  } else if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.TOURNAMENT_WINNER) {
    return 0.9
  } else if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.TOTAL_OFFENSIVE_YARDS) {
    return 0.1
  }
  return 0.0
}

export function tiebreakerQuestionSort(a: TiebreakerQuestionsType, b: TiebreakerQuestionsType) {
  return tiebreakerQuestionSortFor(b) - tiebreakerQuestionSortFor(a)
}
