import { ISelectOption } from "@cbs-sports/sports-shared-client/build/types/components/Select/Select"
import constants from "../../../../../../common/constants"

export const ANYTHING_BUT_NUMBERS_REGEX = /\D/g
export const REMOVE_INVALID_CHARS_REGEX = /[^0-9 ()-]/g
export const US_PHONE_NUMBER_REGEX = /(\d{3})(\d{3})(\d{4})/
export const FORMATTED_US_NUMBER_REGEX = /\((\d{3})\) (\d{3})-(\d{4})/
export const DEFAULT_TITLE = "Edit Bracket Name"
export const HELPER_MESSAGE = `Must be at least ${constants.ENTRY_NAME_MIN_LENGTH} characters`

export const NISSAN_VEHICLE_OPTIONS: ISelectOption[] = [
  { label: "Choose a vehicle", value: "" },
  { label: "Altima", value: "ALTIMA" },
  { label: "ARIYA", value: "ARIYA" },
  { label: "Armada", value: "ARMADA" },
  { label: "Frontier", value: "FRONTIER" },
  { label: "GT-R", value: "GT-R" },
  { label: "Kicks", value: "KICKS" },
  { label: "Leaf", value: "LEAF" },
  { label: "Maxima", value: "MAXIMA" },
  { label: "Pathfinder", value: "PATHFINDER" },
  { label: "Rogue", value: "ROGUE" },
  { label: "Sentra", value: "SENTRA" },
  { label: "Titan / Titan XD", value: "TITAN/TITAN XD" },
  { label: "Versa", value: "VERSA" },
  { label: "Z", value: "Z" },
]

export const NISSAN_OPT_IN_CONFIG = {
  CHECK_LABEL: `Yes, I would like CBS to provide my personal information to NISSAN so I can receive future product news and announcements by email from NISSAN. For more information on Nissan’s privacy practices, visit their <a href="https://www.nissanusa.com/privacy.html" rel="nofollow noopener noreferrer" target="_blank" style="text-decoration: underline">Privacy Policy<a>.`,
  NISSAN_RULES_DISCLAIMER: `*By providing my phone number, I expressly consent that CBSI can provide Nissan North America, Inc., my information and agree to receive recurring text messages and/or marketing calls from Nissan North America, Inc. I do not have to agree to receive such calls or messages as a condition of entering the Challenge. CBS Interactive, Inc. assumes no responsibility for use of such information by Nissan North America, Inc. Reply HELP for help and STOP to cancel. Msg and data rates may apply. Msg frequency varies. For more information on how Nissan processes your information, and advises you to please review the <a href="https://www.nissanusa.com/privacy.html" target="_blank">Nissan Legal Privacy Policy | Nissan USA</a>.`,
}
