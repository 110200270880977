import React, { useEffect, useMemo } from "react"
import constants from "../../../../common/constants"
import { getInvitationUrl } from "../../../utils/url-utils"
import { useQuery } from "@apollo/client"
import { UNREGISTERED_MEMBERS_COUNT } from "../../queries"
import { UnregisteredMembersCount, UnregisteredMembersCountVariables } from "../../../../__generated__/UnregisteredMembersCount"
import { PoolDataContextType } from "../../../Contexts/PoolDataContext"
import { PoolSettingsTypesEnum } from "../../../../common/enums"
import { useLocation } from "react-router-dom"

const NeuronAd = ({ custId, deviceType, poolData }: { custId: number; deviceType: string; poolData: PoolDataContextType }) => {
  const location = useLocation()
  const { poolDetail, hasManagerRole, myEntriesForArea, poolId, entryId } = poolData || {}
  const { poolSettings, membersCount, entriesCount, name } = poolDetail || {}
  const openInvites = poolSettings?.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && poolSettings.openInvites
  const maxEntriesPerUser = poolSettings?.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS && poolSettings.maxEntriesPerUser

  const { currentEntriesForArea: myEntries } = myEntriesForArea?.() || {}
  const myEntriesForPool = myEntries?.filter((entry) => entry.pool?.id === poolId)?.length ?? 0
  const poolInviteUrl = getInvitationUrl({ medium: "neuron", pool: poolDetail, entryId: entryId, hasManagerRole: true })
  const isChallengePool = /challenge/.test(poolDetail?.gameInstanceUid ?? "")

  const { data } = useQuery<UnregisteredMembersCount, UnregisteredMembersCountVariables>(UNREGISTERED_MEMBERS_COUNT, {
    variables: { poolId: poolId || "" },
    skip: !poolId || isChallengePool,
    fetchPolicy: "cache-first",
  })

  const neuronData = useMemo(
    () => ({
      hasManagerRole,
      myEntriesForPool,
      unregisteredMembersCount: data?.pool?.unregisteredMembersCount || 0,
      membersCount,
      entriesCount,
      maxEntriesPerUser,
      openInvites,
      poolInviteUrl,
      poolName: name,
    }),
    [
      hasManagerRole,
      myEntriesForPool,
      data?.pool?.unregisteredMembersCount,
      membersCount,
      entriesCount,
      maxEntriesPerUser,
      openInvites,
      poolInviteUrl,
      name,
    ],
  )
  useEffect(() => {
    let script: HTMLScriptElement | null = null
    script = document.createElement("script")
    script.src = `https://neuron${constants.APP_ENV === "dev" ? "-dev" : ""}.cbssports.cloud/client.js?platform=brackets-standings`
    script.async = true
    document.body.appendChild(script)

    return () => {
      if (script) {
        document.body.removeChild(script)
      }
    }
  }, [location.pathname])
  return (
    <div
      id="neuron-user-id"
      data-user-id={custId || 0}
      data-neuron-data={JSON.stringify(neuronData)}
      style={{ minHeight: deviceType === "handheld" ? "100px" : "200px", position: "relative", marginBottom: "2rem" }}
    />
  )
}

export default React.memo(NeuronAd)
