import React, { useState } from "react"
import Button from "@cbs-sports/sports-shared-client/build/cjs/components/Button"
import ListItemIcon from "@cbs-sports/sports-shared-client/build/cjs/components/ListItemIcon"
import { Bell } from "@cbs-sports/sports-shared-client/build/cjs/components/icons"
import EmailModal from "../../MultipleEntriesArea/MultipleEntriesPoolInviteMailCenter"
import { toast } from "@cbs-sports/sports-shared-client/build/cjs/components/Toast"
import ClassicTooltip from "@cbs-sports/sports-shared-client/build/cjs/components/ClassicTooltip"
import { TPoolRouteProps } from "../../../../../../routes.d"
import Analytics from "../../../../../utils/analytics"
const DEFAULT_SUBJECT = "Message From Your Bracket Pool Manager"
const DEFAULT_MESSAGE =
  "I noticed you haven't made picks yet or have an incomplete bracket in my pool, POOL_NAME. There's still time to make your picks before the tournament starts."
const TOOLTIP_DESCRIPTION = "Send reminder to everyone in pool that hasn't made picks or has an incomplete bracket."

type PicksReminderModalProps = {
  onEmailSent: () => void
  isOpen: boolean
  onClose: () => void
} & TPoolRouteProps

const PicksReminderModal = ({ onEmailSent, ...rest }: PicksReminderModalProps) => {
  const { poolData } = rest || {}
  const { poolDetail } = poolData || {}
  const poolName = poolDetail?.name || ""

  return (
    <EmailModal
      {...rest}
      defaultSubject={DEFAULT_SUBJECT}
      defaultMessage={DEFAULT_MESSAGE.replace("POOL_NAME", poolName)}
      onNotificationSent={onEmailSent}
      variant={"picks-reminder-notification"}
    />
  )
}

type PicksReminderButtonProps = TPoolRouteProps & {
  variant?: "manage-players" | "pool-home"
  isMobile?: boolean
}

const PicksReminderButton = ({ variant, isMobile, ...props }: PicksReminderButtonProps) => {
  const [showEmailModal, setShowEmailModal] = useState(false)
  const showModal = () => setShowEmailModal(true)
  const hideModal = () => setShowEmailModal(false)
  const onEmailSent = () => toast.snackbar("Reminder sent")

  return (
    <>
      <ClassicTooltip content={isMobile ? "" : TOOLTIP_DESCRIPTION}>
        <Button
          variant="secondary"
          onClick={() => {
            const trackingModuleName = variant === "manage-players" ? "manage players" : "brackets standings"
            Analytics.trackAction(trackingModuleName, "send picks reminder icon", "send picks reminder", "click")
            showModal()
          }}
          className="email-pool-cta"
          size={isMobile ? "sm" : "md"}
        >
          <ListItemIcon alignment={variant === "manage-players" ? "right" : "left"}>
            <Bell fontSize="14px" style={{ marginTop: "6px", marginRight: "4px" }} />
          </ListItemIcon>
          Send Picks Reminder
        </Button>
      </ClassicTooltip>
      <PicksReminderModal {...props} isOpen={showEmailModal} onClose={hideModal} onEmailSent={onEmailSent} />
    </>
  )
}

export default PicksReminderButton
