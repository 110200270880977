import { useMutation } from "@apollo/client"
import { UPSERT_ENTRY_MUTATION, ENTRY_DETAILS_QUERY, ENTRY_DETAILS_FRAGMENT } from "../App/queries"
import { UpsertEntryMutation, UpsertEntryMutationVariables } from "../../__generated__/UpsertEntryMutation"
import { managerModeEnabledCookieName, managerModeHeaderName, platformHeaderName, applicationHeaderName } from "../../common/constants"
import { get as getCookie } from "es-cookie"
import { canUseDom } from "../utils/misc-utils"
import { useDeviceType } from "../Base/DeviceType"

function useUpsertEntryMutation(skipPoolData = false) {
  let isManagerModeActive = false
  const deviceType = useDeviceType()
  const isMobile = deviceType === "handheld"
  if (canUseDom) {
    const isManagerModeActiveCookie = getCookie(managerModeEnabledCookieName)
    isManagerModeActive = isManagerModeActiveCookie === "true"
  }
  const platform = isMobile ? "mweb" : "desktop"
  const application = isMobile ? "picksSportsAppWebview" : "picks"
  const [upsertEntryMutation, upsertEntryMutationResult] = useMutation<UpsertEntryMutation, UpsertEntryMutationVariables>(UPSERT_ENTRY_MUTATION, {
    update(cache, other) {
      // If anon entry, we want to make sure we prevent the extra lookup + flash while looking up
      const resp = (other.data && other.data.upsertEntry) || undefined
      if (resp) {
        const entry = resp.entry
        const picksCount = entry.picks.length
        const periodId = picksCount && entry.picks[0].periodId
        if (!skipPoolData) {
          const entryDetailsVariables = {
            entryId: entry.id,
            periodId,
          }
          // if this is the first submit, we want to NOT load the detailed entry, and use the response instead!:
          cache.writeQuery({
            query: ENTRY_DETAILS_QUERY,
            data: { entry },
            variables: entryDetailsVariables,
          })
          // update central current users to reflect added entry + values:
          // const newCurrentUserFragment = resp.currentUser
          cache.writeFragment({
            id: `Entry:${entry.id}`,
            fragmentName: "EntryDetailsFragment",
            fragment: ENTRY_DETAILS_FRAGMENT,
            data: entry,
            variables: {
              skipPoolData,
            },
          })
        }
      }
    },
    refetchQueries: skipPoolData
      ? undefined
      : ["CurrentUserPendingInvites", "PoolSeasonStandingsQuery", "CentralCurrentUsersEntriesQuery", "CurrentMembershipQuery"],
    context: {
      headers: {
        [managerModeHeaderName]: isManagerModeActive,
        [platformHeaderName]: platform,
        [applicationHeaderName]: application,
      },
    },
  })

  return {
    upsertEntryMutation,
    upsertEntryMutationResult,
  }
}

export default useUpsertEntryMutation
