import {
  IBracketUtilMatchup,
  IFeRow,
  IPickUtilsEvent,
  IPickUtilsPick,
  IPickUtilsPicks,
  TBracketSection,
  TBracketTeamPosition,
  TEventSide,
} from "./common-utils-types.d"
import {
  NCAAB_GAME_INSTANCE_UID,
  NCAAW_GAME_INSTANCE_UID,
  NCAAW_MANAGER_GAME_INSTANCE_UID,
  NCAAB_S16_GAME_INSTANCE_UID,
} from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"

import { dateToEpoch, emptyVariable, filterNulls } from "./misc-utils"
import { ENUM_CANCELLED, ENUM_POSTPONED } from "./enums"
import { DbRow } from "./db-typings"
import constants from "./constants"
import { canUseDom, getVGUID } from "../client/utils/misc-utils"

export const BracketSections = ["left", "mid", "right"] as TBracketSection[]
export const BracketTeamPositions = ["top", "bottom"] as TBracketTeamPosition[]
export const invalidFinalGameStatuses = [ENUM_CANCELLED, ENUM_POSTPONED]
export const joiner = "|"
export const pickToCacheKey = (pick: IPickUtilsPick) =>
  [pick.slotId, pick.itemId || emptyVariable, pick.multiplier || emptyVariable, pick.spreadForItem || emptyVariable].filter(filterNulls).join(joiner)
export const pickAccum = (cum: string, pick: IPickUtilsPick) => cum + joiner + pickToCacheKey(pick)
export const eventToStartsAt = (e: IPickUtilsEvent) => dateToEpoch(e.startsAt)
export const serializePicks = (picks: IPickUtilsPicks) => JSON.stringify(picks)
export const deserializePicks = (picksString: string): IPickUtilsPicks => JSON.parse(picksString)

export const poorIsSame = (item: any, itemOther: any) => JSON.stringify(item) === JSON.stringify(itemOther)
export function cacheKeyForMatchup({ event, winnerId, topItemId, bottomItemId }: IBracketUtilMatchup) {
  return [winnerId, topItemId, bottomItemId].join(joiner) + joiner + ((event && cacheKeyFor(event)) || joiner)
}
export function cacheKeyFor({ gameStatusDesc, winningTeamId, timeRemaining, gamePeriod, startsAt, homeTeamScore, awayTeamScore }: IPickUtilsEvent) {
  // NOTE qac: we want break cache when startsAt passes!
  const startsAtIsPassed = (startsAt || 0) < Date.now()
  // const {gameStatusDesc, winningTeamId, timeRemaining, gamePeriod, homeTeamScore, awayTeamScore } = event;
  return [gameStatusDesc, winningTeamId, timeRemaining, gamePeriod, homeTeamScore, awayTeamScore, startsAtIsPassed].join(joiner)
}
export const filterNullItemIds = ({ itemId }: IPickUtilsPick) => !!itemId
export const mapToSlotId = ({ slotId }: IPickUtilsPick) => slotId

export const homeValue = "home" as TEventSide
export const awayValue = "away" as TEventSide

export function findById(objs: IFeRow[], id: string) {
  return objs.find((obj) => obj.id === id)
}

export function mapToOrd(matchup: IBracketUtilMatchup) {
  return matchup.roundOrdinal
}
export function getKeyFor(arg1: string | number, arg2: string | number) {
  return `${arg1}-${arg2}`
}
/**
 * Calculates the new doubling timeout interval. Useful when implementing a retry mechanism and instead of retrying after waiting for
 * a fixed amount of time, we want to increase the waiting time between reties after each retry failure
 * @param currentWait current waiting interval
 * @param limit maximum interval we want to have. 0 means no limits
 */
export function exponentialTimeout(currentWait: number, limit = 0) {
  return limit <= 0 ? currentWait * 2 : Math.min(limit, currentWait * 2)
}

type TSortDir = "ASC" | "DESC"
export const orderDbRowsBy = <T = DbRow>(dbRows: T[], columnName = "id", sortDir: TSortDir = "ASC") => {
  return dbRows.sort((a, b) => (sortDir === "ASC" ? a[columnName] - b[columnName] : b[columnName] - a[columnName]))
}

export const isNissanMarchMadnessMatcher = /nissan-(ncaab|ncaaw)-tournament/
export const isCBSMarchMadnessMatcher = /cbs-(ncaab|ncaaw)-tournament/
export const isCBSMarchMadnessManagerMatcher = /cbs-(ncaab|ncaaw)-tournament-manager/
export const isMarchMadnessChallengeMatcher = /(cbs|nissan)-(ncaab|ncaaw)-tournament-challenge/
export const isOPMMatcher = /cbs-(football|nfl)-(pickem|survivor)-manager/
export const isOPMSurvivorMatcher = /cbs-(football|nfl)-survivor-manager/
export const isMarchMadnessMatcher = /(cbs|nissan)-(ncaab|ncaaw)-tournament/
export const isNCAABTournamentMatcher = /(cbs|nissan)-ncaab-tournament/
export const isNCAAWTournamentMatcher = /(cbs|nissan)-ncaaw-tournament/
export const isNCAABSweet16TournamentMatcher = /sweet-sixteen-challenge/
export const isMarchMadnessProductAbbrevMatcher = /^(wln|ss)?w?bp(c|m)$/ // pulling out w because it should be the prefix for bpc and bpm products

export const mobileUaRegexp = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
export const tabletUaRegexp = /iPad|Tablet|Xoom|Kindle/i
export const isHandheldUaRegexp = /Mobile/i

export const impressionCacheBusterRegex = /\[timestamp\]|\[InsertCachebusterHere\]/g

export const getVisualOptionsGameInstanceUID = (gameInstanceUid: string) => {
  switch (gameInstanceUid) {
    case NCAAW_GAME_INSTANCE_UID:
    case NCAAW_MANAGER_GAME_INSTANCE_UID:
    case constants.NISSAN_NCAAW_CHALLENGE_GAME_INSTANCE_UID:
      return NCAAW_GAME_INSTANCE_UID
    case NCAAB_S16_GAME_INSTANCE_UID:
      return NCAAB_S16_GAME_INSTANCE_UID
    default:
      return NCAAB_GAME_INSTANCE_UID
  }
}

export const getDeviceTypeFromUserAgent = (userAgent: string) => {
  let deviceType = "desktop"
  const isMobile = mobileUaRegexp.test(userAgent)
  const isHandheld = isHandheldUaRegexp.test(userAgent)
  const isTablet = tabletUaRegexp.test(userAgent) || (isMobile && !isHandheld)
  if (isTablet) {
    deviceType = "tablet"
  } else if (isMobile) {
    deviceType = "handheld"
  }
  return deviceType
}

export const isConferenceBracketMatcher = /cbs-ncaab-confbracket/

export const replaceWithVguid = (impressionUrl: string) => {
  const vguidRegex = /\[vguid\]/g
  if (canUseDom && vguidRegex.test(impressionUrl)) {
    // Should never have to call getVGUID, but just being safe
    const vguid = window.surround?.vguid ?? getVGUID()
    return impressionUrl.replace(vguidRegex, vguid)
  }
  return impressionUrl
}
