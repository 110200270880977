import invariant from "invariant"
import {
  GamesPerPeriodEnumType,
  GameWeightEnumType,
  PeriodLengthEnumType,
  PicksDeadlineEnumType,
  RoundBonusEnumType,
  TiebreakerEnumType,
} from "../__generated__/globalTypes"
import {
  awayValue,
  cacheKeyFor,
  eventToStartsAt,
  filterNullItemIds,
  findById,
  getKeyFor,
  homeValue,
  joiner,
  mapToSlotId,
  pickAccum,
  poorIsSame,
  invalidFinalGameStatuses,
} from "./common-utils-helpers"
import {
  IBracketUtilMatchup,
  IFeRow,
  IPickUtilsEvent,
  IPickUtilsEventSpreadChange,
  IPickUtilsPeriod,
  IPickUtilsPick,
  IPickUtilsPicks,
  IPickUtilsTeam,
  TEventSide,
  TTiebreakerAnswers,
  IPickUtilsModes,
  IPickUtilsPickAgnosticMappings,
  IPickUtilsPickMappings,
  TStringMapping,
  TTiebreakerFieldInput,
  ITiebreakerAnswer,
  IRoundBonusesByRound,
  IRoundModifiersByRound,
  IPickUtilsTiebreakerQuestion,
} from "./common-utils-types.d"
import { TGameType, TSportType } from "./db-typings"
import {
  ENUM_BRACKET,
  ENUM_PARLAY,
  ENUM_UNAVAILABLE,
  GameWeightTypeEnum,
  PicksDeadlineTypeEnum,
  RoundBonusTypeEnum,
  TiebreakerQuestionKeyEnum,
  TiebreakerTypeEnum,
  RoundModifierTypeEnum,
  ENUM_UNDERDOG,
  ENUM_TIE,
  ENUM_FAVORED,
  ENUM_SCHEDULED,
  ENUM_FINAL,
  PoolSettingsTypesEnum,
  SpreadEnumType,
} from "./enums"
import {
  deepDup,
  emptyArray,
  emptyObject,
  filterNulls,
  formatSpread,
  getSpreadValue,
  isNumber,
  mapToId,
  oneDay,
  oneHour,
  onlyUnique,
  toRank,
  tryToCastToInteger,
  uniqueNonNull,
  usesSpread,
} from "./misc-utils"
import {
  IFullPoolSettings,
  TiebreakerTypesForCustomOnlyQuestions,
  TiebreakerTypesWithQuestions,
  EventSides,
  TiebreakerTypesForSecondEventCustomQuestions,
  PoolSettingsCheckType,
  AvailabilityOption,
} from "./pool-settings"
import { newEventOfThePeriodSorter, sortBySlotId, sortByStartsAtInt } from "./sorters"
import BracketUtils from "./bracket-utils"

import { PickUtils as PickUtilsBase } from "@cbs-sports/sports-shared-client/build/cjs/utils/pick-utils"
import { IPartialBracketVisualOptions } from "@cbs-sports/sports-shared-client/build/types/utils/common-utils-types"
import { getVisualOptions } from "@cbs-sports/sports-shared-client/build/cjs/utils/VisualOptions"
import { NcaaTournament, NcaawTournament } from "./tournament-groups"

// https://docs.google.com/document/d/1i637Fzp3Fqr831yJUgozyU9k-6UdKef4KfYi92UYap4/edit
const parlayScoringMapping = [
  0, // 0 picks
  0, // 1 pick
  3, // 2 picks
  6, // ...
  12,
  24,
  48,
  75,
  150,
  300,
  600,
  1000,
  2000,
  4000,
  8000,
  16000,
  32000,
]

// 2 pick parlay = 3pts
// 3 pick parlay = 6pts
// 4 pick parlay = 12 pts
// 5 pick parlay = 24 pts
// 6 pick parlay = 48 pts
// 7 pick parlay = 75 pts
// 8 pick parlay = 150 pts
// 9 pick parlay = 300 pts
// 10 pick parlay = 600 pts
// 11 pick parlay = 1000 pts
// 12 pick parlay = 2000 pts
// 13 pick parlay = 4000 pts
// 14 pick parlay = 8000 pts
// 15 pick parlay = 16000 pts
// 16 pick parlay = 32000 pts
// 16 pick parlay + bonus questions = jackpot winner 1m

export const FakePickUtilsTeam = {
  nickName: " ",
  abbrev: "UNK",
  wins: 0,
  losses: 0,
  id: "0",
  location: "",
  conferenceAbbrev: "",
  sportType: "NFL",
  colorHexDex: "#0000",
} as IPickUtilsTeam

class PickUtils extends PickUtilsBase implements IFullPoolSettings {
  public static buildFor(
    picks: IPickUtilsPicks,
    events: IPickUtilsEvent[],
    period: IPickUtilsPeriod,
    matchups: IBracketUtilMatchup[],
    teams: IPickUtilsTeam[],
    poolSettings: PoolSettingsCheckType,
    modeOverrides?: Partial<IPickUtilsModes>,
    previousPickUtils?: PickUtils | null,
    gameInstanceUid?: string,
  ) {
    if (
      previousPickUtils &&
      previousPickUtils.period.id === period.id &&
      poorIsSame(poolSettings, previousPickUtils.poolSettings) &&
      !(modeOverrides && !poorIsSame(Object.assign({}, previousPickUtils.modes, modeOverrides), previousPickUtils.modes))
    ) {
      // NOTE qac: since we have to populate events each time, lets instead reuse the last runs population... and apply patches (see buildMapping)
      const eventsWithBracketOptimization = (!events.length && previousPickUtils.events) || events
      previousPickUtils.update(picks, eventsWithBracketOptimization, period, matchups, teams, poolSettings, modeOverrides)
      return previousPickUtils
    }
    const initialVisualOptions = getVisualOptions(gameInstanceUid || "")
    return new PickUtils(picks, events, period, matchups, teams, poolSettings, initialVisualOptions, modeOverrides)
  }

  // ===== externally assigned props
  public needsSave = false
  public logger: any
  public cacheValue = ""

  // ===== Internally assigned props
  public modes = {
    overriding: false,
    inWeightingMode: false,
    extraCacheKey: "",
  } as IPickUtilsModes
  public picks: IPickUtilsPicks = []
  public bracketUtils!: BracketUtils
  public events: IPickUtilsEvent[] = []
  public matchups: IBracketUtilMatchup[] = []
  public teams: IPickUtilsTeam[] = []
  public picksDeadlineType: PicksDeadlineEnumType = "BEFORE_START_OF_EACH_GAME"
  public periodLength: PeriodLengthEnumType = "DAILY"
  public spreadType: SpreadEnumType = ENUM_UNAVAILABLE
  public multipleEntriesOption: AvailabilityOption = ENUM_UNAVAILABLE
  public maxEntriesPerUser = 1
  public mainTiebreaker: TiebreakerEnumType = ENUM_UNAVAILABLE
  public secondaryTiebreaker: TiebreakerEnumType = ENUM_UNAVAILABLE
  public thirdTiebreaker: TiebreakerEnumType = ENUM_UNAVAILABLE
  public fourthTiebreaker: TiebreakerEnumType = ENUM_UNAVAILABLE
  public includeChampionshipRound: boolean | null = false
  public includeMessageBoard: boolean | null = false
  public openInvites: boolean | null = false
  public gamesPerPeriod: GamesPerPeriodEnumType = ENUM_UNAVAILABLE
  public pickCountRangeOption: AvailabilityOption = ENUM_UNAVAILABLE
  public maxPicksPerPeriodCount: number | null = null
  public minPicksPerPeriodCount = 1
  public gameWeightType: GameWeightEnumType = ENUM_UNAVAILABLE
  public roundBonusType: RoundBonusEnumType = ENUM_UNAVAILABLE
  public roundBonuses: number[] | null = null
  public roundModifiers: RoundModifierTypeEnum[] | null = null
  public roundModifiersOption: AvailabilityOption = ENUM_UNAVAILABLE
  public tournamentIds: number[] | null = null
  public changes = [] as IPickUtilsPicks
  public merged = [] as IPickUtilsPicks
  public filteredEvents = [] as IPickUtilsEvent[]
  public pickAgnosticMappings: IPickUtilsPickAgnosticMappings = {}
  public pickMappings: IPickUtilsPickMappings = {}
  public sportType: TSportType
  public gameType: TGameType
  // public seasonType: TSeasonType;
  public parlayScoringMapping = parlayScoringMapping
  public findById = findById
  public home = homeValue
  public away = awayValue
  public sortByStartsAtInt = sortByStartsAtInt
  public _originalEvents = [] as IPickUtilsEvent[]
  public _eventsPreWeightingMode = null as IPickUtilsEvent[] | null
  public _originalMatchups = [] as IBracketUtilMatchup[]

  public getKeyFor = getKeyFor
  public originalAfterOverrides = null as null | any
  public picksCacheKey = ""
  public period: IPickUtilsPeriod
  public poolSettings: PoolSettingsCheckType
  public roundBonusesByRound: IRoundBonusesByRound
  public roundModifiersByRound: IRoundModifiersByRound
  public __typename: PoolSettingsTypesEnum

  constructor(
    picks: IPickUtilsPicks,
    events: IPickUtilsEvent[],
    period: IPickUtilsPeriod,
    matchups: IBracketUtilMatchup[],
    teams: IPickUtilsTeam[],
    poolSettings: PoolSettingsCheckType,
    visualOptions: IPartialBracketVisualOptions,
    modeOverrides?: Partial<IPickUtilsModes>,
  ) {
    super(picks, events, period, matchups, teams, visualOptions)
    this.period = period
    this.bracketUtils = new BracketUtils(matchups)
    this.__typename = PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS

    this.poolSettings = poolSettings
    Object.assign(this, poolSettings)

    const roundBonuses = this.roundBonuses || emptyArray
    const roundModifiers = this.roundModifiers || emptyArray
    this.roundBonusesByRound = emptyObject
    this.roundModifiersByRound = emptyObject
    if (Boolean(matchups.length)) {
      const roundBonusesByRound: IRoundBonusesByRound = {}
      const roundModifiersByRound: IRoundModifiersByRound = {}
      // TODO: Yuri: 53 Women Tournament ID
      const isNcaabBracket = this.bracketUtils.tournamentIds.includes(NcaaTournament._id, NcaawTournament._id)
      const tournamentRounds = this.bracketUtils.getRoundsFor(matchups[0].tournamentId, false)
      tournamentRounds.forEach((tournamentRound, i) => {
        const index = isNcaabBracket ? tournamentRound - 2 : i
        roundModifiersByRound[tournamentRound] = roundModifiers[index]
        roundBonusesByRound[tournamentRound] = roundBonuses[index]
      })
      this.roundBonusesByRound = roundBonusesByRound
      this.roundModifiersByRound = roundModifiersByRound
    }

    // NOTE qac: both frontend and backend have differing values (all caps vs camelcase)
    this.gameType = period.segment.gameType
    this.sportType = period.segment.sportType
    this.update(picks, events, period, matchups, teams, poolSettings, modeOverrides)
  }

  public init() {
    return undefined
  }

  public update(
    picks: IPickUtilsPicks,
    events: IPickUtilsEvent[],
    period: IPickUtilsPeriod,
    matchups: IBracketUtilMatchup[],
    teams: IPickUtilsTeam[],
    _poolSettings: PoolSettingsCheckType,
    modeOverrides?: Partial<IPickUtilsModes>,
  ) {
    // this.log("update")
    this._originalEvents = events
    this._originalMatchups = matchups
    // reset overrides... this sucks
    this.originalAfterOverrides = null
    this._eventsPreWeightingMode = null
    // public assigns
    if (period.pickingDisabledEventIds?.length) {
      this.events = events
      this.filteredEvents = events.filter((e) => !!period.pickingDisabledEventIds?.includes(e.id))
      this.events = events.filter((e) => !period.pickingDisabledEventIds?.includes(e.id))
    } else {
      this.events = events
    }
    this.matchups = matchups
    this.teams = teams
    this.processNewMatchupsAndEvents(events, this.visualOptions)
    if (modeOverrides) {
      Object.assign(this.modes, modeOverrides)
    }
    this.updateOriginalPicks(picks, true)
  }

  public withOverrides(isOverriding: boolean, action: () => void) {
    if (this.modes.overriding !== isOverriding) {
      const orig = this.modes.overriding
      try {
        this.modes.overriding = isOverriding
        this.rebuildCaches()
        action()
      } finally {
        this.modes.overriding = orig
        this.rebuildCaches()
      }
    } else {
      action()
    }
  }

  public updateOriginalPicks(picks: IPickUtilsPicks, filterInvalidPicks = false, tournamentRound?: number) {
    // this.log(`updateOriginalPicks`);
    this.changes = [] as IPickUtilsPicks
    this.merged = [] as IPickUtilsPicks
    // NOTE qac: filterInvalidPicks flag currently is used for scoring
    // Safety of only inserting picks that fall under the pickable items
    // (due to change-able pool slates of games, entries could have previous picked games no longer available in the slate)
    this.picks = filterInvalidPicks || tournamentRound ? this.ensureCleanedPicks(picks, tournamentRound) : picks
    this.processModes()
    this.updatePicksCacheKey()
    this.buildMappings()
  }

  public rebuildCaches() {
    delete this.bracketMapping
    delete this.bracketPeriodTree
    this.pickAgnosticMappings = {}
    this.pickMappings = {}
    // set the matchups back to 67 before rebuild bracketPeriodTree
    if (this.bracketUtils?.allMatchups?.length) {
      this.matchups = this.bracketUtils.allMatchups
    }
    this.processNewMatchupsAndEvents(this.events, this.visualOptions)
    this.processModes()
    this.updatePicksCacheKey()
    this.buildMappings()
  }

  public cacheKeyFor(event: IPickUtilsEvent) {
    if (this.pickMappings.eventCacheKeyById) {
      return this.pickMappings.eventCacheKeyById[event.id]
    }
    let parts = [cacheKeyFor(event), this.isEventLocked(event.id).toString()] as Array<string | number>
    const pick = this.getPick(event.id)
    if (pick?.itemId) {
      parts.push(pick.itemId)
    }
    const addPts = this.getAdditionalPoints(event.id)
    if (addPts) {
      parts.push(addPts)
    }
    // NOTE qac: we need to update UI dropdowns with all available weights
    if (this.usesWeights()) {
      const takenWeights = this.getAvailableWeights()
      if (takenWeights) {
        parts = parts.concat(takenWeights)
      }
    }
    return parts.join(joiner)
  }

  public formatSpread = formatSpread

  public displayFormattedSpread = (spread: string | null) => {
    return spread === "0.0" ? "PK" : spread
  }

  public displayFormattedSpreadFor = (
    event: IPickUtilsEvent,
    isHomeTeam = true,
    useCurrentPicksLockedSpread = true,
    reconvert = false,
    adjustForTies = false,
    useOpeningSpread = false,
  ) => {
    const spread = this.getSpreadFor(event, isHomeTeam, useCurrentPicksLockedSpread, reconvert, adjustForTies, useOpeningSpread)
    return this.displayFormattedSpread(spread)
  }

  public overrides() {
    if (!this.isOverriding()) {
      this.originalAfterOverrides = {
        events: this.events,
        period: this.period,
      }
      const oneDayFromNow = Date.now() + 24 * oneHour
      this.events = deepDup(this.events)
      this.events.forEach((e) => {
        if (e.startsAt < oneDayFromNow) {
          e.startsAt = oneDayFromNow
        }
        e.winningTeamId = null
        e.gameStatusDesc = ENUM_SCHEDULED
      })
      this.period = deepDup(this.period)
      if (this.period.locksAt) {
        this.period.locksAt = oneDayFromNow
      }
      Object.assign(this.period, {
        isPickable: true,
        // needsToSetSpreads: false,
        // needsToSetEventOfThePeriod: false,
        // needsToSetEventGroups: false,
      })
    }
  }

  public resetFromOverrides() {
    if (this.isOverriding()) {
      this.events = this.originalAfterOverrides.events as any
      this.period = this.originalAfterOverrides.period as any
      this.originalAfterOverrides = null
    }
  }

  public processModes() {
    if (this.modes.overriding) {
      this.overrides()
    } else {
      this.resetFromOverrides()
    }
    if (this.modes.inWeightingMode) {
      if (!this._eventsPreWeightingMode) {
        this._eventsPreWeightingMode = this.events
      }
      this.events = this.events.filter((ev) => !!this.picks.find((pp) => pp.slotId === ev.id))
    } else if (this._eventsPreWeightingMode) {
      this.events = this._eventsPreWeightingMode
      this._eventsPreWeightingMode = null
    }
    return null
  }

  public isOverriding = () => !!this.originalAfterOverrides

  public snapshotPicks(otherPicks?: IPickUtilsPicks) {
    return (otherPicks || this.picks).concat([]).sort(sortBySlotId).reduce(pickAccum, "")
  }

  public getPicksCacheKeyFor(picks: IPickUtilsPicks) {
    return super.getPicksCacheKeyFor(picks) + joiner + this.modes.extraCacheKey + joiner + this.period.id
  }

  public getChampionTeamId(): string | null {
    if (this.isBracket()) {
      const rounds = this.bracketPeriodTree?.rounds
      if (rounds?.length) {
        const lastRound = rounds[rounds.length - 1]
        if (this.pickMappings.slotIdsByRound) {
          const finalSlots = this.pickMappings.slotIdsByRound[lastRound] || []
          for (const slotId of finalSlots) {
            const champ = this.getPick(slotId)
            if (champ) {
              return champ.itemId
            }
          }
        }
      }
    }
    return null
  }
  public tiebreakerQuestionValue(tiebreakerQuestionId: string) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const tiebreakerQuestion = this.getTiebreakerQuestions().find(({ id }) => id === tiebreakerQuestionId)!
    const event = this.getEventOfThePeriod()
    const secondevent = this.getSecondEventOfThePeriod()
    if (
      tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE_2 ||
      tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE_2
    ) {
      if (secondevent && secondevent.winningTeamId) {
        if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE_2) {
          return secondevent.awayTeamScore
        } else if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE_2) {
          return secondevent.homeTeamScore
        } else {
          throw new Error(`invalid tiebreakerQuestion.key: ${tiebreakerQuestion.key}`)
        }
      }
      return null
    } else if (event && event.winningTeamId) {
      if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.TOTAL_SCORE) {
        return event.homeTeamScore + event.awayTeamScore
      } else if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE) {
        return event.awayTeamScore
      } else if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE) {
        return event.homeTeamScore
      } else if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.TOURNAMENT_WINNER) {
        return event.winningTeamId === this.getTeam(event, this.home).id ? this.getTeam(event, this.home).id : this.getTeam(event, this.away).id
      } else if (tiebreakerQuestion.key === TiebreakerQuestionKeyEnum.TOTAL_OFFENSIVE_YARDS) {
        return event.extra?.totalOffensiveYds || -1
      } else {
        throw new Error(`invalid tiebreakerQuestion.key: ${tiebreakerQuestion.key}`)
      }
    } else {
      return null
    }
  }

  public isEliminatedFromBracket(itemId: string) {
    return this.matchups.find((m) => [m.topItemId, m.bottomItemId].includes(itemId) && !!m.winnerId && m.winnerId !== itemId)
  }

  public getMinRequiredPicks() {
    return this.minPicksPerPeriodCount
  }

  public hasMinRequiredPicks() {
    return this.picksMade() >= this.getMinRequiredPicks()
  }

  public requiredSetupErrorMessage() {
    if (this.pickAgnosticMappings.hasOwnProperty("requiredSetupErrorMessage")) {
      return this.pickAgnosticMappings.requiredSetupErrorMessage
    }
    const { needsEventGroupApproval, needsEventGroupCategoryApproval } = this.period
    if (needsEventGroupCategoryApproval) {
      return "CBS Games have not been approved"
    }
    if (needsEventGroupApproval) {
      return "Pool Custom Games have not been set and approved"
    }
    return null
  }

  public isTiebreakerEnabled() {
    return !this.requiredSetupErrorMessage() && (this.isParlay() ? this.hasMadeAllPicks() : this.isTiebreakerPeriod())
  }

  public isPrizeEligible(tiebreakerAnswers?: TTiebreakerAnswers) {
    return this.isTiebreakerEnabled() && this.allTiebreakersAnswered(tiebreakerAnswers)
  }

  public getVisibleTiebreakerAnswerValueFor({
    tiebreakerAnswers = undefined,
    withSecondEventOfThePeriod = false,
    isForSecondEvent = false,
  }: {
    tiebreakerAnswers?: TTiebreakerAnswers
    withSecondEventOfThePeriod?: boolean
    isForSecondEvent?: boolean
  }) {
    const answered = this.getTiebreakerAnswers(tiebreakerAnswers)
    const accepted = answered.map((a) => tryToCastToInteger(a?.value)).filter(isNumber)
    if (withSecondEventOfThePeriod) {
      const secondList = accepted.splice(2, 2)
      return isForSecondEvent ? secondList.join(" - ") : accepted.join(" - ")
    }
    return accepted.length ? accepted.join(" - ") : null
  }

  public getTiebreakerLabelFor(tiebreakerQuestionId: string) {
    const q = this.getTiebreakerQuestions().find(({ id }) => id === tiebreakerQuestionId)
    return (q && q.label) || "unknown"
  }

  public getTiebreakerLabelQuestionFor(question: IPickUtilsTiebreakerQuestion) {
    if (question) {
      const event = this.getEventOfThePeriod()
      const secondEvent = this.getSecondEventOfThePeriod()
      switch (question.key) {
        case TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE:
          if (event?.awayTeam?.abbrev) {
            return `Total Points for ${event.awayTeam.abbrev}`
          }
          return question.label
        case TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE:
          if (event?.homeTeam?.abbrev) {
            return `Total Points for ${event.homeTeam.abbrev}`
          }
          return question.label
        case TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE_2:
          if (secondEvent?.awayTeam?.abbrev) {
            return `Total Points for ${secondEvent.awayTeam.abbrev}`
          }
          return question.label
        case TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE_2:
          if (secondEvent?.homeTeam?.abbrev) {
            return `Total Points for ${secondEvent.homeTeam.abbrev}`
          }
          return question.label
        default:
          return question.label
      }
    }
    return "unknown"
  }
  public getTiebreakerFieldDisableFor(question: IPickUtilsTiebreakerQuestion) {
    if (question) {
      switch (question.key) {
        case TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE_2:
        case TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE_2:
          return this.secondTiebreakerIsLocked()
        case TiebreakerQuestionKeyEnum.AWAY_TEAM_SCORE:
        case TiebreakerQuestionKeyEnum.HOME_TEAM_SCORE:
        default:
          return this.tiebreakerIsLocked()
      }
    }
    return true
  }

  public getTiebreakerTypeForQuestionKey(tiebreakerQuestionKey: TiebreakerQuestionKeyEnum): TiebreakerTypeEnum {
    if (
      TiebreakerTypesForCustomOnlyQuestions.includes(tiebreakerQuestionKey) ||
      TiebreakerTypesForSecondEventCustomQuestions.includes(tiebreakerQuestionKey)
    ) {
      return TiebreakerTypeEnum.CUSTOM
    }
    return TiebreakerTypeEnum[tiebreakerQuestionKey]!
  }

  public getTiebreakerQuestions() {
    return (this.period.tiebreakerQuestions || emptyArray).filter(
      (tiebreakerQuestion) => !!this.getTiebreakerAttrFor(this.getTiebreakerTypeForQuestionKey(tiebreakerQuestion.key)),
    )
  }

  public getTiebreakerAnswers(tiebreakerAnswers?: TTiebreakerAnswers) {
    const qIds = this.getTiebreakerQuestions().map(mapToId)
    // Note LL: let's create a set to make sure we have only 1 instance of a tiebreaker answer
    // Note LL: lets make sure tiebreaker answers are always in the same order as the questions https://jira.cbsi.com/browse/SPSDF-3572
    return Array.from(new Set((tiebreakerAnswers || emptyArray).map((tba) => tba.tiebreakerQuestionId)))
      .map((tqId) => {
        const tieBreakerAnswer: ITiebreakerAnswer = {
          tiebreakerQuestionId: tqId,
          value: tiebreakerAnswers?.find((tba) => tqId === tba.tiebreakerQuestionId)?.value ?? "",
        }
        return tieBreakerAnswer
      })
      .sort((a, b) => qIds.indexOf(a.tiebreakerQuestionId) - qIds.indexOf(b.tiebreakerQuestionId))
      .filter((answer) => qIds.includes(answer.tiebreakerQuestionId))
    // (tiebreakerAnswers || emptyArray)
    //   .sort((a, b) => qIds.indexOf(a.tiebreakerQuestionId) - qIds.indexOf(b.tiebreakerQuestionId))
    //   .filter((answer) => qIds.includes(answer.tiebreakerQuestionId))
  }

  public allTiebreakersAnswered(tiebreakerAnswers?: TTiebreakerAnswers) {
    const qs = this.getTiebreakerQuestions()
    if (!qs.length) {
      return true
    }
    if (!tiebreakerAnswers) {
      return false
    }
    if (this.isBracket()) {
      const qsWithAnswersIds = tiebreakerAnswers.map((t) => {
        const { tiebreakerQuestionId, value } = t
        const question = qs.find((x) => x.id === tiebreakerQuestionId)
        if (question?.key === TiebreakerTypeEnum.TOTAL_SCORE) {
          if (+value >= 0) {
            return tiebreakerQuestionId
          }
        } else {
          return tiebreakerQuestionId
        }
        return undefined
      })
      return !qs.find(({ id }) => !qsWithAnswersIds.includes(id))
    } else {
      const qsWithAnswersIds = tiebreakerAnswers.map(({ tiebreakerQuestionId }) => tiebreakerQuestionId)
      return !qs.find(({ id }) => !qsWithAnswersIds.includes(id))
    }
  }

  public getAllEventsOfThePeriod() {
    return [this.getEventOfThePeriod(), this.getSecondEventOfThePeriod()].filter(filterNulls)
  }

  public getEventOfThePeriod() {
    // TODO qac: optimize
    if (!this.period.eventOfThePeriodId || !this.tiebreakerQuestionsAttr()) {
      return null
    }
    return this.getEventById(this.period.eventOfThePeriodId) || this.filteredEvents.find((e) => e.id === this.period.eventOfThePeriodId) || null
  }
  public getSecondEventOfThePeriod() {
    // TODO qac: optimize
    if (!this.period.secondEventOfThePeriodId || !this.tiebreakerQuestionsAttr()) {
      return null
    }
    return (
      this.getEventById(this.period.secondEventOfThePeriodId) ||
      this.filteredEvents.find((e) => e.id === this.period.secondEventOfThePeriodId) ||
      null
    )
  }

  public tiebreakerIsLocked() {
    if (!this.periodIsPickable()) {
      return true
    }
    return false
  }
  public secondTiebreakerIsLocked() {
    if (!this.periodIsPickable()) {
      return true
    }
    const seconedEventOfThePeriod = this.getSecondEventOfThePeriod()
    if (seconedEventOfThePeriod && seconedEventOfThePeriod.startsAt <= Date.now()) {
      return true
    }
    return false
  }

  public publicCanViewTiebreaker() {
    // if this is the tiebreaker period + there are not more pickable events!
    return this.isTiebreakerPeriod() && this.tiebreakerIsLocked()
  }

  public isPickingEnabledFor = (event: IPickUtilsEvent) => {
    return !this.period.pickingDisabledEventIds?.includes(event.id)
  }

  public isPickingDisabledFor(event: IPickUtilsEvent) {
    return this.period.pickingDisabledEventIds?.includes(event.id)
  }

  // public getTournamentWinnerOptionEvents() {
  //   const seedOptions = [1, 2, 3, 4];
  //   const options = [] as IPickUtilsEvent[];
  //   for (const event of this.events) {
  //     for (const side of eventSides) {
  //       const rank = event.extra[`${side}TeamRank`] || 0;
  //       if (seedOptions.includes(rank) && !this.isPickingDisabledFor(event)) {
  //         options.push(event);
  //       }
  //     }
  //   }
  //   return options;
  // }

  public getTiebreakerFields() {
    if (this.pickAgnosticMappings.tiebreakerFields) {
      return this.pickAgnosticMappings.tiebreakerFields
    }
    const questions = (this.isTiebreakerPeriod() && this.getTiebreakerQuestions()) || emptyArray //  && this.getEventOfThePeriod()
    return questions.map((q) => {
      // const options = undefined as ITournamentWinnerOption[] | undefined;
      const disabled = this.getTiebreakerFieldDisableFor(q)
      const input: TTiebreakerFieldInput = {
        type: "number",
        min: 0,
        max: this.isBracket() ? 999 : 999999, //NOTE RH: Per requirement, tiebreaker for bracker should not exceed 999
        disabled,
        options: undefined,
      }
      // NOTE qac: we ONLY use play in logic for tournament winner...
      // if (q.key === TiebreakerQuestionKeyEnum.TOURNAMENT_WINNER) {
      if (q.options) {
        input.type = "select"
        delete input.min
        delete input.max
        input.options = q.options.map((option) => ({
          label: option.label,
          value: option.value,
        }))
        // input.options.unshift({
        //   label: "",
        //   value: "",
        // })
        // for (const option of q.options) {
        //   options.push({
        //     label: option.label,
        //     value: option.value,
        //   })
        // }
        // const opts = this.getTournamentWinnerOptionEvents();
        // for (const event of opts) {
        //   for (const side of eventSides) {
        //     const team = this.getTeam(event, side);
        //     options.push({
        //       label: team.abbrev,
        //       value: team.id,
        //     });
        //   }
        // }
      }
      const newLabel = this.getTiebreakerLabelQuestionFor(q)
      return Object.assign({}, q, {
        input,
        label: newLabel,
      })
    })
  }

  public eventForMatchupId(matchupId: string) {
    const matchup = this.getMatchupById(matchupId)
    invariant(!!matchup, `no matchup`)
    return this.getEventById(matchup?.event?.id || matchupId) || this.buildFakeEventFor(matchup)
  }

  public _buildFakeEventFor = (matchup: IBracketUtilMatchup) => this.buildFakeEventFor(matchup)

  public fillUnpopulatedMatchupEvents() {
    if (this.isBracket()) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.events = this.matchups.map(this._buildFakeEventFor) as any[]
    }
  }

  public getItemById(itemId: string) {
    if (this.pickAgnosticMappings.teamById && itemId && this.pickAgnosticMappings.teamById[itemId]) {
      return this.pickAgnosticMappings.teamById[itemId]
    }
    return this.teams.find((t) => t.id === itemId)
  }

  public getTeam(event: IPickUtilsEvent, side: TEventSide, fromMatchUpId?: string): IPickUtilsTeam {
    if (event[`${side}Team`]) {
      return event[`${side}Team`] as IPickUtilsTeam
    }
    const teamId = event[`${side}TeamId`] as string | null
    if (this.pickAgnosticMappings.teamById && teamId && this.pickAgnosticMappings.teamById[teamId]) {
      return this.pickAgnosticMappings.teamById[teamId]
    }
    if (!teamId && fromMatchUpId) {
      const matchSide = side === "away" ? "bottom" : "top"
      const key = `${fromMatchUpId}-${matchSide}`
      if (this.bracketMapping?.picksSlotAndPosItemId && this.bracketMapping?.picksSlotAndPosItemId[key]) {
        const newId = this.bracketMapping?.picksSlotAndPosItemId[key]
        return (
          (newId && this.teams.find(({ id }) => id === newId)) ||
          // NOTE qac: this method intentionally hopes we have a "filled" event, but psuedo gaurds
          // against not (empty object to prevent access errors)
          FakePickUtilsTeam
        )
      }
      // const newId = event[`${side}TeamId`] as string | null
      // if (this.pickAgnosticMappings.teamById && teamId && this.pickAgnosticMappings.teamById[teamId]) {
      //   return this.pickAgnosticMappings.teamById[teamId]
      // }
    }
    return (
      (teamId && this.teams.find(({ id }) => id === teamId)) ||
      // NOTE qac: this method intentionally hopes we have a "filled" event, but psuedo gaurds
      // against not (empty object to prevent access errors)
      FakePickUtilsTeam
    )
  }
  public hasRanks(event: IPickUtilsEvent) {
    return event.extra?.hasOwnProperty("homeTeamRank") || event.extra?.hasOwnProperty("awayTeamRank")
  }

  public patchMatchupEvents() {
    if (this.isBracket()) {
      // this.log(`patchMatchupEvents...`);
      for (const m of this.matchups) {
        const eventId = m.event && m.event.id
        const event = eventId && this.getEventById(eventId)
        if (event) {
          Object.assign(event, m.event)
          // this.log(`patching ${eventId}`)
          // console.dir(event)
          // console.dir(m.event)
        }
      }
    }
  }

  public periodIsPickable(bypass = false) {
    const isBracketUnlocked = super.periodIsPickable(bypass)
    if (!isBracketUnlocked) {
      return false
    }
    if (this.isBracket() && this.modes.overriding) {
      return true
    }
    const nowAt = Date.now()
    if (this.picksDeadlineType === PicksDeadlineTypeEnum.BEFORE_START_OF_EACH_TOURNAMENT && this.period.locksAt) {
      return this.period.locksAt > nowAt
    }
    const isPickable =
      this.period.isPickable &&
      !(
        this.picksDeadlineType === PicksDeadlineTypeEnum.BEFORE_START_OF_PERIODS_FIRST_GAME &&
        Math.min(Infinity, ...this.events.map(eventToStartsAt)) <= nowAt
      )
    return isPickable
  }

  public isBracket() {
    return this.gameType === ENUM_BRACKET
  }

  public isParlay() {
    return this.gameType === ENUM_PARLAY
  }

  public getMergedPicks(changes: IPickUtilsPicks) {
    const merged = deepDup(this.picks)
    for (const change of changes) {
      const existing = merged.find((d) => d.slotId === change.slotId)
      if (existing) {
        Object.assign(existing, change)
      } else {
        merged.push(change)
      }
    }
    this.merged = merged.filter(filterNullItemIds)
    return this.merged
  }
  public validate(changes: IPickUtilsPicks, validateMinPicks = false) {
    // update this to be brackets only?
    const isBracket = this.isBracket()
    const merges = this.getMergedPicks(changes) as IPickUtilsPicks
    const pickedSlotIds = merges.map(mapToSlotId)
    const prevPickedSlotIds = this.picks.filter(filterNullItemIds).map(mapToSlotId)
    const newPickedSlotIdsWithItems = merges.filter(filterNullItemIds).map(mapToSlotId).filter(onlyUnique)
    const newPickedSlotIdsWithItemsCount = newPickedSlotIdsWithItems.length
    // validate min picks: ONLY if its not clearing picks (which is allowed!)
    if (validateMinPicks && newPickedSlotIdsWithItemsCount > 0 && newPickedSlotIdsWithItemsCount < this.getMinRequiredPicks()) {
      throw new Error(`Minimum required picks: ${this.getMinRequiredPicks()}`)
    }
    // NOTE qac: first spot, we want to skip this if its a "fix" (first part of query)
    if (pickedSlotIds.length >= prevPickedSlotIds.length && newPickedSlotIdsWithItemsCount > this.getMaxPicksAllowed()) {
      throw new Error(`Max allowed picks: ${this.getMaxPicksAllowed()}`)
    }
    if (pickedSlotIds.length !== pickedSlotIds.filter(onlyUnique).length) {
      throw new Error(`Duplicate slot Ids: ${JSON.stringify(pickedSlotIds)}`)
    }
    const pickableSlotIds = isBracket ? this.matchups.map(mapToId) : this.events.map(mapToId)
    const invalidSlotId = pickableSlotIds.find((slotId) => !pickableSlotIds.includes(slotId))
    if (!!invalidSlotId) {
      throw new Error(`Invalid slotId: '${invalidSlotId}' (${pickableSlotIds.join(", ")})`)
    }
    // individual starts at
    // removing this since we are calling the bracket state before we get into this function, which is what we should solely be relying on for is brackets are locked or not
    // if (isBracket) {
    //   const lockedMatchup = changes
    //     .map((pick) => this.matchups.find(({ id }) => id === pick.slotId))
    //     .find((m) => !!m && this.isBracketLocked(m.tournamentId))
    //   if (lockedMatchup) {
    //     throw new Error(`${lockedMatchup.tournamentDescription} has started and is locked to changes.`)
    //   }
    // }
    // individual itemIds
    if (isBracket) {
      for (const change of changes) {
        if (!this.isValidBracketPropogatingPick(change, changes)) {
          throw new Error(`Invalid pick: ${JSON.stringify(change)}.`)
        }
      }
      const invalidChange = changes.find(({ itemId, slotId }) => {
        const matchup = this.matchups.find(({ id }) => id === slotId)
        // this.log(`1 checking ${slotId}, ${itemId}...`)
        if (!matchup) {
          return true
        }
        // we set itemIds to be undefined for changes, so this is ok:
        if (!itemId) {
          return false
        }
        // this.log(`2 checking ${slotId}, ${itemId} (${[matchup.topItemId, matchup.bottomItemId].join(', ')})...`)
        // is seeded team
        if ([matchup.topItemId, matchup.bottomItemId].includes(itemId)) {
          return false
        }
        if (this.bracketMapping?.itemIdPlayinItemNameOverrides && this.bracketMapping.itemIdPlayinItemNameOverrides[itemId]) {
          if (matchup.tournamentRound === this.bracketPeriodTree?.rounds?.[0]) {
            return false
          }
        }
        // is propogated team
        // this.log(`3checking ${slotId}, ${itemId}...`)
        const playsInto = this.bracketUtils.allPlaysIntoFor(matchup)
        // this.log(playsInto)
        // this.log(merges.find((pk) => pk.itemId === itemId ))
        const picksPlayingIntoWithItem = playsInto.map(({ id }) => merges.find((pk) => pk.slotId === id && pk.itemId === itemId)).filter(filterNulls)
        // this.log(`4 checking ${slotId}, ${itemId} (${JSON.stringify(picksPlayingIntoWithItem)}, ${JSON.stringify(merges)})...`)
        // bad pick if there is no playing into
        return picksPlayingIntoWithItem.length === 0
      })
      if (invalidChange) {
        throw new Error(`Invalid itemId for slotId: ${JSON.stringify(invalidChange)}.`)
      }
    }
  }

  public getLatestSpreadValueForSlotItem(slotId: string, itemId: string) {
    invariant(!this.isBracket(), `needs implemented`)
    const event = this.getEventById(slotId)
    invariant(!!event, `missing event`)
    const isHomeTeam = event.homeTeam?.id === itemId
    const formattedCurrentSpreadForItem = this.getSpreadFor(event, isHomeTeam, false)
    if (formattedCurrentSpreadForItem === null) {
      return null
    }
    return getSpreadValue(formattedCurrentSpreadForItem)
  }

  public canBeUnpicked(eventId: string) {
    const event = this.getEventById(eventId)
    // if you don't have a pick you can't unpick.
    if (this.getPick(eventId)) {
      // NOTE qac: if this game is invalid (cancelled | postponed), still allow someone to unselect their pick IF they have one for this slot:
      return !!(event && !event.winningTeamId && event.startsAt > Date.now())
    }
    return false
  }

  public getDiffFrom(persistedPicks: IPickUtilsPicks) {
    const changes = [] as IPickUtilsPick[]
    const localPicks = this.picks
    const allSlotIds = localPicks.map(mapToSlotId).concat(persistedPicks.map(mapToSlotId)).filter(onlyUnique)
    for (const slotId of allSlotIds) {
      const localPick = localPicks.find((p) => p.slotId === slotId)
      const existingPick = persistedPicks.find((p) => p.slotId === slotId)
      if (
        !localPick ||
        !existingPick ||
        existingPick.itemId !== localPick.itemId ||
        (existingPick.additionalPoints || 0) !== (localPick.additionalPoints || 0)
      ) {
        const change = { slotId } as IPickUtilsPick
        if (existingPick && existingPick.hasOwnProperty("additionalPoints")) {
          change.additionalPoints = existingPick.additionalPoints
        }
        if (existingPick && existingPick.hasOwnProperty("multiplier")) {
          change.multiplier = existingPick.multiplier
        }
        if (localPick) {
          Object.assign(change, localPick)
        }
        changes.push(change)
      }
    }
    // for brackets mainly, check for removed items!
    const changedSlotIds = changes.map(({ slotId }) => slotId)
    const persistedPicksNotInChanges = persistedPicks.filter(({ slotId }) => !changedSlotIds.includes(slotId))
    for (const pick of persistedPicksNotInChanges) {
      const localPick = localPicks.find((p) => p.slotId === pick.slotId && p.itemId === pick.itemId)
      if (!localPick) {
        changes.push(Object.assign({}, pick, { itemId: undefined }))
      }
    }
    // console.log(`getDiffFrom:`)
    // console.dir(localPicks)
    // console.dir(persistedPicks)
    // clean up:
    for (const _pick of changes) {
      if (_pick.id) {
        delete _pick.id
      }
      if (_pick.periodId) {
        delete _pick.periodId
      }
      if (_pick.__typename) {
        delete _pick.__typename
      }
    }
    return changes
  }

  public isEventLocked(eventId: string) {
    if (!this.periodIsPickable()) {
      return true
    }
    if (this.isParlay() && this.parlayStatus() !== "unlocked") {
      return true
    }
    const event = this.getEventById(eventId)
    // NOTE qac: this probably should be for most game types, but for now just parlay:
    if (this.isParlay() && this.usesSpread()) {
      // NOTE qac: if this prop is disabled, still allow someone to unselect their pick IF they have one for this slot:
      if (event && this.getSpreadFor(event) === null) {
        return true
      }
    }
    if (event && this.getUnstartedEvents().includes(event)) {
      // if canBeUnpicked return false.
      if (this.getPick(eventId)) {
        return !this.canBeUnpicked(eventId)
      } else {
        return false
      }
    } else {
      return true
    }
  }

  public requiresNonNullSpread() {
    // NOTE qac: in the future we may want to "default" spreads to 0 for rolling style of spreads
    // for now we just dont allow picking for games with no existing spreads
    return this.usesSpread()
  }

  public usesLockedInSpread() {
    // NOTE qac: in the future we may want to have "rolling" spreads options
    return this.usesSpread()
  }

  public supportsPicksWithDifferentSpreadLockTimes() {
    // This means an Entry can have one pick locked to a spread at time x, and another at time y
    return !(this.usesLockedInSpread() && this.isParlay())
  }

  // public isBracketLocked(tournamentId?: number, bypass = false) {
  //   if (!this.isBracket()) {
  //     throw new Error(`Can only call isBracketLocked for bracket games`)
  //   }

  //   return !!this.period.tournamentRound
  // }

  public buildMappings() {
    // this.log("buildMappings")
    let matchupsWithRealEventsCount: number | null = null
    if (this.isBracket()) {
      matchupsWithRealEventsCount = this.buildBracketMapping()
      // NOTE qac: i dont think its save NOT to do this yet... since the updates need to be on this.events
      // skip this since there's no real point in this
      if (matchupsWithRealEventsCount === this.pickAgnosticMappings.matchupsWithRealEventsCount) {
        // no games added, simply patch all the ones created from previous update
        // this.patchMatchupEvents()
      } else {
        // fill in this.events with real + fake Events to help us utalize PickUtils in a similar way as other game types
        this.fillUnpopulatedMatchupEvents()
      }
    }

    // main items always to respect (isPickable is really important!)
    const fullCacheKeyParts = this.events.map(cacheKeyFor).concat([this.period.id, this.periodIsPickable().toString()])
    // Add bracket since it could provide some values (in future)
    if (this.bracketPeriodTree?.cacheKey) {
      fullCacheKeyParts.push(this.bracketPeriodTree?.cacheKey)
    }
    const newPickAgnosticCacheKey = fullCacheKeyParts.join(joiner)
    if (this.pickAgnosticMappings.cacheKey !== newPickAgnosticCacheKey) {
      // this.log("buildMappings - building pickAgnosticMappings")
      this.pickAgnosticMappings = {
        cacheKey: newPickAgnosticCacheKey,
      }
      const knownLocations = [] as string[]
      const teamById: IPickUtilsPickAgnosticMappings["teamById"] = {}
      const eventById: IPickUtilsPickAgnosticMappings["eventById"] = {}
      const teamIdsWithDupLocation: IPickUtilsPickAgnosticMappings["teamIdsWithDupLocation"] = {}
      const teamRankForPeriod: IPickUtilsPickAgnosticMappings["teamRankForPeriod"] = {}
      const teamPercentOwnedForPeriod: IPickUtilsPickAgnosticMappings["teamPercentOwnedForPeriod"] = {}
      const spreadLatestBySlotId: IPickUtilsPickAgnosticMappings["spreadLatestBySlotId"] = {}
      const spreadOpeningBySlotId: IPickUtilsPickAgnosticMappings["spreadOpeningBySlotId"] = {}
      for (const event of this.events) {
        eventById[event.id] = event
        for (const side of EventSides) {
          const team = this.getTeam(event, side)
          if (team && team.id && !teamById[team.id]) {
            teamById[team.id] = team
            if (knownLocations.includes(team.location)) {
              teamIdsWithDupLocation[team.id] = true
            }
            knownLocations.push(team.location)
            const extra = event.extra || emptyObject
            teamRankForPeriod[team.id] = extra[`${side}TeamRank`] || null
            teamPercentOwnedForPeriod[team.id] = extra[`${side}TeamPickemPercentOwned`] || null
          }
          if (!this.isBracket()) {
            spreadLatestBySlotId[`${event.id}${side}`] = this.getSpreadFor(event, side === this.home, false)
            spreadOpeningBySlotId[`${event.id}${side}`] = this.getSpreadFor(event, side === this.home, false, false, false, true)
          }
        }
      }
      this.pickAgnosticMappings.teamById = teamById
      this.pickAgnosticMappings.eventById = eventById
      this.pickAgnosticMappings.teamIdsWithDupLocation = teamIdsWithDupLocation
      this.pickAgnosticMappings.teamRankForPeriod = teamRankForPeriod
      this.pickAgnosticMappings.teamPercentOwnedForPeriod = teamPercentOwnedForPeriod
      this.pickAgnosticMappings.spreadLatestBySlotId = spreadLatestBySlotId
      this.pickAgnosticMappings.spreadOpeningBySlotId = spreadOpeningBySlotId
      this.pickAgnosticMappings.unstartedEvents = this.getUnstartedEvents()
      this.pickAgnosticMappings.slotIds = this.getSlotIds()
      this.pickAgnosticMappings.requiredSetupErrorMessage = this.requiredSetupErrorMessage()
      this.pickAgnosticMappings.tiebreakerFields = this.getTiebreakerFields()
      if (matchupsWithRealEventsCount) {
        this.pickAgnosticMappings.matchupsWithRealEventsCount = matchupsWithRealEventsCount
      }
    }
    const newCacheKey = this.bracketMapping?.cacheKey || this.pickAgnosticMappings?.cacheKey + this.picksCacheKey
    if (this.pickMappings.cacheKey !== newCacheKey) {
      // this.log("buildMappings - building pickMappings")
      // clear cache
      this.pickMappings = {}
      // rebuild cache one item at a time:
      this.pickMappings.cacheKey = newCacheKey
      const pickedItemIdBySlotId = {}
      const pickBySlotId = {}
      for (const pick of this.picks) {
        pickBySlotId[pick.slotId] = pick
        if (pick.itemId) {
          pickedItemIdBySlotId[pick.slotId] = pick.itemId
        }
      }
      this.pickMappings.pickedItemIdBySlotId = pickedItemIdBySlotId
      this.pickMappings.pickBySlotId = pickBySlotId
      const slotIds = this.getSlotIds()
      this.pickMappings.maxPicksAllowed = this.getMaxPicksAllowed()
      this.pickMappings.currentUnlockedUnpickedSlotsCount = this.getCurrentUnlockedUnpickedSlotsCount()
      this.pickMappings.pickedSlotIds = this.pickedSlotIds()
      this.pickMappings.slotIdsByRound = this.getSlotIdsByRound()

      this.pickMappings.gameWeightChoices = this.getGameWeightChoices()
      this.pickMappings.takenWeights = this.getTakenWeights()
      const additionalPointsBySlotId = {}
      const multiplierBySlotId = {}
      for (const slotId of slotIds) {
        additionalPointsBySlotId[slotId] = this.getAdditionalPoints(slotId)
        multiplierBySlotId[slotId] = this.getMultiplier(slotId)
      }
      this.pickMappings.additionalPointsBySlotId = additionalPointsBySlotId
      this.pickMappings.multiplierBySlotId = multiplierBySlotId
      const spreadBySlotId = {}
      for (const slotId of slotIds) {
        const event = this.getEventForSlotId(slotId) as IPickUtilsEvent | undefined
        if (event) {
          for (const side of EventSides) {
            spreadBySlotId[`${event.id}${side}`] = this.getSpreadFor(event, side === this.home)
          }
        }
      }
      this.pickMappings.spreadBySlotId = spreadBySlotId
      this.pickMappings.correctPickMapping = this.getCorrectPickMapping()
      this.pickMappings.correctPicks = this.getCorrectPicks()
      this.pickMappings.incorrectPicks = this.getIncorrectPicks()
      this.pickMappings.hasScoredEvent = this.hasScoredEvent()
      this.pickMappings.allEventsScored = this.allEventsScored()
      this.pickMappings.fantasyPoints = this.getFantasyPoints()
      this.pickMappings.orderedEvents = this.getOrderedEvents()
      const eventCacheKeyById = {}
      for (const slotId of slotIds) {
        const event = this.getEventForSlotId(slotId) as IPickUtilsEvent | undefined
        if (event) {
          eventCacheKeyById[event.id] = this.cacheKeyFor(event)
        }
      }
      this.pickMappings.eventCacheKeyById = eventCacheKeyById
    }
  }

  public fixConflictingModifiers(changes: IPickUtilsPicks, ignoreUnstartedEvents = false) {
    // NOTE qac: this is due to an issue where somehow a user gets multiple of the same weight in the db...
    // the correct solution is to correct the server side to validate the same way as the client side, but thats a big change.
    // this at least deals with the user side of things
    const gameWeightChoices = this.getGameWeightChoices()
    const messages = [] as string[]
    if (gameWeightChoices) {
      const merged = this.getMergedPicks(changes) as IPickUtilsPicks
      const unstartedEventIds = this.getUnstartedEvents().map(({ id }) => id)
      merged.forEach((pick) => {
        const additionalPoints = pick.additionalPoints || 0
        const existingPickWithMod = merged.find((p) => p.slotId !== pick.slotId && p.additionalPoints === additionalPoints)
        const hasInvalidWeight = !gameWeightChoices.includes(typeof pick.additionalPoints === "number" ? pick.additionalPoints : -1)
        if ((existingPickWithMod || hasInvalidWeight) && (ignoreUnstartedEvents || unstartedEventIds.includes(pick.slotId))) {
          const takenChoices = merged.map((p) => p.additionalPoints)
          const availWeights = gameWeightChoices.filter((c) => !takenChoices.includes(c))
          const availWeight = availWeights.length > 0 ? availWeights[availWeights.length - 1] : -1
          const change = changes.find((p) => p.slotId === pick.slotId)
          if (availWeight >= 0) {
            pick.additionalPoints = availWeight
            if (change) {
              change.additionalPoints = pick.additionalPoints
            } else {
              changes.push(pick)
            }
          } else {
            pick.additionalPoints = additionalPoints
            if (change) {
              change.additionalPoints = additionalPoints
            } else {
              changes.push(pick)
            }
          }
          messages.push(
            `Fixing pick: '${pick.slotId}' -> ${pick.additionalPoints} for ${
              (existingPickWithMod && existingPickWithMod.id) || `(additionalPoints: ${additionalPoints})`
            } (${availWeight}, ${JSON.stringify(availWeights)}, ${JSON.stringify(gameWeightChoices)})`,
          )
        } else {
          messages.push(`skipping ${JSON.stringify(pick)}`)
        }
      })
    }
    return messages
  }

  public setChanges(slotId: string, itemId: string | null, additionalPoints?: number | null) {
    const pick = {
      slotId,
      itemId,
    } as IPickUtilsPick
    if (!pick.slotId) {
      throw new Error(`Missing slotId`)
    }
    const existingPick = this.getPick(slotId) as IPickUtilsPick // this.picks.find((p) => p.slotId === slotId);
    this.changes = [pick]
    // -----------------------------------
    // process gameWeightChoices
    const gameWeightChoices = this.getGameWeightChoices()
    if (pick.itemId && gameWeightChoices) {
      // this.log(`gameWeightChoices: ${additionalPoints}`);
      // this.log(gameWeightChoices);
      if (isNumber(additionalPoints)) {
        let addPoints = Number(additionalPoints || 0)
        let pickWithWeight = this.getPickWithAdditionalPoints(addPoints) // this.picks.find((p) => p.additionalPoints === additionalPoints);
        const unlockedEventIds = this.getUnstartedEvents().map(mapToId)
        if (pickWithWeight && !unlockedEventIds.includes(pickWithWeight.slotId)) {
          addPoints = this.getNearestUnlockedWeight(addPoints)
          pickWithWeight = this.getPickWithAdditionalPoints(addPoints)
          // this.log(`pick locked, changing to nearest: ${addPoints}`)
        }
        if (pickWithWeight) {
          if (pickWithWeight.slotId === pick.slotId) {
            // this.log(`is same pick: modifying pick only!`);
            pick.additionalPoints = addPoints
          } else {
            // this.log(`different pick has these points, adjust the previous pick (since user probably selected the weight for this pick manually)`);
            if (!existingPick) {
              throw new Error(`must have existingPick to adjust weight`)
            }
            const originalAdditionalPoints = Number(existingPick.additionalPoints || 0)
            pick.additionalPoints = addPoints
            const movedUpList = originalAdditionalPoints < addPoints
            let increment = 1
            while (pickWithWeight && pickWithWeight.slotId !== slotId) {
              const newAddPoints = addPoints + (movedUpList ? -1 : 1) * increment
              if (!gameWeightChoices.includes(newAddPoints)) {
                throw new Error(`failed: ${newAddPoints} (${increment})`)
              }
              const nextPickToMove = this.getPickWithAdditionalPoints(newAddPoints)
              const newAddPointsIsUnlocked = !nextPickToMove || unlockedEventIds.includes(nextPickToMove.slotId)
              // this.log(`bumping pick for ${pickWithWeight.slotId} weight from ${addPoints} -> ${newAddPoints} (newAddPointsIsUnlocked?: ${newAddPointsIsUnlocked})`);
              if (newAddPointsIsUnlocked) {
                // object.assign so we get ALL values (in case this is in merges)
                const changeForWeight = Object.assign({}, pickWithWeight, {
                  additionalPoints: newAddPoints,
                })
                this.changes.push(changeForWeight)
                addPoints = newAddPoints
                pickWithWeight = this.getPickWithAdditionalPoints(addPoints)
                increment = 1
              } else {
                increment++
              }
            }
          }
        } else {
          // this.log(`no current pick has this weight`);
          pick.additionalPoints = addPoints
        }
      } else if (existingPick) {
        // this.log(`keep the existing pick's weight`);
        pick.additionalPoints = existingPick.additionalPoints
      } else {
        const potentialAddPts = this.getAdditionalPointsFromPosition(pick.slotId)
        // this.log(`no weight specified + no existing pick (${potentialAddPts})`);
        pick.additionalPoints = this.getNearestAvailableWeight(potentialAddPts)
        // pick.additionalPoints = this.getHighestAvailableWeight();
      }
    }
    // -----------------------------------
    // process propogation
    if (this.isBracket() && existingPick) {
      let currMatchup = this.matchups.find((m) => m.id === pick.slotId)
      const previousPickedItemId = existingPick.itemId
      if (currMatchup && previousPickedItemId && previousPickedItemId !== itemId) {
        while (currMatchup) {
          const nextMatchup = this.bracketUtils.getNextMatchupFor(currMatchup) as IBracketUtilMatchup | undefined
          const nextMatchupPickWithRemovedItem = this.picks.find(
            (p) => p.slotId === (nextMatchup && nextMatchup.id) && p.itemId === previousPickedItemId,
          )
          if (nextMatchupPickWithRemovedItem) {
            const change = Object.assign({}, nextMatchupPickWithRemovedItem, {
              itemId: null,
            })
            this.changes.push(change)
            currMatchup = nextMatchup
          } else {
            currMatchup = undefined
          }
        }
      }
    }
    // -----------------------------------
    // process lock in spread:
    if (pick.itemId && this.usesLockedInSpread()) {
      pick.spreadForItem = this.getLatestSpreadValueForSlotItem(pick.slotId, pick.itemId) || 0
    }
    // const multiplier = this.getMultiplier()
    // if (multiplier) {
    //
    // }
    // attempt to fix for when pool alters confidence...
    // console.log('existingPick')
    // console.dir(existingPick)
    if (
      gameWeightChoices &&
      existingPick &&
      existingPick.additionalPoints === 0 &&
      this.picks.length > 1 &&
      this.getAvailableWeights().length === gameWeightChoices.length - 1
    ) {
      this.log("fixconflicing", this.fixConflictingModifiers(this.changes))
      // return this.setChanges(slotId, itemId, additionalPoints)
    }
    // this.log(`changes: ${slotId} ${itemId} ${additionalPoints}`);
    // console.dir(this.changes);
    return this.changes
  }

  public isWinning(event: IPickUtilsEvent, pickedSide: TEventSide) {
    const spread = this.getSpreadFor(event, pickedSide === this.home)
    const adjustedSpread = getSpreadValue(spread)
    const otherSide = pickedSide === this.home ? this.away : this.home
    return (event[`${pickedSide}TeamScore`] || 0) + adjustedSpread > (event[`${otherSide}TeamScore`] || 0)
  }

  public getCorrectPickMapping(): TStringMapping {
    if (this.isBracket()) {
      return super.getCorrectPickMapping()
    } else {
      const correctPickMapping = {} as TStringMapping
      for (const event of this.events) {
        // Need to add a check for gameStatus since NFL games can end in tie's and there is no winning teamId
        if (event.winningTeamId || event.gameStatusDesc === ENUM_FINAL) {
          const spread = this.getSpreadFor(event)
          const adjustedSpread = getSpreadValue(spread)
          let winningTeamId = event.winningTeamId
          const gameStatus = event.gameStatusDesc || ""
          // Spreads can be decimals, in which case their can be no ties.
          const adjustedHomeTeamScore = parseFloat(event.homeTeamScore.toString()) + adjustedSpread
          const awayTeamScore = parseFloat(event.awayTeamScore.toString())
          // this.log(`correctPickMapping: ${homeTeamSpread}: ${adjustedScore}`)
          // if there is a tie, NO TEAM is the winner (all picks are losers) (set the winning team id to "tie")
          if (adjustedHomeTeamScore < awayTeamScore && event.awayTeam?.id) {
            winningTeamId = event.awayTeam.id
          } else if (adjustedHomeTeamScore > awayTeamScore && event.homeTeam?.id) {
            winningTeamId = event.homeTeam.id
          } else {
            winningTeamId = "tie"
          }
          // CANCELLED/POSTPONED: this has a winning team set, but no score... however we DONT want to score it:
          if (invalidFinalGameStatuses.includes(gameStatus)) {
            winningTeamId = gameStatus
          }
          // pushes for games with spreadPushDecider:
          if (winningTeamId === "tie" && this.usesSpread() && this.period.spreadPushDecider !== ENUM_TIE) {
            // we need to determine if the push goes the way of the favored or underdog
            // we cannot just add this to adjustedHomeTeamScore since we would need to know the underdog vs favored team
            // which is possibly based on the spread locked into the pick
            const favoredTeam = this.getPropFavoredTeamFor(event, true)
            const unfavoredTeam = (favoredTeam?.id === event.homeTeam?.id && event.awayTeam) || event.homeTeam
            if (adjustedSpread) {
              winningTeamId =
                (this.period.spreadPushDecider === ENUM_FAVORED && favoredTeam?.id) ||
                (this.period.spreadPushDecider === ENUM_UNDERDOG && unfavoredTeam?.id) ||
                "tie"
            } else {
              // this is TIE GAME and a PK (pickem, 0.0): correct if they pick "No" (unfavored team)
              winningTeamId = unfavoredTeam?.id || "tie"
            }
          }
          correctPickMapping[event.id] = winningTeamId
        }
      }
      return correctPickMapping
    }
  }

  public isValidBracketPropogatingPick(pick: IPickUtilsPick, pickList: IPickUtilsPicks) {
    if (!this.isBracket()) {
      return true
    }
    if (this.bracketMapping?.matchupById) {
      const matchup = this.bracketMapping.matchupById[pick.slotId]
      const tree = this.bracketPeriodTree
      if (!matchup) {
        return false
      }
      if (!pick.itemId) {
        return true
      }
      const teamPropagation = tree?.teamPropagation[pick.itemId] || []
      // NOTE qac: slotIdsKey is used for testing in shared lib
      const matchupSlotIds = matchup?.slotIds || (matchup as any)?.slotIdsKey?.split("-") || []
      if (!matchupSlotIds.length) {
        // Bracket doesnt support primpy slotIds, skip these checks
        return true
      }
      const numberMatchupSlotIds = matchupSlotIds.map((x) => Number(x))
      if (!teamPropagation.some((r) => numberMatchupSlotIds.includes(r))) {
        // if team propagation doesn't have the MU slotIds => incorrect
        return false
      }
      const nextMatchup = this.bracketUtils.getNextMatchupFor(matchup)
      if (nextMatchup) {
        const nextMatchupSlotIds = nextMatchup?.slotIds || (nextMatchup as any)?.slotIdsKey?.split("-") || []
        const numberNextMatchupSlotIds = nextMatchupSlotIds.map((x) => Number(x))
        if (!teamPropagation.some((r) => numberNextMatchupSlotIds.includes(r))) {
          // if team propagation doesn't have the nextMU slotIds => incorrect
          return false
        }
      }
      const prevMatchups = this.bracketUtils.getPreviousMatchupsFor(matchup, tree)
      if (prevMatchups) {
        for (const prevMatch of prevMatchups) {
          const prevPick = this.getPick(prevMatch.id)
          if (prevPick?.itemId === pick.itemId) {
            return true
          }
          // if is not picked yet, check if is one of the picks to save.
          const prevPickToSave = pickList.find((x) => x.slotId === prevMatch.id)
          if (prevPickToSave?.itemId === pick.itemId) {
            return true
          }
        }
      } else {
        return true
      }
    }
    throw new Error(`failed to build bracketMapping`)
  }

  public getSlotIds() {
    if (this.pickAgnosticMappings.slotIds) {
      return this.pickAgnosticMappings.slotIds
    }
    return super.getSlotIds()
  }

  public getSlotIdsByRound(): Record<string, string[]> | undefined {
    if (this.pickMappings.slotIdsByRound) {
      return this.pickMappings.slotIdsByRound
    }
    if (this.isBracket()) {
      const result: Record<string, string[]> = {}
      const rounds = this.bracketPeriodTree?.rounds
      if (rounds) {
        for (const round of rounds) {
          result[`${round}`] = this.matchups.filter((m) => m.tournamentRound === round).map((x) => x.id.toString())
        }
      }
      return result
    } else {
      return
    }
  }

  public getUnpickedPickableSlotIds(picks: IPickUtilsPicks) {
    return (this.getUnpickedPickableSlots(picks) as IFeRow[]).map(mapToId) as string[]
  }

  public getUnpickedPickableSlots(picks: IPickUtilsPicks) {
    if (this.isBracket()) {
      return this.matchups.filter((e) => !picks.find((p) => p.slotId === e.id))
    } else {
      return this.getUnstartedEvents().filter((e) => !picks.find((p) => p.slotId === e.id))
    }
  }

  public hasStartedEvents() {
    return this.getUnstartedEvents().length < this.getSlotIds().length
  }

  public isCollegeSport() {
    return /ncaa/i.test(this.sportType)
  }

  public usesSpread() {
    return usesSpread(this.spreadType)
  }

  public getSpreadFor(
    event: IPickUtilsEvent,
    isHomeTeam = true,
    useCurrentPicksLockedSpread = true,
    reconvert = false,
    adjustForTies = false,
    useOpeningSpread = false,
  ) {
    return null
  }

  public getTakenWeights() {
    if (this.pickMappings.takenWeights) {
      return this.pickMappings.takenWeights
    }
    // -1 so that zero is up for grabs!
    const taken = this.picks.map((p) => p.additionalPoints).filter(isNumber) as number[]
    return taken
  }

  public getNearestUnlockedWeight(closeTo: number, exceptThisWeight = -1) {
    const unstartedEventIds = this.getUnstartedEvents().map(mapToId) as string[]
    const availableAdditionalPoints = unstartedEventIds.map((id) => this.getAdditionalPointsFromPosition(id))
    availableAdditionalPoints.sort((a, b) => Math.abs(a - closeTo) - Math.abs(b - closeTo))
    return (availableAdditionalPoints.length && availableAdditionalPoints[0]) || 0
  }

  public getNearestAvailableWeight(closeTo: number, exceptThisWeight = -1) {
    const available = this.getAvailableWeights()
    available.sort((a, b) => Math.abs(a - closeTo) - Math.abs(b - closeTo))
    return (available.length && available[0]) || 0
  }

  // public getHighestAvailableWeight() {
  //   const available = this.getAvailableWeights();
  //   return available.length && available[available.length - 1] || 0;
  // }

  public getAvailableWeights() {
    const taken = this.getTakenWeights()
    const gameWeightChoices = this.getGameWeightChoices() || emptyArray
    const available = gameWeightChoices.filter((w) => taken.indexOf(w) < 0)
    // this.log("available: ", available, gameWeightChoices, taken);
    return available
  }

  public getWeightFor(slotId: string) {
    if (this.usesWeights()) {
      return this.getAdditionalPoints(slotId)
    }
    return null
  }

  public getEventById(eventId: string): IPickUtilsEvent {
    if (this.pickAgnosticMappings.eventById) {
      return this.pickAgnosticMappings.eventById[eventId]
    }
    return super.getEventById(eventId) as IPickUtilsEvent
  }

  public getMatchupById(matchupId: string): IBracketUtilMatchup {
    const matchup = (this.bracketMapping?.matchupById[matchupId] as IBracketUtilMatchup) || this.matchups.find((m) => m.id === matchupId)
    // invariant(!!matchup, `cannot find matchupId: ${matchupId}`)
    return matchup
  }

  public getMultiplier(slotId: string): number {
    if (this.pickMappings.multiplierBySlotId) {
      return this.pickMappings.multiplierBySlotId[slotId]
    }
    // const pick = this.getPick(slotId);
    if (this.roundBonusType === "STANDARD") {
      if (this.isBracket()) {
        // This is for March Madness
        if (this.multipleEntriesOption === "AVAILABLE" && this.roundModifiersOption === "AVAILABLE") {
          const matchup = this.getMatchupById(slotId)
          if (matchup) {
            const pick = this.getPick(slotId)
            const roundModifier = this.roundModifiersByRound[matchup.tournamentRound]
            if (roundModifier?.match(/MULTIPLY_SEED/) && pick?.itemId) {
              if (this.pickAgnosticMappings.teamRankForPeriod && this.pickAgnosticMappings.teamRankForPeriod[pick.itemId]) {
                return this.pickAgnosticMappings.teamRankForPeriod[pick.itemId] ?? 1
              }
              return 1
            }
          }
        } else {
          // We want to do this for conf tournament
          // NOTE qac: for brackets, there can be multiple tournaments with multiple rounds.
          // to implement this, we use a reverse array (since it allows for all levels)
          const matchup = this.getMatchupById(slotId)
          if (matchup) {
            const tournamentRounds = this.bracketUtils.getRoundsFor(matchup.tournamentId)
            const rbs = this.roundBonuses || emptyArray
            const diff = tournamentRounds.length - rbs.length
            // [1,2,3,4,5]
            // [2,3]
            // => [1,1,1,2,3]
            // NOTE qac: (rbs.length ? rbs[0] : 1) means: if this is a prelim round: use the "first bonus"
            const mappedMultipliers = tournamentRounds.map((_, i) => (i - diff < 0 ? (rbs.length ? rbs[0] : 1) : rbs[i - diff]))
            return mappedMultipliers[tournamentRounds.indexOf(matchup.tournamentRound)]
          }
        }
      } else {
        const order = this.period.order
        const rbs = this.roundBonuses || emptyArray
        return rbs.length >= order ? rbs[order - 1] : order
      }
    }
    return 1
  }

  public getPickWithAdditionalPoints(additionalPoints: number) {
    return ((this.merged.length && this.merged) || this.picks).find((p) => (p.additionalPoints || 0) === additionalPoints)
  }

  public getPick(slotId: string) {
    if (this.pickMappings.pickBySlotId && !this.merged.length) {
      return this.pickMappings.pickBySlotId[slotId]
    }
    return super.getPick(slotId)
  }

  public getAdditionalPointsFromPosition(slotId: string) {
    // TODO qac: optimize
    const events = this.getOrderedEvents()
    const newIndex = events.findIndex((p) => p.id === slotId)
    // if (newIndex < 0) {
    //   throw new Error(`nay ${slotId}`)
    // }
    const idx = newIndex
    // let additionalPoints = events.length - (idx + 1)
    const event: IPickUtilsEvent | undefined = events[idx]
    const pick = this.getPick(event.id) as IPickUtilsPick
    // while (event && !pick) {
    //   console.log(`stepping: ${idx} -> ${idx + 1}`)
    //   idx++;
    //   event = idx >= 0 ? events[idx] : undefined;
    //   pick = event && this.getPick(event.id);
    // }
    const additionalPoints = Number(pick ? pick.additionalPoints : events.length - (idx + 1))
    // const choices = this.getGameWeightChoices();
    // if (!choices || !choices.includes(additionalPoints)) {
    //   throw new Error('nope')
    // }
    return additionalPoints
  }

  public findPickWithAdditionalPoints(additionalPoints: number) {
    return ((this.merged.length && this.merged) || this.picks).find((p) => p.additionalPoints === additionalPoints)
  }

  public getPositionForAdditionalPoints(additionalPoints: number) {
    const events = this.getOrderedEvents()
    const pickWithAddPts = this.findPickWithAdditionalPoints(additionalPoints)
    const eventWithPick = pickWithAddPts?.slotId && this.getEventById(pickWithAddPts.slotId)
    const idx = eventWithPick ? events.indexOf(eventWithPick) : events.length - (additionalPoints + 1)
    return idx
    // let event = events[idx];
    // let pick = this.getPick(event.id);
    // while (!pick && event) {
    //   idx++;
    //   event = events[idx];
    //   if (event) {
    //     pick = event && this.getPick(event.id);
    //   } else {
    //     idx = events.length - 1;
    //   }
    // }
    // return idx;
  }

  public getAdditionalPoints(slotId: string) {
    // NOTE LL: Do not want to use cache for Confidence pools. It will be different per user. Quick fix to get back to brackets
    if (this.gameWeightType !== GameWeightTypeEnum.CONFIDENCE && this.pickMappings.additionalPointsBySlotId) {
      return this.pickMappings.additionalPointsBySlotId[slotId] || 0
    }
    const pick: IPickUtilsPick | undefined = this.getPick(slotId)
    if (pick) {
      if (this.gameWeightType === GameWeightTypeEnum.CONFIDENCE) {
        return pick.additionalPoints || 0
      } else if (this.gameWeightType === GameWeightTypeEnum.MULTIPLY_SEED) {
        let pickedTeamRank = 0
        if (this.isBracket()) {
          pickedTeamRank = (pick.itemId && this.matchupSeedFor(pick.itemId)) || 0
        } else {
          const event = this.events.find((ev) => ev.id === slotId)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          pickedTeamRank = (event && event.extra && (event.homeTeam!.id === pick.itemId ? event.extra?.homeTeamRank : event.extra?.awayTeamRank)) || 0
        }
        // minus 1 since these are "additionalPoints" (added on top of base points)
        return pickedTeamRank ? pickedTeamRank - 1 : 0
      } else if (this.gameWeightType === GameWeightTypeEnum.ROUND) {
        let bonus = 0
        if (this.multipleEntriesOption === "AVAILABLE" && this.roundModifiersOption === "AVAILABLE") {
          const matchup = this.getMatchupById(slotId)
          if (matchup) {
            if (this.roundBonusesByRound[matchup.tournamentRound]) {
              // subtracting 1 since basePoints are 1
              bonus += this.roundBonusesByRound[matchup.tournamentRound] - 1
            }
            const roundModifier = this.roundModifiersByRound[matchup.tournamentRound]
            if (roundModifier?.match(/ADD_SEED/) && pick.itemId) {
              if (this.pickAgnosticMappings.teamRankForPeriod && this.pickAgnosticMappings.teamRankForPeriod[pick.itemId]) {
                bonus += this.pickAgnosticMappings.teamRankForPeriod[pick.itemId] ?? 0
              }
            }
          }
        }
        return bonus
      }
    }
    return 0
  }

  public getPossiblePointsFor(slotId: string) {
    const pick = this.getPick(slotId)
    const basePoints = 1 + this.getAdditionalPoints(slotId)
    return (pick && basePoints * (this.getMultiplier(slotId) || 1)) || 0
  }

  public isSingleOptionParlay() {
    // NOTE qac: this is our 2020 switch to help us with the leaderboard ordering
    return this.isParlay() && this.minPicksPerPeriodCount === this.maxPicksPerPeriodCount
  }

  public getParlayMinPicks() {
    return this.minPicksPerPeriodCount
  }

  public parlayStatus() {
    if (!this.isParlay()) {
      return "none"
    }
    const pickedSlotIds = this.pickedSlotIds()
    const unstartedEventIds = this.getUnstartedEvents().map(mapToId)
    const lockedSlotId = pickedSlotIds.find((slotId) => !unstartedEventIds.includes(slotId))
    if (!lockedSlotId && unstartedEventIds.length >= this.getParlayMinPicks()) {
      return "unlocked"
    }
    if (pickedSlotIds.length < this.getParlayMinPicks()) {
      return "busted"
    }
    const correctPickMapping = this.getCorrectPickMapping()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const notCorrectSlotIds = pickedSlotIds.filter((slotId) => correctPickMapping[slotId] !== this.getPick(slotId)!.itemId)
    if (notCorrectSlotIds.length) {
      const wrongSlotId = notCorrectSlotIds.find((slotId) => !!correctPickMapping[slotId])
      if (wrongSlotId) {
        return "busted"
      } else {
        return "live"
      }
    }
    return "correct"
  }

  public getSpreadMovementInfoFor(event: IPickUtilsEvent, pickedItemId?: string | null): IPickUtilsEventSpreadChange {
    // Idea behind logic:
    // * get home team oriented spread info
    // * get whether pick was home team or not
    // * calculate direction based on both
    // NOTE qac: see getLatestSpreadValueForSlotItem for how we store "spread" on a Pick
    // NOTE qac: we have adjusted this logic for less confusion for the user...
    const useCurrentPicksLockedSpread = !!pickedItemId
    const favoredTeam = this.getPropFavoredTeamFor(event, useCurrentPicksLockedSpread) || this.getTeam(event, "home")
    const favoredTeamIsHomeTeam = favoredTeam?.id === event.homeTeam?.id
    const openingFavoredTeamSpread = this.displayFormattedSpreadFor(event, favoredTeamIsHomeTeam, false, undefined, undefined, true)
    const latestFavoredTeamSpread = this.displayFormattedSpreadFor(event, favoredTeamIsHomeTeam, false)
    const spreadNotAvailable = !latestFavoredTeamSpread
    const pickFavoredTeamSpread = (pickedItemId && this.displayFormattedSpreadFor(event, favoredTeamIsHomeTeam, true)) || null
    const hasPickWithDifferentSpreadFromLatest = !!pickFavoredTeamSpread && pickFavoredTeamSpread !== latestFavoredTeamSpread
    const hasAPick = !!hasPickWithDifferentSpreadFromLatest
    const openingOrPickFavoredTeamSpread = (hasPickWithDifferentSpreadFromLatest && pickFavoredTeamSpread) || openingFavoredTeamSpread
    const openingOrPickItemIsFavoredTeam = !hasPickWithDifferentSpreadFromLatest || pickedItemId === favoredTeam?.id
    const spreadToCompareToLatest = (hasPickWithDifferentSpreadFromLatest && openingOrPickFavoredTeamSpread) || latestFavoredTeamSpread
    const directionSpreadMove =
      spreadToCompareToLatest !== latestFavoredTeamSpread
        ? (getSpreadValue(latestFavoredTeamSpread) > getSpreadValue(spreadToCompareToLatest) && openingOrPickItemIsFavoredTeam && "better") || "worse"
        : "none"
    return {
      spreadNotAvailable,
      directionSpreadMove,
      openingOrPickFavoredTeamSpread,
      favoredTeam,
      latestFavoredTeamSpread,
      hasAPick,
      openingOrPickItemIsFavoredTeam,
      favoredTeamIsHomeTeam,
    }
  }

  public getPropFavoredTeamSideFor(event: IPickUtilsEvent, useCurrentPicksLockedSpread = true) {
    const homeTeamSpread = this.getSpreadFor(event, true, useCurrentPicksLockedSpread)
    const { awayTeam, homeTeam } = event
    if (!homeTeam || !awayTeam || !homeTeamSpread) {
      // invalid question: NO spread
      return null
    }
    const spreadValue = getSpreadValue(homeTeamSpread)

    if (spreadValue) {
      return (spreadValue < 0 ? "home" : "away") as TEventSide
    } else {
      // NOTE qac: if a pickem, we need to decide one or the other, home is favored or away is favored:
      // we chose AWAY (2020 parlay)
      return "away" as TEventSide
    }
  }

  public getPropFavoredTeamFor(event: IPickUtilsEvent, useCurrentPicksLockedSpread = true) {
    const side = this.getPropFavoredTeamSideFor(event, useCurrentPicksLockedSpread)
    return side && this.getTeam(event, side)
  }

  public getPickEmPropQuestionForEvent = (event: IPickUtilsEvent, useCurrentPicksLockedSpread = true, variant: 1 | 2 = 1) => {
    const { awayTeam, homeTeam } = event
    const favoredTeam = this.getPropFavoredTeamFor(event, useCurrentPicksLockedSpread)
    const favoredIsHome = favoredTeam === homeTeam
    const unfavoredTeam = ((favoredIsHome && awayTeam) || homeTeam) as IPickUtilsTeam | null
    if (!favoredTeam || !unfavoredTeam) {
      return `The spread for this matchup is currently unavailable.`
    }
    const spread = this.getSpreadFor(event, favoredIsHome, useCurrentPicksLockedSpread)
    const spreadValue = getSpreadValue(spread)
    const needsModifier = spreadValue === Math.round(spreadValue)
    const modifier = (needsModifier && this.period.spreadPushDecider === ENUM_UNDERDOG && 1) || 0.0
    // 0 + 3.0 = win by 3 or more (favored is favored)
    // 1 + 3.0 = win by 4 or more (underdog is favored)
    // 0.4 + 0.5 + 3.0 = win by 4 or more (underdog is favored)
    // 3.5 = win by 4 or more (underdog is favored)
    const bettingLineToUse = Math.ceil(modifier + Math.abs(spreadValue))
    if (!spreadValue) {
      // this is a PK (pickem: 0.0 spread)
      return `Will ${this.teamLocationWithAbbrevIfNeeded(favoredTeam)} beat ${this.teamLocationWithAbbrevIfNeeded(unfavoredTeam)}?`
    }
    // for everything else, we are garenteed that the bettingLineToUse > 0
    if (variant === 2) {
      return `Will ${this.teamLocationWithAbbrevIfNeeded(favoredTeam)} beat ${this.teamLocationWithAbbrevIfNeeded(
        unfavoredTeam,
      )} by ${bettingLineToUse} or more points?`
    } else {
      return `Will ${this.teamLocationWithAbbrevIfNeeded(favoredTeam)} win by ${bettingLineToUse} or more points?`
    }
  }

  public teamLocationWithAbbrevIfNeeded(team: IPickUtilsTeam) {
    let str = team.location
    if (this.teamHasDuplicateLocationInSet(team)) {
      str += ` (${team.abbrev})`
    }
    return str
  }

  public teamHasDuplicateLocationInSet(team: IPickUtilsTeam) {
    invariant(!!this.pickAgnosticMappings.teamIdsWithDupLocation, `must build pickAgnosticMappings`)
    return !!this.pickAgnosticMappings.teamIdsWithDupLocation[team.id]
  }

  public usesWeights() {
    return this.gameWeightType === GameWeightTypeEnum.CONFIDENCE
  }

  public requiresDnDWeightInterface() {
    return (
      this.usesWeights() &&
      typeof this.maxPicksPerPeriodCount === "number" &&
      this.maxPicksPerPeriodCount < (this.isBracket() ? this.matchups : this.events).length
    )
  }

  public humanPick(eventId: string, teamId?: string | null, includeDate = true) {
    const event = this.getEventById(eventId)
    if (event) {
      const homeTeam = this.getTeam(event, this.home)
      const awayTeam = this.getTeam(event, this.away)
      const tid = teamId || event.winningTeamId
      const team = (homeTeam.id === tid && homeTeam) || (awayTeam.id === tid && awayTeam)
      const parts = [`${awayTeam.abbrev}@${homeTeam.abbrev}`]
      if (includeDate) {
        parts.push(new Date(event.startsAt).toLocaleString())
      }
      parts.push((team && team.abbrev) || "none")
      const currentSpread = this.getSpreadFor(event, !!team && team.id === homeTeam.id, false)
      const spread = this.getSpreadFor(event, !!team && team.id === homeTeam.id, true)
      if (spread) {
        parts.push(spread)
      }
      if (currentSpread !== spread) {
        parts.push(`(current spread: ${currentSpread})`)
      }
      return parts
    }
    return ["not found"]
  }

  public printCorrectMapping() {
    const correctPickMapping = this.getCorrectPickMapping()
    return Object.keys(correctPickMapping).map((itemId) => this.humanPick(itemId, correctPickMapping[itemId]).join(" - "))
  }

  public getFantasyPoints() {
    if (typeof this.pickMappings.fantasyPoints === "number") {
      return this.pickMappings.fantasyPoints
    }
    // NOTE qac: for parlay, if its single option, we award points based on correct / incorrect (for leaderboard)
    if (this.isParlay() && !this.isSingleOptionParlay()) {
      if (!["live", "correct"].includes(this.parlayStatus())) {
        return 0
      }
      // const correctPicks = this.getCorrectPicks();
      // NOTE qac: for parlay, we care about possible points until you bust
      return this.getParlayPossiblePoints()
    } else {
      const basePoints = 1
      const correctPicks = this.getCorrectPicks()
      return correctPicks
        .map((pick) => (basePoints + this.getAdditionalPoints(pick.slotId)) * this.getMultiplier(pick.slotId))
        .reduce((accumulator, pts) => accumulator + pts, 0)
    }
  }

  public getCorrectPicks() {
    if (this.pickMappings.correctPicks) {
      return this.pickMappings.correctPicks
    }
    return super.getCorrectPicks()
  }

  public getIncorrectPicks() {
    if (this.pickMappings.incorrectPicks) {
      return this.pickMappings.incorrectPicks
    }
    return super.getIncorrectPicks()
  }

  public getTiebreakerAttrFor(enumType: TiebreakerTypeEnum) {
    if (this.mainTiebreaker === enumType) {
      return "mainTiebreaker"
    }
    if (this.secondaryTiebreaker === enumType) {
      return "secondaryTiebreaker"
    }
    if (this.thirdTiebreaker === enumType) {
      return "thirdTiebreaker"
    }
    if (this.fourthTiebreaker === enumType) {
      return "fourthTiebreaker"
    }
    return null
  }

  public roundTotalsTiebreakerAttr() {
    return this.getTiebreakerAttrFor(TiebreakerTypeEnum.ROUND_TOTALS)
  }

  public totalScoreTiebreakerAttr() {
    return this.getTiebreakerAttrFor(TiebreakerTypeEnum.TOTAL_SCORE)
  }

  public customTiebreakerAttr() {
    return this.getTiebreakerAttrFor(TiebreakerTypeEnum.CUSTOM)
  }

  public totalTournamentWinnerTiebreakerAttr() {
    return this.getTiebreakerAttrFor(TiebreakerTypeEnum.TOURNAMENT_WINNER)
  }

  public tiebreakerQuestionsAttr() {
    if (TiebreakerTypesWithQuestions.includes(this.mainTiebreaker)) {
      return "mainTiebreaker"
    }
    if (TiebreakerTypesWithQuestions.includes(this.secondaryTiebreaker)) {
      return "secondaryTiebreaker"
    }
    if (TiebreakerTypesWithQuestions.includes(this.thirdTiebreaker)) {
      return "thirdTiebreaker"
    }
    if (TiebreakerTypesWithQuestions.includes(this.fourthTiebreaker)) {
      return "fourthTiebreaker"
    }
    return null
  }

  public isTiebreakerPeriod() {
    return ((this.tiebreakerQuestionsAttr() && this.period.tiebreakerQuestions) || emptyArray).length > 0
  }

  public validateTiebreaker(tiebreakerAnswers: TTiebreakerAnswers) {
    if (!this.tiebreakerQuestionsAttr()) {
      throw new Error(`Pool doesn't use tiebreaker`)
    }
    if (!this.isTiebreakerPeriod()) {
      throw new Error(`You cannot enter a tiebreaker for this period`)
    }
    const secondEvent = this.getSecondEventOfThePeriod()
    if (secondEvent) {
      if (this.tiebreakerIsLocked() && this.secondTiebreakerIsLocked()) {
        throw new Error(`Tiebreaker entering is locked`)
      }
    } else {
      if (this.tiebreakerIsLocked()) {
        throw new Error(`Tiebreaker entering is locked`)
      }
    }
    const fields = this.getTiebreakerFields()
    const tiebreakerQuestionIds = fields.map(mapToId)
    tiebreakerAnswers.forEach(({ value, tiebreakerQuestionId }) => {
      const tiebreakerQuestionWithFields = fields.find(({ id }) => id === tiebreakerQuestionId)
      if (!tiebreakerQuestionWithFields) {
        throw new Error(`Invalid tiebreaker: 'tiebreakerQuestionId: ${tiebreakerQuestionId} not in ${tiebreakerQuestionIds.join(", ")}'`)
      }
      const { input } = tiebreakerQuestionWithFields
      const valueAsNumber = tryToCastToInteger(value)
      if (input.type === "number" && typeof valueAsNumber !== "number") {
        throw new Error(`Invalid tiebreaker: '${value}', must be a whole ${input.type}`)
      }
      if (input.hasOwnProperty("min") && typeof valueAsNumber === "number" && valueAsNumber < Number(input.min)) {
        throw new Error(`Invalid tiebreaker: must be greater than ${input.min}`)
      }
      if (input.hasOwnProperty("max") && typeof valueAsNumber === "number" && valueAsNumber > Number(input.max)) {
        throw new Error(`Invalid tiebreaker: must be less than ${input.max}`)
      }
      if (input.options && !input.options.find((opt) => opt.value === value)) {
        throw new Error(`Invalid tiebreaker: must be one of the options: ${input.options.map(({ label }) => label).join(", ")}`)
      }
    })
    return true
  }

  public hasScoredEvent() {
    if (this.pickMappings.hasOwnProperty("hasScoredEvent")) {
      return this.pickMappings.hasScoredEvent
    }
    return Object.keys(this.getCorrectPickMapping()).length > 0
  }

  public allEventsScored() {
    if (this.pickMappings.hasOwnProperty("allEventsScored")) {
      return this.pickMappings.allEventsScored
    }
    return Object.keys(this.getCorrectPickMapping()).length === this.events.length
  }

  public getUnstartedEvents() {
    if (this.pickAgnosticMappings.unstartedEvents) {
      return this.pickAgnosticMappings.unstartedEvents
    }
    const nowAt = Date.now()
    // mark cancelled and postponed events as started
    return this.events.filter((e) => e.startsAt > nowAt && !invalidFinalGameStatuses.includes(e.gameStatusDesc || ""))
  }

  public getParlayPossiblePoints() {
    return this.parlayScoringMapping[this.picks.length]
  }

  public getNextPickableUnpickedLocksAt() {
    if (!this.periodIsPickable()) {
      return undefined
    }
    if (this.isBracket()) {
      if (!this.period.locksAt) {
        throw new Error(`bracket game types require a locking period`)
      }
      return this.period.locksAt || Date.now() + oneDay
    } else {
      const slotIds = this.pickedSlotIds()
      const event = this.getUnstartedEvents().find((e) => slotIds.indexOf(e.id) < 0)
      return (event && event.startsAt) || undefined
    }
  }

  public canHaveOverallEntryPoints() {
    if (this.isBracket()) {
      return !this.periodIsPickable()
    } else if (this.isParlay() && !this.isSingleOptionParlay()) {
      return this.allEventsScored()
    } else {
      return this.hasScoredEvent()
    }
  }

  public getViewablePicks() {
    if (this.isBracket()) {
      if (this.periodIsPickable()) {
        return emptyArray
      } else {
        return this.picks
      }
    } else if (this.isParlay()) {
      if (this.getUnstartedEvents().length > this.getParlayMinPicks()) {
        return emptyArray
      } else {
        return this.picks
      }
    } else if (this.picksDeadlineType === PicksDeadlineTypeEnum.BEFORE_START_OF_PERIODS_FIRST_GAME) {
      if (this.periodIsPickable()) {
        return emptyArray
      } else {
        return this.picks
      }
    } else {
      const unstartedEventIds = this.getUnstartedEvents().map(mapToId)
      return this.picks.filter(({ slotId }) => !unstartedEventIds.includes(slotId))
    }
  }

  public shouldMakePicks(tiebreakerAnswers?: TTiebreakerAnswers, excludeTiebreakers = false) {
    const status = this.parlayStatus()
    if (status !== "none") {
      if (status !== "unlocked") {
        return false
      }
      if (!this.hasMinRequiredPicks()) {
        return true
      }
      if (!this.isTiebreakerPeriod()) {
        return false
      } else if (this.hasMadeAllPicks() && !(excludeTiebreakers || this.isPrizeEligible(tiebreakerAnswers))) {
        return true
      } else {
        return false
      }
    } else {
      if (!this.hasMadeAllPicks()) {
        return true
      }
      if (!excludeTiebreakers && this.isTiebreakerPeriod()) {
        return !this.allTiebreakersAnswered(tiebreakerAnswers)
      }
      return false
    }
  }

  public picksMade() {
    return this.pickedSlotIds().length
  }

  public pickedSlotIds() {
    if (this.pickMappings.pickedSlotIds) {
      return this.pickMappings.pickedSlotIds
    }
    return uniqueNonNull(this.picks.filter((pick) => !!pick.itemId).map((pick) => pick.slotId))
  }

  public getMaxPicksAllowed() {
    if (this.pickMappings.maxPicksAllowed) {
      return this.pickMappings.maxPicksAllowed
    }
    // NOTE qac: we use getUnstartedEvents here to exclude unpickable games, however we do not exclude games without spreads currently
    const pickableItems = this.isBracket() ? this.matchups : this.events
    return Math.min(this.maxPicksPerPeriodCount || 99999, pickableItems.length)
  }

  public getCurrentUnlockedUnpickedSlotsCount() {
    if (this.pickMappings.currentUnlockedUnpickedSlotsCount) {
      return this.pickMappings.currentUnlockedUnpickedSlotsCount
    }
    const pickedSlotIds = this.pickedSlotIds()
    if (this.isBracket()) {
      return this.matchups.filter((m) => !pickedSlotIds.includes(m.id) && !this.isBracketLocked(m.tournamentId)).length
    } else {
      return this.events.filter((event) => !pickedSlotIds.includes(event.id) && !this.isEventLocked(event.id)).length
    }
  }

  public getMissedPickCount() {
    const pickedSlotIds = this.pickedSlotIds()
    const picksCount = pickedSlotIds.length
    const currentUnlockedUnpickedSlotsCount = this.getCurrentUnlockedUnpickedSlotsCount()
    const maxPicksAllowed = this.getMaxPicksAllowed()
    if (picksCount + currentUnlockedUnpickedSlotsCount < maxPicksAllowed) {
      return maxPicksAllowed - picksCount - currentUnlockedUnpickedSlotsCount
    }
    return 0
  }

  public hasMadeAllPicks() {
    const pickedSlotIds = this.pickedSlotIds()
    const picksCount = pickedSlotIds.length
    // check for max picks reach, then if there are still pickable items
    return picksCount >= this.getMaxPicksAllowed() || this.getCurrentUnlockedUnpickedSlotsCount() === 0
  }

  public hasModifiers() {
    return !!this.getHumanMultiplierSource() || !!this.getHumanAdditionalPointsSource()
  }

  public getHumanMultiplierSource() {
    if (!this.roundBonusType) {
      return null
    }
    return (this.roundBonusType === RoundBonusTypeEnum.STANDARD && `round bonus`) || null
  }
  public getHumanAdditionalPointsSource(slotId?: string) {
    if (!this.gameWeightType) {
      return null
    }
    return (
      (this.gameWeightType === GameWeightTypeEnum.CONFIDENCE && `confidence weight`) ||
      (this.gameWeightType === GameWeightTypeEnum.MULTIPLY_SEED && (slotId ? `seed: ${toRank(this.getAdditionalPoints(slotId) + 1)}` : `seed`)) ||
      null
    )
  }

  public getGameWeightChoices() {
    if (this.gameWeightType === GameWeightTypeEnum.CONFIDENCE) {
      if (this.pickMappings.gameWeightChoices) {
        return this.pickMappings.gameWeightChoices
      }
      if (this.pickMappings.gameWeightChoices) {
        return this.pickMappings.gameWeightChoices
      }
      const allowedPickAmount = this.getMaxPicksAllowed()
      const weights = this.events.map((_i, i) => i)
      return weights.slice(0, allowedPickAmount)
    }
    return null
  }
  public _weightedEventSorterScore = (event: IPickUtilsEvent) => {
    const pick = this.getPick(event.id) as IPickUtilsPick
    const allowedPickAmount = this.getMaxPicksAllowed()
    if (pick && typeof pick.additionalPoints === "number") {
      // if put at 2nd: this will be 1
      // if put at 1st: this will be 1
      // if put at 8th: this will be 8
      const mod = pick.additionalPoints / 1000 // 1 -
      const positionWithMod = allowedPickAmount - (pick.additionalPoints + 1) + mod
      // console.log(`(${this.humanPick(event.id, pick.itemId, false)}) positionWithMod: ${positionWithMod} (${this.events.indexOf(event)} == ${allowedPickAmount} - ${pick.additionalPoints})`)
      return positionWithMod
    } else {
      return this.events.indexOf(event)
    }
  }

  public _weightedEventSorter = (a: IPickUtilsEvent, b: IPickUtilsEvent) => {
    // lower is better!
    const aScore = this._weightedEventSorterScore(a)
    const bScore = this._weightedEventSorterScore(b)
    return aScore - bScore
  }

  public getOrderedEvents() {
    if (this.pickMappings.orderedEvents) {
      return this.pickMappings.orderedEvents
    }
    const weightChoices = this.getGameWeightChoices()
    if (weightChoices) {
      const ss = [...this.events]
      ss.sort(this._weightedEventSorter)
      return ss
    }
    const sorter = newEventOfThePeriodSorter(this.period.eventOfThePeriodId, this.period.secondEventOfThePeriodId)
    return this.events.sort(sorter)
  }

  public getEventForSlotId(slotId: string) {
    if (this.isBracket()) {
      return this.eventForMatchupId(slotId)
    } else {
      return this.getEventById(slotId)
    }
  }

  public getPercentOwned(matchupId: string, side: "home" | "top" | "bottom" | "away") {
    if (this.isBracket()) {
      throw new Error(`This logic does not work for brackets`)
    } else {
      const event = this.eventForMatchupId(matchupId) as IPickUtilsEvent | undefined
      return (event?.extra || emptyObject)[`${side}TeamPickemPercentOwned`]
    }
  }

  public log(msg: string, item?: any) {
    if (this.logger) {
      this.logger.debug(msg)
    } else {
      console.debug(msg)
    }
    if (item) {
      console.dir(item)
    }
  }

  public getMaxPointsFor(slotId: string) {
    const basePoints = 1 + this.getAdditionalPoints(slotId)
    return basePoints * (this.getMultiplier(slotId) || 1)
  }

  public getPossibleBracketPoints() {
    if (!this.isBracket()) {
      return 0
    }

    // NOTE LL: we should probable only have to do this, but I think it's messed up w/ sample data. Need to test w/ full simulated pool
    // return this.getUnstartedEvents().reduce((total, event) => total + this.getMaxPointsFor(event.id), 0)
    const slotIdsToProcess =
      this.pickAgnosticMappings.slotIds?.filter((slotId) => {
        const matchup = this.bracketMapping?.matchupById[slotId]
        const pickForSlot = this.getPick(slotId)
        // Need to consider this a valid slot if no winner for this slot and user's pick for this slot is still alive
        return !matchup?.winnerId && pickForSlot?.itemId && !this.isEliminatedFromBracket(pickForSlot.itemId)
      }) ?? []
    return slotIdsToProcess.reduce((total, slotId) => total + this.getMaxPointsFor(slotId), 0)
  }
}

export default PickUtils
